import React, { useState, useMemo,useEffect } from "react";
import { DownloadOutlined } from '@ant-design/icons';

import { Select,DatePicker, Spin,Button} from 'antd';
import CustomTable from '../common/CustomTable';
import dayjs from 'dayjs';
import { Timesheetapi } from "../../middleware/interceptor";
import * as moment from 'moment';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import CryptoJS from 'crypto-js';
import {  MenuItem, FormControl, InputLabel } from '@mui/material';



import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Paper,Checkbox  } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';






const { RangePicker } = DatePicker;




export const  Invoice_report = ({isAuthenticated,Secret_key})=>{

    const currentStartDate = dayjs().startOf('month');
     const currentEndDate = dayjs().endOf('month');
    const [projectlist, setprojectlist] = useState([]);
    const [ selectedRange, setSelectedRange] = useState([currentStartDate, currentEndDate]);

    const [ changestartdate, setchangestartdate] = useState();

    const [ changeenddate, setchangeenddate] = useState();



    const [loading,setloading]=useState(false)
    const [openRows, setOpenRows] = useState({});



    const [Options,setOptions]=useState([])
    const[values,setvalues]=useState([]);



    const [tempproj,settempproj] = useState([]);


    // const handleExportRows = (data, checkedRows) => {
    //   const doc = new jsPDF();
    //   const overallSummary = { totalHours: 0, totalAmount: 0 }; // Overall totals
    
    //   doc.setFontSize(18);
    //   doc.text("INVOICE REPORT", 14, 15);
    
    //   data.forEach((client, clientIndex) => {
    //     // Add client section
    //     const clientSummary = { totalHours: 0, totalAmount: 0 }; // Client-level totals
    
    //     // Filter rows based on the selected rows in `checkedRows`
    //     const selectedRows = client.subRows.filter((_, index) =>
    //       (checkedRows[client.Client_id] ?? {})[index]
    //     );
        

    //     console.log("Checkeds", selectedRows)
    
    //     if (selectedRows.length === 0) return; // Skip if no rows selected for this client
    
    //     const rows = selectedRows.map((row) => {
    //       const hours = convertToHours(row.Total_hours);
    //       const rate = parseFloat(row.Rate);
    //       clientSummary.totalHours += hours;
    //       clientSummary.totalAmount += rate;
    
    //       return [row.Projects, row.Chargeability, row.Total_hours, `$${row.Rate}`];
    //     });
    
    //     doc.setFontSize(12);
    //     if (clientIndex > 0) doc.addPage(); 
    //     doc.text(`Client: ${client.Client_name}`, 14, 30);
    
    //     doc.autoTable({
    //       startY: 35,
    //       head: [["Project", "Chargeability", "Hours", "Amount"]],
    //       body: rows,
    //     });
    
    //     const finalY = doc.lastAutoTable.finalY + 10;
    //     doc.text(
    //       `Client Total Hours: ${clientSummary.totalHours.toFixed(2)}`,
    //       14,
    //       finalY
    //     );
    //     doc.text(
    //       `Client Total Amount: $${clientSummary.totalAmount.toFixed(2)}`,
    //       120,
    //       finalY
    //     );
    //         overallSummary.totalHours += clientSummary.totalHours;
    //     overallSummary.totalAmount += clientSummary.totalAmount;
    //   });
    
    //   const overallStartY = doc.lastAutoTable.finalY + 20;
    //   doc.setFontSize(14);
    //   doc.text("Overall Totals", 14, overallStartY);
    //   doc.text(
    //     `Total Hours: ${overallSummary.totalHours.toFixed(2)}`,
    //     14,
    //     overallStartY + 10
    //   );
    //   doc.text(
    //     `Total Amount: $${overallSummary.totalAmount.toFixed(2)}`,
    //     120,
    //     overallStartY + 10
    //   );
    
    //   // Save the PDF
    //   doc.save("client_report.pdf");
    // };
    
    const handleExportRows = (data) => {
      const doc = new jsPDF();
      const overallSummary = { totalHours: 0, totalAmount: 0 }; // Overall totals
    
      doc.setFontSize(18);
      doc.text("INVOICE REPORT", 14, 15);
    
      data.forEach((client, clientIndex) => {
        // Add client section
        const clientSummary = { totalHours: 0, totalAmount: 0 }; // Client-level totals
    
        const selectedRows = client.subRows.filter((_, index) =>
          (checkedRows[client.Client_id] ?? {})[index]
        );
    
        if (selectedRows.length === 0) return; // Skip if no rows selected for this client
    
console.log("checkedRows",checkedRows)

        const rows = selectedRows.map((row) => {
          const hours = convertToHours(row.Total_hours);
          const rate = parseFloat(row.Rate);
          clientSummary.totalHours += hours;
          clientSummary.totalAmount += rate;
    
          return [row.Projects, row.Chargeability, row.Total_hours, `$${row.Rate}`];
        });
    
        // Add client header
        doc.setFontSize(12);
        if (clientIndex > 0) doc.addPage(); // Add a new page for the next client if needed
        doc.text(`Client: ${client.Client_name}`, 14, 30);
    
        // Add table for client's projects
        doc.autoTable({
          startY: 35,
          head: [["Project", "Chargeability", "Hours", "Amount"]],
          body: rows,
        });
    
        // Add client totals
        const finalY = doc.lastAutoTable.finalY + 10;
        doc.text(
          `Client Total Hours: ${clientSummary.totalHours.toFixed(2)}`,
          14,
          finalY
        );
        doc.text(
          `Client Total Amount: $${clientSummary.totalAmount.toFixed(2)}`,
          120,
          finalY
        );
    
        // Update overall summary
        overallSummary.totalHours += clientSummary.totalHours;
        overallSummary.totalAmount += clientSummary.totalAmount;
      });
    
      // Add overall summary at the end
      const overallStartY = doc.lastAutoTable.finalY + 20;
      doc.setFontSize(14);
      doc.text("Overall Totals", 14, overallStartY);
      doc.text(
        `Total Hours: ${overallSummary.totalHours.toFixed(2)}`,
        14,
        overallStartY + 10
      );
      doc.text(
        `Total Amount: $${overallSummary.totalAmount.toFixed(2)}`,
        120,
        overallStartY + 10
      );
    
      // Save the PDF
      doc.save("client_report.pdf");
    };
    


    const convertToHours = (time) => {
      const [hours, minutes] = time.split(":").map((unit) => parseInt(unit, 10));
      return hours + minutes / 60;
    };


    const columns = useMemo(() => [
        {
          accessorKey: 'Client_name',
          header: 'Client name',
          size: 50,
          enableColumnActions: false,
          muiTableHeadCellProps: {
            align: 'center',
          },
        },
        {
          accessorKey: 'Projects',
          header: 'Projects',
          size: 5,
          enableColumnActions: false,
          muiTableHeadCellProps: {
            align: 'center',
          },
        },
        {
          accessorKey: 'Chargeability',
          header: 'Chargeability',
          size: 5,
          enableColumnActions: false,
          muiTableHeadCellProps: {
            align: 'center',
          },
          // Customize cell renderer to show a dropdown
          Cell: ({ row}) => {
    
            console.log(row.original,"heap")
            if(!row.original.subRows)
            {
            return (
              <FormControl fullWidth>
                <Select
                  
                >
                 <option value="1">
                          Billable
                        </option>
                        <option value="2" >
                          Non-Billable
                        </option >
                </Select>
              </FormControl>
            );
          }
          },
        },

        {
            accessorKey: 'Rate',
            header: 'Rate',
            size: 5,
            enableColumnActions: false,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              style: {
                textAlign: 'right',
              },
            },
            
          },
        
        
      ], [projectlist]);

      const formatTimeinter = (totalMinutes) => {
        if (isNaN(totalMinutes) || totalMinutes == null) return '0'; // Handle NaN or null by returning '0'
      
        // Convert totalMinutes to hours (in decimal format)
        const decimalHours = totalMinutes / 60;
      
        // Format the result with thousand separators and two decimal places
        return decimalHours.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };


      const formatTime = (totalMinutes) => {
        if (isNaN(totalMinutes) || totalMinutes == null) return "00:00"; // Handle NaN or null
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      };




      const projectratetypechange = async(data,item) => {

           
       

       let payload = {

        Project_id : item.Projectid,
        client_id : item.Client_id,
        Chargeability:data

       }

       console.log(payload,"rider")

        try {
          const response = await Timesheetapi.post('api/Projects/changeprojectratetype', payload).then((response) => {
            console.log('response', response.data);
            if (response.data.status == "1") {
              getprojectchangedaterange();
              //toast.success(response.data.Msg)
            }
          }
          );
        } catch (error) {
          console.error('Error fetching client names:', error);
        }

      }




      const handleDateChange = async(date, dateString) => {

        console.log(date,"crowd")
      
        if (date && date.length === 2) {
          
          
          setSelectedRange(dateString)

            setloading(true);

            const startMonth = date[0];
            const endMonth = date[1];
            const startOfMonth = startMonth.startOf('month').format('YYYY-MM-DD');
            const endOfMonth = endMonth.endOf('month').format('YYYY-MM-DD');

            setchangestartdate(startOfMonth);
            setchangeenddate(endOfMonth);
            console.log('date',startOfMonth,endOfMonth);
    
            await Timesheetapi.get(`api/Projects/getinvoicereport?sdate=${startOfMonth}&edate=${endOfMonth}`).then((response) => {
        
              const employees = response.data.employeedetails;
          
          
              const projectlists = response.data.projects;


              //setvalues(projectlists[0].client_id);
              
    
              // let data = projectlists?.map((item) => {

              //   let [ciphertext, ivBase64]="";
              //   let Ratedecrypt = "";
      
              //   if(item.Rate){
          
              //    [ciphertext, ivBase64] = item.Rate.split(',');
          
              //    Ratedecrypt = decryptData(ciphertext,ivBase64)
              //   }


              //   return {
              //     ...item,
              //    // Rate:formatTimeinter(item.Rate),
              //    Rate:formatTimeinter(Ratedecrypt)

              //   };
              // })


              const filter = projectlists.map(cli => ({
                value: cli.Client_id,
                label: cli.Client_name,
              }));
      
      
              //console.log("filter",projectlists[0].Client_id);
            
      
              
      
      
                  setOptions(filter&&filter?filter:[])

                  setvalues(projectlists[0] && projectlists[0].Client_id?projectlists[0].Client_id:[])

              let data = projectlists.map((item) => {
         
                let updatedSubRows = item.subRows.map((subRow) => {
                  let Ratedecrypt = "";
              
                  if (subRow.Rate) {
                    let [ciphertext, ivBase64] = subRow.Rate.split(',');
                    Ratedecrypt = decryptData(ciphertext, ivBase64); 
              
                   
                    subRow.Rate = formatTimeinter(Ratedecrypt); 
                  }
                  subRow.Total_hours=formatTime(subRow.Total_hours)

              
                  return subRow; 
                });
              
              
                return {
                  ...item,
                  subRows: updatedSubRows, 
                };
              });
          
          console.log(data,"LENGTH")
              if(data.length>0 ){
                console.log("Push",data[0],data);
                settempproj(data);
                setprojectlist([data[0]]);
              }
              else{
                settempproj([]);
                setprojectlist([]);

              }
          
              }).catch((error) => {
                console.log(error);
              });

              setloading(false);

    
        }
    
    
    
      }


      const decryptData = (ciphertext, ivBase64) => {
    

        console.log(ciphertext,ivBase64,"callfunc")
    
    
        const iv = CryptoJS.enc.Base64.parse(ivBase64);
      
        // Decrypt using AES CBC mode
        const bytes = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(Secret_key), { iv: iv });
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      
    
        console.log(decryptedData,"joker")
    
        return decryptedData;
      };



      const getprojectchangedaterange = async () => {

       
        console.log(changestartdate,changeenddate,"bruuuu")


        setloading(true);
        // const currentStartDate = moment().startOf('month').format('YYYY-MM-DD');
        // const currentEndDate = moment().endOf('month').format('YYYY-MM-DD');
    
        await Timesheetapi.get(`api/Projects/getinvoicereport?sdate=${changestartdate}&edate=${changeenddate}`).then((response) => {
        
        const projectlists = response.data.projects;
    
        console.log(projectlists,"dcrew")

        const filter = projectlists.map(cli => ({
          value: cli.Client_id,
          label: cli.Client_name,
        }));
        setOptions(filter)

        let data = projectlists.map((item) => {
         
          let updatedSubRows = item.subRows.map((subRow) => {
            let Ratedecrypt = "";
        
            if (subRow.Rate) {
              let [ciphertext, ivBase64] = subRow.Rate.split(',');
              Ratedecrypt = decryptData(ciphertext, ivBase64); 
              subRow.Rate = formatTimeinter(Ratedecrypt); 
            }

            subRow.Total_hours=formatTime(subRow.Total_hours)
        
            return subRow; 
          });
        
        
          return {
            ...item,
            subRows: updatedSubRows, 
          };
        });
        
        if(data.length>0 ){
          console.log("Push",data[0],data);
          settempproj(data);
          setprojectlist([data[0]]);
        }
        else{
          settempproj([]);
          setprojectlist([]);

        }
    
         
        }).catch((error) => {
          console.log(error);
        });

        setloading(false);

    
      }

      const getinvoicereport = async () => {
        setloading(true);
        const currentStartDate = moment().startOf('month').format('YYYY-MM-DD');
        const currentEndDate = moment().endOf('month').format('YYYY-MM-DD');

        setchangestartdate(currentStartDate);
        setchangeenddate(currentEndDate);
    
        await Timesheetapi.get(`api/Projects/getinvoicereport?sdate=${currentStartDate}&edate=${currentEndDate}`).then((response) => {
        
        const projectlists = response.data.projects;
    
        console.log(projectlists,"dcrew")


        setvalues(projectlists[0].Client_id)


        const filter = projectlists.map(cli => ({
          value: cli.Client_id,
          label: cli.Client_name,
        }));


        console.log("filter",projectlists[0].Client_id);
      

        


            setOptions(filter)

        let data = projectlists.map((item) => {      
          

      

          let Filters= item?.Client_id.includes(projectlists[0]?.Client_id);


           //if(Filters){
            let updatedSubRows = item.subRows.map((subRow) => {
              let Ratedecrypt = "";       
  
              
              if (subRow.Rate) {
                let [ciphertext, ivBase64] = subRow.Rate.split(',');
                Ratedecrypt = decryptData(ciphertext, ivBase64); 
                subRow.Rate = formatTimeinter(Ratedecrypt); 
              }
  
              subRow.Total_hours=formatTime(subRow.Total_hours)
          
              return subRow; 
            });
          
          
            return {
              ...item,
              subRows: updatedSubRows, 
            };
        //  }
//

     
       
        });
    

        if(data.length>0 ){
          console.log("Push",data[0],data);
          settempproj(data);
          setprojectlist([data[0]]);
        }
        else{
          settempproj([]);
          setprojectlist([]);

        }
           
    
         
        }).catch((error) => {
          console.log(error);
        });

        setloading(false);

    
      }
      const [checkedRows, setCheckedRows] = React.useState({});

      

      useEffect(() => {
        if (Secret_key && projectlist?.length > 0) {
    
            const initialCheckedState = {};
            const initialOpenState = {}; 
    
            projectlist.forEach((client) => {
                initialCheckedState[client.Client_id] = {};
                initialOpenState[client.Client_id] = true; 
    
                client?.subRows.forEach((_, index) => {
                    initialCheckedState[client.Client_id][index] = true;
                });
            });
    
            console.log(initialCheckedState, initialOpenState, 'Initial State');
    
            setCheckedRows(initialCheckedState); 
            setOpenRows(initialOpenState);
        }
    }, [Secret_key, projectlist]);
    

    

      
      useEffect(() => {

        if(Secret_key){
          getinvoicereport();
          

        }
      },[Secret_key]);
      const handleCheckboxChange = (clientId, index) => {
        setCheckedRows((prev) => ({
          ...prev,
          [clientId]: {
            ...(prev[clientId] || {}),
            [index]: !prev[clientId]?.[index],
          },
        }));
      };
      
    
    
      const toggleRow = (clientId) => {
        setOpenRows((prev) => ({
            ...prev,
            [clientId]: !prev[clientId], // Toggle the state for the specific client
        }));
    };

    const CollapsibleRow = ({ row, rowindex, isOpen, toggleRow }) => {


      const total = row.subRows
      .reduce((sum, item, index) => {
        let rate = 0;
//let isAnyRowChecked;
        console.log("clkoejin", item.Client_id,index,checkedRows,checkedRows[item.Client_id]?.[index]);
            if (checkedRows[item.Client_id]?.[index]) {
              console.log(rate,"RATE");
              if (typeof item?.Rate === "string") {
                rate = parseFloat(item.Rate.replace(/,/g, "")) || 0;
              } else if (typeof item?.Rate === "number") {
                rate = item.Rate;
              }

              //isAnyRowChecked = checkedRows[row.Client_id] && Object.values(checkedRows[row.Client_id]).some(Boolean);
          return sum + rate;
        }
        return sum;
      }, 0)
      .toLocaleString("en-IN", { style: "currency", currency: "INR" });
  
      return (
          <>
              <TableRow>
                  <TableCell>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <div>
                              <IconButton size="small" onClick={toggleRow}>
                                  {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                              </IconButton>
                              {row.Client_name}
                          </div>
  
                          <label
        style={{
          marginLeft: "auto",
          padding: "0 8px", 
          fontSize: "14px",
          fontWeight: "bolder",
          display: "flex",
          flexDirection: "column",
          textAlign: "right"
        }}
      >
        {/* Total Calculation */}
        <span >
          {
            console.log(total,'KILOK')
          }
          Total:{" "}
          {total!=0
                  ? total
                  :row?.subRows
            .reduce((total, item) => {
              // Check if Rate is a string or number
              let rate = 0;

              if (typeof item?.Rate === "string") {
                rate = parseFloat(item.Rate.replace(/,/g, "")) || 0;
              } else if (typeof item?.Rate === "number") {
                rate = item.Rate;
              }

              return total + rate;
            }, 0)
            .toLocaleString("en-IN", { style: "currency", currency: "INR" })} 
        </span>
      </label>
                      </div>
                  </TableCell>
              </TableRow>
  
              {/* Collapsible Subrows */}
              {isOpen && (
               
            <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <div className="border-[1px] border-solid border-[#ddd] my-2">
                  {/* <strong>History:</strong> */}
                  <Table size="small">
                  <TableHead>
                <TableRow>
                  <TableCell  align="center" ><strong>Projects</strong></TableCell>
                  <TableCell  align="center" ><strong>Chargeability</strong></TableCell>
                  <TableCell  align="center" ><strong>Hours</strong></TableCell>
                  <TableCell align="right"><strong>Amount</strong></TableCell>
                </TableRow>
              </TableHead>
                    <TableBody >
                      
                      {row.subRows.map((item, index) => (

                        <>                          {
                          console.log("hYyg", index,)

                        }

                        <TableRow key={index}>
                          
                          <TableCell> 
                          <Checkbox size="small"
                        checked={!!checkedRows[item?.Client_id]?.[index]}
                        onChange={() => handleCheckboxChange(item?.Client_id, index,rowindex)}
                        inputProps={{ "aria-label": "select row" }}
                      />
                          {item.Projects}</TableCell>
                          <TableCell>
              <FormControl fullWidth>
              <Select
                defaultValue={item.Chargeability}
                onChange={(e)=>{projectratetypechange(e,item)}}
              >
               <option value="1">
                        Billable
                      </option>
                      <option value="2" >
                        Non-Billable
                      </option >
              </Select>
            </FormControl>


                    </TableCell>
                        <TableCell align="center">{item.Total_hours}</TableCell>

                          <TableCell align="right">{item.Rate}</TableCell>
                        </TableRow>
                        </>

                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Collapse>
            </TableCell>
          </TableRow>
              )}
          </>
      );
  };
  

  const handleChange = (values) => {
    // Update the state with the selected values
    setvalues(values);
  
    console.log(tempproj, values, "Test");
  
    const filters = tempproj.filter((item) => 
      item.Client_id && 
      values.some((val) => 
        Array.isArray(item.Client_id)
          ? item.Client_id.includes(val)
          : item.Client_id === val
      )
    );
    

    setprojectlist(filters);
  
    console.log(filters, "Filters");
  };
  


  const handleExportRowss =async()=>{



  }
      


return (


    <section className='reports-section'>
    <div>
      <h2 className="text-[20px] font-[500]">Invoice Reports</h2>
    </div>
    <div className="flex items-center justify-between">
    <div className="flex items-center gap-2">
    
              
     
     

    <div style={{left:"100px"}}> 
     <RangePicker
       style={{marginBottom:"60px"}}
       className={"z-[99] top-[31px] py-[5px]" }
       onChange={handleDateChange}
       defaultValue={selectedRange} // Show the selected range
       picker="month"
       placeholder={['Start Month', 'End Month']}
        format="MMMM YYYY"
     />
    </div>
    <Select style={{height:'fit-content'}}
     mode="multiple"
     onChange={handleChange}
className="relative z-[9] top-[0px] w-[300px]"
     placeholder="select a client"
     options={Options}
   value={values}
     // filterOption={(input, option) =>
     //   option.label.toLowerCase().includes(input.toLowerCase())
     // }
     
   />
   </div>
   <Button type="primary" icon={<DownloadOutlined />} size={"large"}  onClick={()=>handleExportRows(projectlist)}>
           Generate Report
         </Button>
    </div>
  
    <div className="mt-[-20px]  invoice-report-table">



    {loading?(<>
  <Spin tip="Loading..." className="justify-center items-center flex" style={{height:'100vh'}}>
  <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell><strong>CLIENT LISTS</strong></TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
        {console.log(projectlist,"gather")}
        {(projectlist && projectlist.length > 0) ? (
        projectlist.map((row, index) => (
          <CollapsibleRow
            key={row?.Client_name}
            row={row}
            rowindex={index}
            isOpen={openRows[row.Client_id] || false}
            toggleRow={() => toggleRow(row.Client_id)}
          />
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={1} style={{ textAlign: 'center' }}>
            <strong>NO DATA FOUND</strong>
          </TableCell>
        </TableRow>
      )}
        </TableBody>
      </Table>
    </TableContainer>
 </Spin>
</>):(
    <>
    {/* <CustomTable
  columns={columns}
  data={projectlist} // Append totals row
  component={"InvoiceReport"}
  handleExportRows={handleExportRows}
/> */}


<TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell><strong>CLIENT LISTS</strong></TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
        {console.log(projectlist,"gather")}
        {(projectlist && projectlist.length > 0) ? (
        projectlist.map((row, index) => (
          <CollapsibleRow
            key={row?.Client_name}
            row={row}
            rowindex={index}
            isOpen={openRows[row.Client_id] || false}
            toggleRow={() => toggleRow(row.Client_id)}
          />
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={1} style={{ textAlign: 'center' }}>
            <strong>NO DATA FOUND</strong>
          </TableCell>
        </TableRow>
      )}
        </TableBody>
      </Table>
    </TableContainer>





  </>

)
}

   
    </div>
  </section>




)



}



