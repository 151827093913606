import React, { useState, useMemo,useEffect } from "react";
import CustomTable from '../common/CustomTable';
import { Select,DatePicker, Spin } from 'antd';
import { Timesheetapi } from "../../middleware/interceptor";
import dayjs from 'dayjs';
import * as moment from 'moment';

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import CryptoJS from 'crypto-js';


const { Option } = Select;
const { RangePicker } = DatePicker;


export const Report = ({isAuthenticated,Secret_key}) => {
  const [selectedRole, setSelectedRole] = useState('');



  const [loading,setloading]=useState(false)



  const handleRoleChange = (value) => {
    setSelectedRole(value);
  };


  const currentStartDate = dayjs().startOf('month');
  const currentEndDate = dayjs().endOf('month');



  const [ selectedRange, setSelectedRange] = useState([currentStartDate, currentEndDate]);

  const formatTime = (totalMinutes) => {
    if (isNaN(totalMinutes) || totalMinutes == null) return "00:00"; // Handle NaN or null
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };
  
  const formatTimeinter = (totalMinutes) => {
    if (isNaN(totalMinutes) || totalMinutes == null) return '0'; // Handle NaN or null by returning '0'
  
    // Convert totalMinutes to hours (in decimal format)
    const decimalHours = totalMinutes / 60;
  
    // Format the result with thousand separators and two decimal places
    return decimalHours.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  


  const data = [
    {
      project: 'Autobuy',
      emp_name: 'John Doe',
      hours: '3',
      internalRank: 1,
      externalRank: 3,
      total: '45',
    },
    {
      project: 'Fobesoft',
      emp_name: 'Jane Smith',
      hours: '6',
      internalRank: 2,
      externalRank: 4,
      total: '66',
    },
    {
      project: 'Transcredit',
      emp_name: 'Sam Wilson',
      hours: '8',
      internalRank: 3,
      externalRank: 5,
      total: '78',
    }
  ];


  const [clientlist, setClientlist] = useState([]);



  useEffect(() => {
    if (Secret_key) {
      getclientreport();
    }
  },[Secret_key]);

  const decryptData = (ciphertext, ivBase64) => {
    

    console.log(ciphertext,ivBase64,"callfunc")


    const iv = CryptoJS.enc.Base64.parse(ivBase64);
  
    // Decrypt using AES CBC mode
    const bytes = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(Secret_key), { iv: iv });
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  

    console.log(decryptedData,"joker")

    return decryptedData;
  };


  const getclientreport = async () => {

    setloading(true);
    const currentStartDate = moment().startOf('month').format('YYYY-MM-DD');
    const currentEndDate = moment().endOf('month').format('YYYY-MM-DD');

    await Timesheetapi.get(`api/Projects/getreportsAsync?Role_id=${localStorage.getItem('emp_role_id')}&empid=${localStorage.getItem('employee_id')}&sdate=${currentStartDate}&edate=${currentEndDate}`).then((response) => {
    
    const projects = response.data.Clients;


    let data = projects?.map((item) => {

      let [ciphertext, ivBase64]="";
      let InternalRate = "";
      let ExternalRate = "";
      if(item.InternalRate && item.InternalRate !="0" ){

       [ciphertext, ivBase64] = item.InternalRate.split(',');

       InternalRate = decryptData(ciphertext,ivBase64)
      }

      if(item.ExternalRate && item.ExternalRate !="0"){

        [ciphertext, ivBase64] = item.ExternalRate.split(',');
 
        ExternalRate = decryptData(ciphertext,ivBase64)
       }



      return {
        ...item,
        Totalhours:formatTime(item.Totalhours),
        // InternalRate:formatTimeinter(item.InternalRate),
        // ExternalRate:formatTimeinter(item.ExternalRate)
         InternalRate:formatTimeinter(InternalRate),
         ExternalRate:formatTimeinter(ExternalRate)
      };
    })


      setClientlist(data);
      setloading(false);


      console.log("clientlist",projects);
    }).catch((error) => {
      console.log(error);
    });

  }


  // Calculate totals
  const totalHours = data.reduce((acc, item) => acc + parseInt(item.hours || 0), 0);
  const totalInternalRank = data.reduce((acc, item) => acc + (item.internalRank || 0), 0);
  const totalExternalRank = data.reduce((acc, item) => acc + (item.externalRank || 0), 0);

  // Create a totals row
  const totalRow = {
    project: "Total",
    emp_name: "",
    hours: totalHours,
    internalRank: totalInternalRank,
    externalRank: totalExternalRank,
    total: "25",
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "client_name",
        header: "Clients",
        size: 80,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'left',
          },
        },
      },
      {
        accessorKey: "Totalhours",
        header: "Worked Hours",
        size: 80,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'right',
          },
        },
      },
      {
        accessorKey: "InternalRate",
        header: "Internal Rate",
        size: 80,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'right',
          },
        }
      },
      {
        accessorKey: "ExternalRate",
        header: "External Rate",
        size: 80,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'right',
          },
        },
        
      },
      // {
      //   accessorKey: 'total',
      //   header: 'Total',
      //   size: 80,
      //   enableColumnActions: false,
      // }
    ],
    [clientlist]
  );

  const handleDateChange = async(date, dateString) => {

    setSelectedRange(dateString);

    if (date && date.length === 2) {
        setloading(true);
        const startMonth = date[0];
        const endMonth = date[1];
        const startOfMonth = startMonth.startOf('month').format('YYYY-MM-DD');
        const endOfMonth = endMonth.endOf('month').format('YYYY-MM-DD');
        console.log('date',startOfMonth,endOfMonth);

        await Timesheetapi.get(`api/Projects/getreportsAsync?Role_id=${localStorage.getItem('emp_role_id')}&empid=${localStorage.getItem('employee_id')}&sdate=${startOfMonth}&edate=${endOfMonth}`).then((response) => {
    
          const projects = response.data.Clients;
      
      
          let data = projects?.map((item) => {

            let [ciphertext, ivBase64]="";
      let InternalRate = "";
      let ExternalRate = "";
      if(item.InternalRate && item.InternalRate !="0" ){

       [ciphertext, ivBase64] = item.InternalRate.split(',');

       InternalRate = decryptData(ciphertext,ivBase64)
      }

      if(item.ExternalRate && item.ExternalRate !="0"){

        [ciphertext, ivBase64] = item.ExternalRate.split(',');
 
        ExternalRate = decryptData(ciphertext,ivBase64)
       }


            return {
              ...item,
              Totalhours: formatTime(item.Totalhours),
              // InternalRate: formatTimeinter(item.InternalRate),
              // ExternalRate: formatTimeinter(item.ExternalRate),
              InternalRate:formatTimeinter(InternalRate),
              ExternalRate:formatTimeinter(ExternalRate)
            };
          })
      
      
            setClientlist(data);
            setloading(false);
      
            console.log("clientlist",projects);
          }).catch((error) => {
            console.log(error);
          });

    }
  };

  const handleExportData = async(rows) => {



    const tableData = rows.map((row) => Object.values(row.original));




    const tableDatas = tableData.map((row) => ({
      Client_name: row[0],
      Worked_hrs: row[6],
      Internal_rate: row[7],
      External_rate: row[8],
      //Details: row[4],
      //Purveyor: row[5],
     // Review_status: row[6]
  }));

  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet(`Reports.xlsx`);

  
  const startDate = new Date(selectedRange[0]);
      const endDate = new Date(selectedRange[1]);
    
      // Get the month and year for both start and end dates
      const options = { year: 'numeric', month: 'long' };
      const startMonthYear = startDate.toLocaleString('en-US', options); // e.g., "October 2024"
      const endMonthYear = endDate.toLocaleString('en-US', options); 

  const selected = 'DATE: ' + startMonthYear + '  -  ' + endMonthYear;
  worksheet.addRow([selected]);

  worksheet.mergeCells('A1:D1');
  const Cell = worksheet.getCell('A1');
  Cell.font = { bold: true };
   Cell.alignment = { horizontal: 'center' };



worksheet.getCell('A3').value = 'Client';
worksheet.getCell('B3').value = 'Worked_Hours';
worksheet.getCell('C3').value = 'Internal_Rate';
worksheet.getCell('D3').value = 'External_Rate';


worksheet.getCell('A3').font = { bold: true };
worksheet.getCell('B3').font = { bold: true };
worksheet.getCell('C3').font = { bold: true };
worksheet.getCell('D3').font = { bold: true };

worksheet.getColumn('A').width = 20;
worksheet.getColumn('B').width = 15;
worksheet.getColumn('C').width = 15;
worksheet.getColumn('D').width = 15;


  console.log(tableDatas,tableData,"tableData")


  let rowIndex = 4;

  const filteredData = tableDatas.map(item => ({
    Client_name: item.Client_name,
    Worked_hrs: item.Worked_hrs,
    Internal_rate: item.Internal_rate,
    External_rate: item.External_rate
}));

filteredData.forEach(data => {
  worksheet.getCell(`A${rowIndex}`).value = data.Client_name;
  worksheet.getCell(`B${rowIndex}`).value = data.Worked_hrs;
  worksheet.getCell(`C${rowIndex}`).value = data.Internal_rate;
  worksheet.getCell(`D${rowIndex}`).value = data.External_rate;
  rowIndex++;
});
const buffer = await workbook.xlsx.writeBuffer();
saveAs(new Blob([buffer]), `Reports.xlsx`);

    // console.log(columns, "ghghgh");
    // const rowData = data.map((row) => row.original);
    // const exportData = clientlist; 
    // const csv = convertToCSV(rowData);
    // downloadCSV(csv);
  };

  // Convert data to CSV
  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const rows = data.map(row => 
      headers.map(field => row[field]).join(',')
    );
    return [headers.join(','), ...rows].join('\n');
  };

  // Download the CSV
  const downloadCSV = (csv) => {
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Reports.csv';
    link.click();
  };





  return (
    <section className='reports-section'>
      <div>
        <h2 className="text-[20px] font-[500]">Reports</h2>
      </div>
      <div className="flex justify-start">
        {/* <Select className="z-[99] left-[10px] top-[25px]"
          style={{ width: 130 }}
          placeholder="Client"
          options={clientlist.map((client) => ({ label: client.client_name, value: client.client_name }))}
          onChange={handleRoleChange}
        /> */}
                
        <div style={{left:"100px"}}> 
       <RangePicker
        className="z-[99] left-[10px] top-[31px] py-[5px]"
        // style={{ left: "100px" }}
        onChange={handleDateChange}
        defaultValue={selectedRange} // Show the selected range
        picker="month"
        placeholder={['Start Month', 'End Month']}
         format="MMMM YYYY"
      />
 
       </div>
      </div>
      <div className="mt-[-20px]  custom-table report-table">
        {loading ? (
          <>
            <Spin tip="Loading..." className="justify-center items-center flex" style={{height:'100vh'}}>
              <CustomTable
                columns={columns}
                data={[...clientlist]} // totalRow
              />
            </Spin>
          </>
        ) : (
          <>
            <CustomTable
              columns={columns}
              data={[...clientlist]} // totalRow Append totals row
              component={"Report"}
              handleExportData={handleExportData}
            />
          </>
        )}
      </div>
    </section>
  );
};
