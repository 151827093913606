import { React, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { CiViewTimeline } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { FaCreditCard, FaUserFriends, FaUserTie } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { BiSpreadsheet } from "react-icons/bi";
import { AiOutlineSetting } from "react-icons/ai";
import { IoDocumentsSharp } from "react-icons/io5";
import { LuUserCog2 } from "react-icons/lu";
import { TbReportAnalytics } from "react-icons/tb";
import { Menu } from 'antd';
import { FaBars } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { SiGooglesheets } from "react-icons/si";
import { RiFileUserLine } from "react-icons/ri";
import { LiaFileInvoiceSolid } from "react-icons/lia";

export const Sidebar = ({ Emp_role }) => {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onOpenChange = (openKeys) => {

    console.log(openKeys)

  }


  console.log("Roles_id", Emp_role)
  return (
    <aside className="max-w-[225px] bg-[#fff] text-white flex flex-col items-center py-10" style={{ borderRight: '1px solid #cfcfcf' }}>
      <div className='flex justify-between w-[100%] px-3 gap-4'>
        <button className='flex items-center justify-center rounded-[5px] h-[30px] w-[30px] bg-[#fff] border border-[#3b82f6]' onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
          {isDrawerOpen ? (
            <IoClose className='text-[20px] text-[#3b82f6] ' />

          ) : (
            <FaBars className='text-[18px] text-[#3b82f6]' />
          )}
        </button>
          <img className={`side-logo1 ${isDrawerOpen ? '' : 'hidden'}`} src='/jetz-logo.png' alt="logo" />
          <img className={`side-logo2 ${isDrawerOpen ? 'hidden' : ''}`} src='/logo-jetz.png' alt="logo" />

      </div>
      {isDrawerOpen ? (
        <ul className="w-full mt-[1.5rem]">


          <Menu mode="inline" theme="light" defaultSelectedKeys={localStorage.getItem('emp_role_id') == 2 ? ['1'] : localStorage.getItem('emp_role_id') == 3 ? ['4'] : ['1']} onOpenChange={onOpenChange}>
            {localStorage.getItem('emp_role_id') != 3 && (
              <Menu.Item key="1" icon={<BiSpreadsheet style={{ fontSize: '18px' }} />}>
                <NavLink to="/timesheets">
                  My Timesheet
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 2 && (
              <Menu.Item key="2" icon={<SiGooglesheets style={{ fontSize: '16px' }} />}>
                <NavLink to="/Teamtimesheet">
                  Team Timesheets
                </NavLink>
              </Menu.Item>
            )}

            {(localStorage.getItem('emp_role_id') == 3 || localStorage.getItem('emp_role_id') == 2) && (
              <Menu.Item key="4" icon={<FaUserFriends style={{ fontSize: '18px' }} />}>
                <NavLink to="/employee">
                  Employee
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.Item key="5" icon={<FaUserTie style={{fontSize:'17px'}} />}>
                <NavLink to="/Clients">
                  Clients
                </NavLink>
              </Menu.Item>
            )}

            {(localStorage.getItem('emp_role_id') == 3 || localStorage.getItem('emp_role_id') == 2) && (
              <Menu.Item key="6" icon={<FaFileAlt className="text-[16px]" />}>
                <NavLink to="/projects">
                  Projects
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.Item key="7" icon={<FaCreditCard className="text-[16px]" />}>
                <NavLink to="/employeerate">
                  Rate Card
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.SubMenu
                key="sub1"
                icon={<IoDocumentsSharp style={{fontSize:'18px'}} />}
                title={<span className="menu-title">Reports</span>}
              >
                <Menu.Item key="8" icon={<LuUserCog2 style={{fontSize:'19px'}} />}>
                  <NavLink to="/client-allocation">
                    Client Allocation
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="9" icon={<TbReportAnalytics style={{fontSize:'19px'}} />}>
                  <NavLink to="/report">
                    Report
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="15" icon={<RiFileUserLine style={{fontSize:'19px'}} />}>
                  <NavLink
                    to="/Employee_report"
                    activeClassName="active-link"
                  >
                    Employee Report
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="16" icon={<LiaFileInvoiceSolid style={{fontSize:'19px'}} />}>
                  <NavLink
                    to="/Invoice_report"
                    activeClassName="active-link"
                  >
                    Invoice Generate
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="17" icon={<LiaFileInvoiceSolid style={{fontSize:'19px'}} />}>
                  <NavLink
                    to="/SiteDetails"
                    activeClassName="active-link"
                  >
                    Site Details
                  </NavLink>
                </Menu.Item>

              </Menu.SubMenu>
            )}
          </Menu>
        </ul>
      ) : (
        <ul className="w-full mt-[1.5rem]">
          <Menu className='custom-menu' mode="inline" theme="light" defaultSelectedKeys={localStorage.getItem('emp_role_id') == 2 ? ['1'] : localStorage.getItem('emp_role_id') == 3 ? ['4'] : ['1']} onOpenChange={onOpenChange}>
            {localStorage.getItem('emp_role_id') != 3 && (
              <Menu.Item key="1">
                <NavLink to="/timesheets">
                  <BiSpreadsheet style={{ fontSize: '18px' }} />
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 2 && (
              <Menu.Item key="2">
                <NavLink to="/Teamtimesheet">
                  <SiGooglesheets style={{ fontSize: '16px' }} />
                </NavLink>
              </Menu.Item>
            )}

            {(localStorage.getItem('emp_role_id') == 3 || localStorage.getItem('emp_role_id') == 2) && (
              <Menu.Item key="4">
                <NavLink to="/employee">
                  <FaUserFriends style={{ fontSize: '18px' }} />
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.Item key="5" >
                <NavLink to="/Clients">
                  <FaUserTie className="text-[17px]" />
                </NavLink>
              </Menu.Item>
            )}

            {(localStorage.getItem('emp_role_id') == 3 || localStorage.getItem('emp_role_id') == 2) && (
              <Menu.Item key="6" >
                <NavLink to="/projects">
                  <FaFileAlt className="text-[16px]" />
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.Item key="7" >
                <NavLink to="/employeerate">
                  <FaCreditCard className="text-[16px]" />
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.SubMenu
                key="sub1"
                icon={<IoDocumentsSharp style={{fontSize:'20px',position:'relative',left:'13px'}} />}
                // title={<span className="menu-title">Reports</span>}
              >
                <Menu.Item key="8">
                  <NavLink to="/client-allocation">
                  <LuUserCog2 className="text-[19px]" />
                  </NavLink>
                </Menu.Item>


                <Menu.Item key="9">
                  <NavLink to="/report">
                  <TbReportAnalytics className="text-[19px]" />
                  </NavLink>
                </Menu.Item>



                <Menu.Item key="15">
                  <NavLink
                    to="/Employee_report"
                    activeClassName="active-link"
                  ><RiFileUserLine style={{fontSize:'19px'}} />
                  </NavLink>
                </Menu.Item>



                <Menu.Item key="16">
                  <NavLink
                    to="/Invoice_report"
                    activeClassName="active-link"
                  >
                    <LiaFileInvoiceSolid style={{fontSize:'19px'}} />
                  </NavLink>
                </Menu.Item>

              </Menu.SubMenu>
            )}
          </Menu>
        </ul>
      )
      }
    </aside >
  );
};
