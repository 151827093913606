import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  PublicClientApplication,
  EventType
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const msalConfig = {
  auth: {
    clientId: "9d456678-a395-45bc-9cf2-c80b081570b5",
    authority: "https://login.microsoftonline.com/79e52b1c-9099-4e1d-a436-283a07fa9b94",
    // redirectUri: "http://localhost:3002/",
    redirectUri: "https://my.jetzerp.com/",
    postLogoutRedirectUri: "/",

  },  
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const pca = new PublicClientApplication(msalConfig);
pca.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    isAuthenticationResult(event.payload)
  ) {
    pca.setActiveAccount(event.payload.account);
  }
});

function isAuthenticationResult(payload) {
  return (
    typeof payload === "object" &&
    "account" in payload &&
    "idToken" in payload &&
    "accessToken" in payload
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={pca}>
    <App />
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
