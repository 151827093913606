// src/components/modules/Settings.js

import React, { useState, useMemo } from "react";
import { Modal } from "../common/Modal";
import CustomTable from "../common/CustomTable";

export const Settings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([
    { projectName: 'Autobuy', client: 'wepaythemax', status: 'Active', assignedTo: 'Thaha' },
    { projectName: 'Fobesoft', client: 'Jordy', status: 'Resolved', assignedTo: 'Luffy' },
  ]);

  const columns = useMemo(() => [
    {
      accessorKey: 'projectName',
      header: 'Project Name',
      size:300,
    },
    {
      accessorKey: 'client',
      header: 'Client',
      size:300,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size:80,
    },
  ], [data]);

  const [newProject, setNewProject] = useState({
    projectName: '',
    client: '',
    status: 'Active',
    assignedTo: '',
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    setData(prevData => [
      ...prevData,
      {
        projectName: newProject.projectName,
        client: newProject.client,
        status: newProject.status,
        assignedTo: newProject.assignedTo
      }
    ]);
    setNewProject({ projectName: '', client: '', status: 'Active', assignedTo: '' });
    toggleModal();
  };

  return (
    <div>
      <div className="container mx-auto px-0">
        <div>
          <h2 className="text-[20px] font-[500]">Settings</h2>
        </div>
        {/* Create new project button */}
        <div className="flex justify-start">
          <button
            onClick={toggleModal}
            className="bg-blue-500 text-white py-1.5 px-3 rounded flex items-center mb-4 relative top-[1.4rem] z-[9] left-[1rem]"
          >
            <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Create New Project</span> 
          </button>
        </div>
        {/* Modal component */}
        {isModalOpen && (
          <Modal toggleModal={toggleModal}>
            <div className="bg-white rounded-lg">
              <h3 className="text-[20px] font-[500] mb-4">Create new project</h3>
              <form onSubmit={handleSubmit}>
                {/* Project Name */}
                <div className="mb-4">
                  <label className="block text-gray-700 text-[15px]">Enter project name</label>
                  <input
                    type="text"
                    value={newProject.projectName}
                    onChange={(e) => setNewProject({ ...newProject, projectName: e.target.value })}
                    className="w-full p-2 border border-gray-300 rounded mt-1 text-[14px]"
                    placeholder="Enter project name"
                  />
                </div>
                {/* Client Selection */}
                <div className="mb-4">
                  <label className="block text-gray-700 text-[15px]">Select client</label>
                  <select
                    value={newProject.client}
                    onChange={(e) => setNewProject({ ...newProject, client: e.target.value })}
                    className="w-full p-2 border border-gray-300 rounded mt-1 text-[14px]"
                  >
                    <option value="">Select client</option>
                    <option value="Client 1">Client 1</option>
                    <option value="Client 2">Client 2</option>
                  </select>
                </div>
                {/* Status Selection */}
                <div className="mb-4">
                  <label className="block text-gray-700 text-[15px]">Select status</label>
                  <select
                    value={newProject.status}
                    onChange={(e) => setNewProject({ ...newProject, status: e.target.value })}
                    className="w-full p-2 border border-gray-300 rounded mt-1 text-[14px]"
                  >
                    <option value="Active">Active</option>
                    <option value="Hold">Hold</option>
                    <option value="Proposed">Proposed</option>
                    <option value="Resolved">Resolved</option>
                    <option value="Closed">Closed</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>
                {/* Assign To Selection */}
                {/* <div className="mb-4">
                  <label className="block text-gray-700">Assign To</label>
                  <select
                    value={newProject.assignedTo}
                    onChange={(e) => setNewProject({ ...newProject, assignedTo: e.target.value })}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  >
                    <option value="">Select User</option>
                    <option value="Thaha">Thaha</option>
                    <option value="Luffy">Luffy</option>
                    <option value="Sanji">Sanji</option>
                    <option value="Zoro">Zoro</option>
                  </select>
                </div> */}
                {/* Action Buttons */}
                <div className="flex justify-end gap-3 mt-8">
                  <button
                    type="button"
                    className="text-gray-500 hover:text-gray-700 text-[15px]"
                    onClick={toggleModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded text-[15px]"
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        )}
        {/* Table component */}
        <div className="mt-[-40px] overflow-auto custom-table">
          <CustomTable
            columns={columns}
            data={data}
            pinColumn={['action']}
          />
        </div>
      </div>
    </div>
  );
};
