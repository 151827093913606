// src/App.js

import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect,useState} from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SSOSignin } from "./components/signin/SSOSignin";
import { Dashboard } from "./components/modules/Dashboard";
import { Layout } from "./components/constants/Layout";
import { Timesheet } from "./components/modules/Timesheet";
import {Teamtimesheet} from "./components/modules/Teamtimesheet";
import { Settings } from "./components/modules/Settings";
import { Employee } from "./components/modules/Employee";
import { TimeSheetOne } from "./components/modules/TimeSheetOne";
import { Project } from "./components/modules/Project";
import { Client } from "./components/modules/Clients";
import { Report } from "./components/modules/Report";
import {Employee_report} from  "./components/modules/Employee_report";

import { Employeerate } from "./components/modules/Employeerate";

import {setAxiosDefauls} from "./middleware/interceptor";
import { Timesheetapi } from "./middleware/interceptor";
import Reports from "./components/modules/ClientAllocation";
import ClientAllocation from "./components/modules/ClientAllocation";
import { Invoice_report } from "./components/modules/Invoice_report";

import { SiteDetails } from "./components/modules/SiteDetails";

import AWS from 'aws-sdk';

// Configure AWS SDK
AWS.config.update({
  accessKeyId: 'AKIA2JPUCIF2VPIQVPED', // Not recommended for frontend!
  secretAccessKey: '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', // Not recommended for frontend!
  region: 'us-east-1',
});



setAxiosDefauls();



function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

const [Emp_Role,setEmp_Role]=useState()
const [employee_id, setEmployee_id] = useState();
const [Secret_key , setSecret_key] = useState("");




  useEffect(() => {
    if (isAuthenticated) {
      const { username } = instance?.getActiveAccount();
      console.log("Email : ", username)



      console.log(localStorage.getItem('bearer-token'), "bearer-token");

      if((localStorage.getItem('bearer-token') != undefined || localStorage.getItem('bearer-token') != null) && isAuthenticated){
        console.log("Inside tok",localStorage.getItem('bearer-token'), username)
        getemployee(username)
        getSecretValue("TIMESHEET_KEYS")

      }
    }
  }, [isAuthenticated,localStorage.getItem('bearer-token')]);

  const getSecretValue = async (secretName) => {

    const secretsManager = new AWS.SecretsManager();

    try {
      const data = await secretsManager.getSecretValue({ SecretId: secretName }).promise();

      const secretkey = data.SecretString;
      const parsedSecret = JSON.parse(secretkey);
      const encryptionKey = parsedSecret.ENCRYPTION_KEY;
      setSecret_key(encryptionKey);
      console.log('Secret:', encryptionKey);



    } catch (error) {
      console.error('Error fetching secret:', error);
    }

    
  };

  const getemployee = async (username) => {
    try {
      const response = await Timesheetapi.get(`api/Projects/getemployeebyid/${username}/`);
      console.log("response", response.data.Employee[0].employee_id);
  
      setEmp_Role(response?.data?.Employee[0]?.employee_role_id);
      setEmployee_id(response?.data?.Employee[0]?.employee_id);
  
      localStorage.setItem('employee_id', response?.data?.Employee[0]?.employee_id);
      localStorage.setItem('emp_role_id', response?.data?.Employee[0]?.employee_role_id);
      localStorage.setItem('role_name', response?.data?.Employee[0]?.employee_role_name);
      localStorage.setItem('Emp_Assign_client', response?.data?.Employee[0]?.employee_proj);
      console.log(response.data.Employee, "employee_id");
    } catch (error) {
      console.error("Error fetching employee data: ", error);
    }
  };
  


console.log("Emp_Role",Emp_Role); 

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SSOSignin isAuthenticated={isAuthenticated}  setEmp_Role={setEmp_Role} Emp_Role={Emp_Role}/>} />
        <Route path="/" element={<Layout Emp_Role={Emp_Role} />}>
          {/* <Route path="dashboard" element={<Dashboard />} /> */}



          {(isAuthenticated && localStorage.getItem('emp_role_id')==1) && (
          <Route index path="timesheets" element={<Timesheet isAuthenticated={isAuthenticated}  employee_id={employee_id}/>} />
        )} 



          <Route path="settings" element={<Settings Emp_Role={Emp_Role} />} />

{
  isAuthenticated && localStorage.getItem('emp_role_id')==3 &&(
    <>
    <Route path="client-allocation" element={<ClientAllocation Secret_key={Secret_key} />} />
    <Route path="clients" element={<Client/>} />
    <Route path="report" element={<Report isAuthenticated={isAuthenticated} Secret_key={Secret_key} />} />
    <Route path="Employee_report" element={<Employee_report isAuthenticated={isAuthenticated} />} />
    <Route path="Invoice_report" element={<Invoice_report isAuthenticated={isAuthenticated} Secret_key={Secret_key} />} />
    <Route path="employeerate" element={<Employeerate isAuthenticated={isAuthenticated} Secret_key={Secret_key} />} />
    <Route path="projects" element={<Project isAuthenticated={isAuthenticated}/>} />
    <Route path="employee" element={<Employee  isAuthenticated={isAuthenticated} setEmployee_id={setEmployee_id} setEmp_Role={setEmp_Role} />} />
    <Route path="SiteDetails" element={<SiteDetails isAuthenticated={isAuthenticated}/>} />

    </>
  )
}
    {(isAuthenticated && localStorage.getItem('emp_role_id')==2) && (
      <>
      <Route path="projects" element={<Project isAuthenticated={isAuthenticated}/>} />
       <Route path="employee" element={<Employee  isAuthenticated={isAuthenticated} setEmployee_id={setEmployee_id} setEmp_Role={setEmp_Role} />} />
       <Route index path="timesheets" element={<Timesheet isAuthenticated={isAuthenticated}  employee_id={employee_id}/>} />
       <Route  path="Teamtimesheet" element={<Teamtimesheet isAuthenticated={isAuthenticated}  employee_id={employee_id}/>} />
</>
      )}         
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
