import React, { useState, useMemo, useEffect } from "react";
import { Modal } from "../common/Modal";
import CustomTable from "../common/CustomTable";
import { Switch, Input, Select, Tag, Form, Button, DatePicker,Table,Spin,message,Modal as AntdModal} from 'antd';
import { FaRegEdit,FaSave  } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import axios from 'axios';
import { data } from "autoprefixer";
import * as moment from 'moment';
import { Co2Sharp, Create } from "@mui/icons-material";
import { Box, Typography } from '@mui/material';
import { Timesheetapi } from "../../middleware/interceptor";
import { useMsal } from "@azure/msal-react";
import dayjs from 'dayjs';
import {ExclamationCircleFilled } from '@ant-design/icons';



import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { Employee } from "./Employee";



export const Client = ({ isAuthenticated }) => { 


    const initialData = [
        { client_id: '1', client_name: 'Client1', },
        { client_id: '2', client_name: 'Client2', },
        { client_id: '3', client_name: 'Client3', },
        { client_id: '4', client_name: 'Client4', },
        { client_id: '5', client_name: 'Client5', },
    ]

    const { RangePicker } = DatePicker;

    const [clientData, setClientData] = useState([]);


    const [isModalOpen, setIsModalOpen] = useState(false);

    const [clientdeletemodal, setclientdeletemodal] = useState(false);


    const [Clients] = Form.useForm();
    const [edit, setedit] = useState(false);

    const [clientedit, setclientedit] = useState(true);
    


    const [deletedata, setdeletedata] = useState();


    const [clientload, setclientload] = useState(false);




    useEffect(() => {

        getclientname();

    }, []);



    const getclientname = async () => {
 
      setclientload(true);
 
        try {
         
    const currentStartDate = moment().startOf('month').format('YYYY-MM-DD');
    const currentEndDate = moment().endOf('month').format('YYYY-MM-DD');
            const respons = await Timesheetapi.get(`api/Projects/getclientlist?Role_id=${localStorage.getItem('emp_role_id')}&empid=${localStorage.getItem('employee_id')}&sdate=${currentStartDate}&edate=${currentEndDate}`)
     
         
     
            const projects = respons.data.Clients;
 
            setclientload(false);
 
            // To remove duplicates based on `project_id`
            const uniqueProjects = Array.from(
              new Set(projects.map((project) => project.client_id)) // Extract unique project IDs
            ).map((id) => {
              // Return the project corresponding to each unique ID
              return projects.find((project) => project.client_id === id);
            });
 
            let data = uniqueProjects?.map((item) => {
              return {
                ...item,
                Totalhours:formatTime(item.Totalhours),
              };
            })
 
            console.log(uniqueProjects,"uniqueProjects");  
 
            setClientData(data);
        }
        catch (error) {
            console.log(error);
        }
 
    }


    const[isDisabled,setisDisabled]=useState(false)


    const columns = useMemo(() => [
  
        {
            accessorKey: 'client_name',
            header: 'Client Name',
            size: 190,
            align: 'right',
            enableColumnActions: false,
            muiTableHeadCellProps: {
              align: 'center',
            },
        },
        
     

    {
      accessorKey: 'approved_emp',
      header: 'Approved employee',
      size: 190,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },
      enableColumnActions: false,
  },



    {
      accessorKey: 'assigned_hours',
      header: 'Approved Hours',
      size: 190,
      align: 'left',
      muiTableHeadCellProps: {
        align: 'center',
      },
    
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },
      
      enableColumnActions: false,
  },

  {
    accessorKey: 'employee_count',
    header: 'Assigned employee',     /// list of employee
    size: -100,
    align: 'center',
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      style: {
        textAlign: 'right',
      },
    },
    enableColumnActions: false,
},

{
  accessorKey: 'Totalhours',
  header: 'Worked hours',     /// list of employee
  size: -100,
  align: 'center',
  muiTableHeadCellProps: {
    align: 'center',
  },
  muiTableBodyCellProps: {
    style: {
      textAlign: 'right',
    },
  },
  enableColumnActions: false,
},


        {
            accessorKey: 'id',
            header: 'Action',
            size: 80,
            enableColumnActions: false,
            muiTableHeadCellProps: {
              align: 'center',
            },
            Cell: ({ row }) => (
              <div className="flex justify-center gap-3">
               
                  <>
                    <FaRegEdit
                      onClick={() => toggleModals(row)}
                      className="text-[19.5px] text-[#3b82f6] cursor-pointer"
                    />
                    <FaTrashAlt
  onClick={() => toggledelete(row)} // Replace isDisabled with your condition
  //style={{ cursor: isDisabled ? "pointer" : "not-allowed" }} // Change cursor based on disable state
  className={`text-[16px] text-[#ff4d4d] cursor-pointer`} // Add opacity if disabled
/>

                  </>
                
              </div>
            ),
          }
],[clientData])





const formatTime = (totalMinutes) => {
  if (isNaN(totalMinutes) || totalMinutes == null) return "00:00"; // Handle NaN or null
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};


const cancelDelete = () => {
  setclientdeletemodal(false);
};


const confirmDelete = async () => {
 
  const res = await Timesheetapi.post('api/Projects/deleteclients',deletedata);

  if(res.data.status == "1"){
    success("client deleted successfully")

  }
  else{
  error("client not deleted")
  }
  setclientdeletemodal(false)

  
      getclientname();


};




const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

const toggleModalclick = () => {
  setclientedit(true);
    setedit(false);
    Clients.resetFields();
    toggleModal();
  };
  



  const toggleModals = (row) => {

    setclientedit(false);

    console.log(row.original.client_name,"doing");
    setedit(true);
    toggleModal();


    Clients.setFieldsValue({"Client_name": row.original.client_name,
        client_id: row.original.client_id,
        assigned_hours: row.original.assigned_hours,
        Approved_employee : row.original.approved_emp
    });



  }

  const toggledelete = async(row) => {  


    setclientdeletemodal(true);
    let payload={
        "clientid" : row.original.client_id,
        "clientname" :row.original.client_name,
        "updatedby" :localStorage.getItem('employee_id'),
        "defaults" :"0",
        "status":'2'
         
        }
        setdeletedata(payload)


  }

const handleSubmit = async (values) => {


    let payload= {}


    if(!edit){
         payload= {
            "clientname" :values.Client_name,
            "updatedby" : localStorage.getItem('employee_id'),
            "Assigned_hrs":values.assigned_hours,
            "Approved_employee":values.Approved_employee,
            "defaults" :"0"
            
           }


         const res =  await Timesheetapi.post('api/Projects/Addclients',payload);

         if(res.data.status == "1"){
          success("client created successfully")
     }
     else{
      error("client not created")
     }


              getclientname();

    }
    else{

         payload= {
            "clientid" : Clients.getFieldValue('client_id'),
           "clientname" :values.Client_name,
            "updatedby" : localStorage.getItem('employee_id'),
            "Assigned_hrs":values.assigned_hours,
            "Approved_employee":values.Approved_employee,
            "defaults" :"0"
            
           }


           const res = await Timesheetapi.post('api/Projects/Updateclients',payload);

           console.log(res,"hghgh")
            
           if(res.data.status == "1"){
                success("client updated successfully")
           }
           else{
            error("client not updated")
           }



    }

  
    getclientname();
    toggleModal();

}


const table = useMaterialReactTable({
  data: clientData,
  columns,
  enableColumnFilter: false,
  enableFullScreenToggle: false, 
  enableColumnFilters: false,      
  enableDensityToggle: false,      
  enableColumnVisibility: false,  
  enableHiding: false,
  //enableExpandAll: false, //hide expand all double arrow in column header
  getSubRows: (originalRow) => originalRow.subRows, //default, can customize
  initialState: { pagination: { pageSize: 25, pageIndex: 0 } },
});

// const table = useMaterialReactTable({
//   columns,
//   data:clientData,
//   enableExpandAll: false, //disable expand all button
//   muiDetailPanelProps: () => ({
//     sx: (theme) => ({
//       backgroundColor:
//         theme.palette.mode === 'dark'
//           ? 'rgba(255,210,244,0.1)'
//           : 'rgba(0,0,0,0.1)',
//     }),
//   }),
//   //custom expand button rotation
//   muiExpandButtonProps: ({ row, table }) => ({
//     onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
//     sx: {
//       transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
//       transition: 'transform 0.2s',
//     },
//   }),
//   //conditionally render detail panel
//   renderDetailPanel: ({ row }) =>
//     row.original.client_name ? (
//       <Box
//         sx={{
//           display: 'grid',
//           margin: 'auto',
//           gridTemplateColumns: '1fr 1fr',
//           width: '100%',
//         }}
//       >
//         <Typography>Address: {row.original.client_name}</Typography>
//         <Typography>City: {row.original.client_name}</Typography>
//         <Typography>State: {row.original.client_name}</Typography>
//         <Typography>Country: {row.original.client_name}</Typography>

//         <Typography>Addwwress: {row.original.client_name}</Typography>
//         <Typography>City: {row.original.client_name}</Typography>
//         <Typography>State: {row.original.client_name}</Typography>
//         <Typography>Country: {row.original.client_name}</Typography>

//       </Box>
//     ) : null,
// });




const handlemployehrs =(e)=>{




let value =e.target.value;


let calculatehours= (value*160);

console.log(calculatehours)


Clients.setFieldsValue({

  "assigned_hours":calculatehours

})


}


const [messageApi, contextHolder] = message.useMessage();

  const success = (msg) => {
    messageApi.open({
      type: 'success',
      title: 'Success',
      content: msg,
      duration:1.5
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      duration:1.5,
      content: 'This is an error message',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      title: 'Warning',
      duration:1.5,
      content: 'No more project available',
    });
  };


return (
    <div>
      {contextHolder}

<div className="flex justify-start">
          <button
            onClick={toggleModalclick}
            className="bg-blue-500 text-white py-[4px] px-3 rounded flex items-center mb-4 relative top-[50px] z-[9] left-[1rem]"
          >
            <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Add Clients</span>
          </button>

        </div> 

<div>


{/* <div style={{ left: "100px" }}>
      <RangePicker
        className="z-[99] left-[10px] top-[25px]"
        style={{ left: "100px" }}
        //onChange={handleDateChange}
        //defaultValue={selectedRange} // Show the selected range
        picker="month"
        placeholder={['Start Month', 'End Month']}
         format="MMMM YYYY"
      />
    </div> */}

{isModalOpen && (
          <Modal toggleModal={toggleModal}  >
            <div className="bg-white rounded-lg">
              <h3 className="text-[20px] font-[500] mb-2">{clientedit ?"ADD CLIENTS" : "UPDATE CLIENTS"}</h3>
              <Form  form={Clients} layout="vertical" onFinish={handleSubmit}> 
                
                <Form.Item
                      label="Client name"
                      name="Client_name"
                      placeholder="select"
                      rules={[{ required: true, message: 'Enter a client' }]}
                    >
                      <Input className="h-10"></Input>
                    </Form.Item>
                    
                    <Form.Item
                      label="Approved Employee"
                      name="Approved_employee"
                      placeholder="select"
                      rules={[{ required: true, message: 'Enter a approved employee' }]}
                    >
                      <Input className="h-10" onKeyDown={(event) => {
                          // Allow Backspace, Tab, Delete, and Arrow keys
                          if (
                            event.key !== 'Backspace' &&
                            event.key !== 'Tab' &&
                            event.key !== 'Delete' &&
                            event.key !== 'ArrowLeft' &&
                            event.key !== 'ArrowRight'
                          ) {
                            // Regex to allow only numbers and a single decimal point
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (!regex.test(event.target.value + event.key)) {
                              event.preventDefault();
                            }
                          }
                        }}    onChange={(e)=>{handlemployehrs(e)}}  ></Input>
                    </Form.Item>

                    <Form.Item
                      label="Approved Hours"
                      name="assigned_hours"
                      placeholder="select"
                      rules={[{ required: true, message: 'Enter a assigned hours' }]}
                    >
                      <Input className="h-10" onKeyDown={(event) => {
                          // Allow Backspace, Tab, Delete, and Arrow keys
                          if (
                            event.key !== 'Backspace' &&
                            event.key !== 'Tab' &&
                            event.key !== 'Delete' &&
                            event.key !== 'ArrowLeft' &&
                            event.key !== 'ArrowRight'
                          ) {
                            // Regex to allow only numbers and a single decimal point
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (!regex.test(event.target.value + event.key)) {
                              event.preventDefault();
                            }
                          }
                        }} ></Input>
                    </Form.Item>
                    

                    <Form.Item className="text-right footer-form-item">
                  <Button type="default" onClick={toggleModal} style={{ marginRight: '8px' }}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                 {clientedit ?"Add" : "Update"}
                  </Button>
                </Form.Item>

               </Form>


                </div>
                </Modal>

                



              )}
</div>


<div>
<AntdModal
        title={<><ExclamationCircleFilled  style={{ color: 'orange', marginRight: '8px' }} /> Confirm</>}
        visible={clientdeletemodal}
        onOk={confirmDelete}  
        onCancel={cancelDelete}
        footer={[
          <Button key="cancel" onClick={cancelDelete}>
            Cancel
          </Button>,
          <Button key="confirm" type="primary" onClick={confirmDelete}>
            Delete
          </Button>
        ]}
      >
        <p>Are you sure you want to delete this client? Deleting the client will also remove any related projects and the assigned tasks for employees</p>
      </AntdModal>

      </div>









    <div className="mt-[-10px]  custom-table">

{!clientload ? (
  
   <CustomTable
            columns={columns}
            data={clientData}
            pinColumn={['action']}
          />
):(
  
   <Spin tip="Loading..."className="justify-center items-center flex" style={{height:'100vh'}}>
            <CustomTable
            columns={columns}
            data={clientData}
            pinColumn={['action']}
          />
          </Spin>
)}

 
    {/* <MaterialReactTable
        table={table}
       
      /> */}

          </div>
          
             </div>
)   

}

