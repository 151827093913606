// src/components/constants/Layout.js

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { useMsal } from "@azure/msal-react";

export const Layout = ({Emp_Role}) => {
  const { instance } = useMsal();
  const { name } = instance?.getActiveAccount();
  const initials = name.split(" ").map(n => n[0]).join("").toUpperCase();

  const [isSignOutVisible, setIsSignOutVisible] = useState(false);

  const toggleSignOut = () => {
    setIsSignOutVisible(!isSignOutVisible);
  };

  const handleSignOut = () => {
    instance.logoutRedirect();
    localStorage.clear();
  };

  return (
    <div className="flex h-screen">
      <Sidebar Emp_role={Emp_Role} />
      <main className="flex-1 py-10 px-3 relative bg-gray-100">
        <Header
          user_name={name}
          initials={initials}
          toggleSignOut={toggleSignOut}
          isSignOutVisible={isSignOutVisible}
          handleSignOut={handleSignOut}
          Emp_role={Emp_Role}
        />
        <Outlet />
      </main>
    </div>
  );
};
