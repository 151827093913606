// src/components/common/CustomTable.js
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { TbFileTypePdf } from "react-icons/tb";

import React, { useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';

const CustomTable = ({ columns, data,component,handleExportData,handleExportRows }) => {

  useEffect(() => {
    console.log('Testdata', data, component);
  }, [data]);

  const table = useMaterialReactTable({
    data: data,
    columns: columns,
    enableGlobalFilter: ((component !== 'Report') && (component !== 'Allocation') && (component!=="InvoiceReport")),
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableColumnVisibility: false,
    enableHiding: false,
    defaultDisplayColumn: {
      enableColumnOrdering: true,
      enableColumnResizing: true,
      minSize: 100,
    },
    initialState: { pagination: { pageSize: 25, pageIndex: 0 } },
   

    renderTopToolbarCustomActions: (component === 'Report' || component==='Allocation') ? 
    
    ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
          justifyContent: 'end',
          width: '100%',
        }}
      >
        <Button
 onClick={() =>
  handleExportData(table.getPrePaginationRowModel().rows)
}            startIcon={<FileDownloadIcon />}
        >
          Export
        </Button>
      </Box>
    ) : (component == "InvoiceReport")?({ table }) => (
   
    <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
          justifyContent: 'end',
          width: '100%',
        }}
      >
        <Button
 onClick={() =>
  handleExportRows(table.getPrePaginationRowModel().rows)
}            startIcon={<TbFileTypePdf />}
        >
          generate
        </Button>
      </Box>

    ):null,
  });



  console.log(table.enableColumnFilters,'uyuyy')

  return (
    
    <div className='custom-material'>
      
      <MaterialReactTable
        table={table}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 20],
        }}
        muiTableBodyRowProps={(row) => ({
          sx: {
            fontWeight: row.getValue('project') === 'Total' ? 700 : 'normal',
          },
        })}
      />
    </div>
  );
};

export default CustomTable;
