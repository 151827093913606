// src/components/signin/SSoSignin.js

import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import axios from 'axios';
import {LocalStorageService} from "../service/LocalStorageService";
//import { Timesheet } from "../middleware/interceptor";

import {Timesheetapi} from "../../middleware/interceptor";

export const SSOSignin = ({ isAuthenticated,setEmp_Role,Emp_Role }) => {

  const { instance } = useMsal();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const Loader =
    <TailSpin
      visible={true}
      height="30"
      width="50"
      radius="1"
    />;

  const handleSignInWithMicrosoft = async () => {
    setIsLoading(true);
    instance.loginRedirect({
      scopes: ["user.read"],
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      const { name,username } = instance?.getActiveAccount();
      console.log("username", name,username);
      setIsLoading(false);

let empdata={
  employee_name:name,
  employee_email:username,
}

       saveemployee(empdata,username);

      


    }
  }, [isAuthenticated]);



  const saveemployee = async (data,username) => {
    let header = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    const response = await Timesheetapi.post('api/Projects/emplpoyee',data,{
    });
console.log(response,"response");

    if (response.status==200) {



      if(localStorage.getItem("bearer-token")==null && localStorage.getItem("bearer-token")==undefined )
        
        {

        const payload = new URLSearchParams();

        payload.append('username', response.data.username);
        payload.append('password', response.data.password);
        payload.append('grant_type', 'password');
  
        console.log(response.data,"token");
          await Timesheetapi.post('token', payload, {
            headers: header
          }).then((res) => {
          console.log(res);
          LocalStorageService.setToken(res.data.access_token)
          
          setEmp_Role(response.data.Emp_Role);
          getemployee(username)
        }
        ).catch((err) => {
          console.log(err);
        });
      }
      else{

        setEmp_Role(localStorage.getItem('emp_role_id'));

        getemployee(username)
      }
     

      console.log("Employee saved successfully");
    }
  }


  
const getemployee = async (username) => {
  const response = await Timesheetapi.get(`api/Projects/getemployeebyid/${username}/`);
  console.log("response", response.data.Employee[0].employee_id);

  localStorage.setItem('employee_id', response?.data?.Employee[0]?.employee_id);
  localStorage.setItem('emp_role_id', response?.data?.Employee[0]?.employee_role_id);


  console.log(response.data.Employee,"employee_id");


  if(localStorage.getItem('emp_role_id')!=3){
    navigate('timesheets', { state: { Username: response.data.username } });

  }
  else{
    navigate('employee', { state: { Username: response.data.username } });


  }



}


  
  return (
    // <div className="flex justify-center items-center h-screen bg-gray-100">
    //   {isLoading ? Loader : (
    //     <button
    //       className="bg-[#3f51b5] text-white px-6 py-3 rounded-lg shadow-lg flex items-center gap-3 hover:bg-[#3549a1] transition-colors duration-300"
    //       onClick={handleSignInWithMicrosoft}
    //     >
    //       <img style={{ width: "1.5em" }} src="./microsoft.png" alt="Microsoft logo" />
    //       Sign in with Microsoft
    //     </button>
    //   )}
    // </div>

    <div>
      <div className="h-screen grid grid-cols-2">
        <div className="flex justify-center items-center md:flex">
          <img className="w-full h-full object-cover" src="/Timesheet.jpeg" alt="signin" />
          {/* <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
          >
            <source src={VideoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
        </div>
        <div className="bg-blue-100 flex flex-col gap-6 justify-center items-center p-10 sm:p-12.5 xl:p-17.5">
          <img className="sm:max-w-full max-w-48" src="./jetz-logo.png" />
          {isLoading ? Loader : (
            <button
              style={{ backgroundColor: 'rgb(213, 43, 43)' }} // Use inline style
              className="w-full text-white justify-center px-5 py-5 rounded-md flex gap-5 items-center"
              onClick={handleSignInWithMicrosoft}
            >
              <img style={{ width: "1.5em" }} src="./microsoft.png" alt="microsoft logo" />
              Sign in with Microsoft
            </button>

            // <button
            //   className="bg-[#3f51b5] w-full text-white justify-center px-5 py-5 rounded-md flex gap-5 items-center"
            //   onClick={handleSignInWithMicrosoft}
            // >
            //   <img style={{ width: "1.5em" }} src="./microsoft.png" alt="microsoft logo" />
            //   Sign in with Microsoft
            // </button>
          )}
        </div>
      </div>
    </div>
  );
}
