// src/components/modules/Timesheet.js

import React, { useState, useMemo, useEffect } from "react";
import CustomTable from "../common/CustomTable";

import { Form, Input, Button,Drawer} from 'antd';

import { FaRegEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { Modal } from "../common/Modal";
import { Select, message,DatePicker,Spin,Modal as AntdModal } from 'antd';
import { LuPlusCircle } from "react-icons/lu";
import { Switch } from 'antd';
import axios from 'axios';
import * as moment from 'moment';
import { Timesheetapi } from "../../middleware/interceptor";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMsal } from "@azure/msal-react";
import { Navigate,useNavigate } from "react-router-dom";
import {ExclamationCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';


const { RangePicker } = DatePicker;

const options = [
  { value: 'Fobesoft', label: 'Fobesoft' },
  { value: 'Autobuy', label: 'Autobuy' },
  { value: 'wepaythemax', label: 'wepaythemax' },
  { value: 'transcredit', label: 'transcredit' },
];
// for (let i = 10; i < 36; i++) {
//   options.push({
//     value: i.toString(36) + i,
//     label: i.toString(36) + i,
//   });
// }



export const Employee = ({ isAuthenticated,setEmp_Role,setEmployee_id }) => {

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [loading,setloading]=useState(false)

  const { instance } = useMsal();

  const [employeedeletemodal, setemployeedeletemodal] = useState(false);
  const [deletedata, setdeletedata] = useState();


  const [clients, setClients] = useState([]);

  const [projects, setprojects] = useState([]);

  const [dates, setDates] = useState([null, null]);

  const [projectstemp, setprojectstemp] = useState([]);

  const [grade, setgrade] = useState([]);

  const [sdate,setsdate]=useState();
  const [edate,setedate]=useState();


  const [edit, setedit] = useState(false);

  const [emp_id, setemp_id] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
const [employelog,setemployelog]=useState([]);


  const currentStartDate = dayjs().startOf('month');
  const currentEndDate = dayjs().endOf('month');

  // Initialize state for the selected date range
  const [ selectedRange, setSelectedRange] = useState([currentStartDate, currentEndDate]);

  const toggleModalclick = () => {


    form.resetFields();

    setIsModalOpen(!isModalOpen);
    setedit(false);
  };



  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setedit(false);
  };

  const [messageApi, contextHolder] = message.useMessage();


  const success = (content) => {
    messageApi.open({
      type: 'success',
      title: 'Success',
      content: content,
      duration:1.5
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      duration:1.5,
      content: 'This is an error message',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      title: 'Warning',
      duration:1.5,
      content: 'No more project available',
    });
  };




  const [emprole, setemprole] = useState();



  const [data, setData] = useState([
    { employeeName: 'Thaha', role: 'Develober', email: 'thaha@jetzerp.com', status: '', action: '' },
    { employeeName: 'Muthu', role: 'Develober', email: 'muthu@jetzerp.com', status: '', action: '' },
  ]);



  const [clientselected, setclientselected] = useState([]);
  const [projectselected, setprojectselected] = useState([]);

  const handleChange = (value) => {
 
   
 
    let selectedValues;
    if(projectassign==21){
        selectedValues = [value]; // Your selected multiple values
 
        console.log(selectedValues,"loooo")
    }
    else{
      selectedValues = value; // Your selected multiple values
      console.log(selectedValues,"koooo")
 
    }
   
 
   //setclientselected([...value]);
 
     console.log(selectedValues,"Mani")
 
 
 
 
 
 
    //  let proj = projectstemp
    //  ?.filter(Projects => selectedValues?.includes(Projects?.client_id)) // Filter based on selected value
    //  .filter(Projects => Projects?.Project_id != null && Projects?.projectname != null) // Filter out null values
    //  .map(Projects => ({
    //    value: Projects.Project_id,
    //    label: Projects.projectname,
    //  }));
 
   
    let proj = projectstemp
    ?.filter(Projects => selectedValues?.includes(Projects?.client_id)) // Filter based on selected value
    .filter(Projects => Projects?.Project_id != null && Projects?.projectname != null) // Filter out null values
    .filter(Projects => {
      // Parse end_date in DD-MM-YYYY format
 
      let Dateformat= dayjs(Projects?.end_date).format('DD-MM-YYYY')
      const [day, month, year] = Dateformat.split('-');
      const endDate = new Date(`${year}-${month}-${day}`); // Rearrange into YYYY-MM-DD format
 
      // Remove the time component by setting to midnight (date-only comparison)
      const endDateOnly = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
      const currentDateOnly = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
 
    console.log(Projects?.end_date, endDateOnly,currentDateOnly,projectstemp,"Miller Muthu")
 
      return endDateOnly >= currentDateOnly; // Include only if endDate is today or in the future
    })
    .map(Projects => ({
      value: Projects.Project_id,
      label: Projects.projectname,
      dates: Projects?.end_date,
    }));
 
 
 
      console.log('projTYRTY', proj);
 
 
 
 
      let fileter = projectstemp
  ?.filter(Projects => {
 
    return (
      selectedValues.includes(Projects?.client_id) && // Check if the client_id is in the selectedValues
      (form.getFieldValue('assign_Projects') || []).includes(Projects?.Project_id) && // Check if it's also in the form's assign_Projects
      Projects?.Project_id != null && // Avoid null Project_id
      Projects?.projectname != null // Avoid null projectname
    );
  })
  .map(Projects => ({
     value:Projects?.Project_id && Projects?.Project_id,
    label: Projects?.Project_id && Projects?.projectname,
  }));
 
 
      console.log('filetersss',fileter);
 
 
 
      form.setFieldsValue({
        'assign_Projects': fileter
          .filter(f => f.value !== null && f.value !== undefined) // Explicitly exclude null and undefined
          .map(f => f.value)
      });
     
 
     
 
    setprojects(proj);
 
    console.log('proj', form.getFieldValue('assign_Projects'));
 
 
  };


  const handleChangeproject = (value) => {
    console.log(`selected ${value}`);
  };



  const formatTime = (totalMinutes) => {
    if (isNaN(totalMinutes) || totalMinutes == null) return "00:00"; // Handle NaN or null
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };


  useEffect(() => {
    if (isAuthenticated) {
      const { name, username } = instance?.getActiveAccount();
      console.log("username", name, username);
      getemployee(username);

    }
  }, [isAuthenticated]);



  const getemployee = async (Username) => {

    const { name, username } = instance?.getActiveAccount();
    
    setloading(true)

    console.log(username,'user')

    const currentStartDate = moment().startOf('month').format('YYYY-MM-DD');
    const currentEndDate = moment().endOf('month').format('YYYY-MM-DD');


    await Timesheetapi.get(`api/Projects/getemployee?sdate=${currentStartDate}&edate=${currentEndDate}&emp_id=${localStorage.getItem('employee_id')}&roleid=${localStorage.getItem('emp_role_id')}`)
      .then(async (response) => {

        const gradedetails = await Timesheetapi.get(`api/Projects/Getempgradedata`);

        const respons = await Timesheetapi.get(`api/Projects/getprojectdetailinemployee?Role_id=${localStorage.getItem('emp_role_id')}&empid=${localStorage.getItem('employee_id')}`);


   
        const clientOptions = respons?.data?.projects?.map(Projects => ({
          value: Projects.client_id,
          label: Projects.client_name,
        })).filter((item, index, self) => index === self.findIndex(t => t.value === item.value)
        );

        setClients(clientOptions);

const gradedata= gradedetails?.data?.Emp_Grade_Data?.map(grade => ({

   value: grade.id,
  label: grade.grade_name,

}));
        
setgrade(gradedata);

        // const projectOptions = respons.data.projects.map(Projects => ({
        //   value: Projects.projectname,     
        //   label: Projects.projectname,//Project_id
        // })).filter((item, index, self) => 
        //   index === self.findIndex(t => t.value === item.value)
        // );

        setprojectstemp(respons?.data?.projects);



        const clientOption = response?.data?.Emp_Role.map(client => ({
          value: client.employee_id+','+client.employee_role,
          label: client.employee_role,
        }));


        let data = response?.data?.Employee?.map((item) => {
          return {
            ...item,
            employee_create_date: item.employee_create_date ? moment(item.employee_create_date).format('YYYY-MM-DD') : null,
            employee_update_date: item.employee_update_date ? moment(item.employee_update_date).format('YYYY-MM-DD') : null,
            Total_hours:formatTime(item.Total_hours),
          };
        }).filter((item) => item.del_employee_status !== '1');


console.log(data,'datadata')

        setemprole(clientOption)
        setData(data);

        setloading(false)



        getemployeebyid(username)

      })
      .catch((error) => {
        setData([]);
        console.error('There was an error!', error);
      });
  }




  const SwitchonChange = async (e, checked) => {

    await Timesheetapi.post(`api/Projects/updateemployeestatus?id=${checked.employee_id}&status=${e ? 1 : 0}`)
      .then((response) => {
        const updatedData = data.map((item) => {
          if (item.employee_id === checked.employee_id) {
            item.employee_status = e ? "1" : "0";
          }
          return item;
        });

        setData(updatedData)
      }).catch((error) => {
        console.error('Error updating project status:', error);
      });


  };

  const toggledelete = async (row) => {

    setemployeedeletemodal(true);
    let del = row.original.employee_id;

    setdeletedata(row.original.employee_id);

    
  };




  const handleFinish = async (values) => {

    console.log('Form values:', values);
    let payload = {
      "employeeName": values.employeeName,
      "role": values.role,
      "email": values.email,
      "assign_Clients": [values.assign_Clients],
      "assign_Projects": [values.assign_Projects],
      "grade": values.Grade

    }



    console.log('payload', payload);

    if (!edit) {
      await Timesheetapi.post('api/Projects/CreateEmployee', payload)

        .then((response) => {
          console.log('response', response.data);
          if (response.data.Msg == "Employee created successfully") {

            toast.success(response.data.Msg);
            getemployee();
            setIsModalOpen(false);
            success('Employee created successfully')


            let logerror={
              "Employee_id": localStorage.getItem('employee_id'),
              "role_name": localStorage.getItem('role_name'),
              "Event_name": "Add Employee",
              "updatedby": localStorage.getItem('employee_id'),
              log_details:{
                old_changes:employelog,
                new_changes:payload
              }
              
            }


           // Activity_log(logerror);

          }
          else {
            toast.warning(response.data.Msg);
            setIsModalOpen(false);

          }
        })
    }
    else {

      let payload = {
        "employeeName": values.employeeName,
        "role": role,
        "role_id":projectassign,
        "email": values.email,
        "assign_Clients":projectassign == 2?[values.assign_Clients]:[values.assign_Clients],
        "assign_Projects": [values.assign_Projects],
        "employee_id": emp_id,
        "grade": values.Grade
      }


      await Timesheetapi.post('api/Projects/updateemployedata',payload) 

      .then((response) => {
        console.log('response', response.data);
        if (response.data.Msg == "Employee updated successfully") {
          //toast.success(response.data.Msg);
           getemployee();
          success('Employee updated successfully')



          let logerror={
            "Employee_id": localStorage.getItem('employee_id'),
            "role_name": localStorage.getItem('role_name'),
            "Event_name": "Update Employee",
            "updatedby": localStorage.getItem('employee_id'),
            log_details:{
              old_changes:employelog,
              new_changes:payload
            }
            
          }

         // Activity_log(logerror);


        }
        else{
          toast.warning(response.data.Msg);
        }
        setIsModalOpen(false);

      }).catch((error) => {
        console.error('Error updating project status:', error);
      })

    }


  };


  const getemployeebyid = async (username) => {
    try {
      const response = await Timesheetapi.get(`api/Projects/getemployeebyid/${username}/`);
      console.log("response", response.data.Employee[0].employee_id);
  
      setEmp_Role(response?.data?.Employee[0]?.employee_role_id);
      setEmployee_id(response?.data?.Employee[0]?.employee_id);


      if(response?.data?.Employee[0]?.employee_role_id==1){


        navigate('/timesheets'); // Use navigate function here

      }
  
      localStorage.setItem('employee_id', response?.data?.Employee[0]?.employee_id);
      localStorage.setItem('emp_role_id', response?.data?.Employee[0]?.employee_role_id);
  
      console.log(response.data.Employee, "employee_id");
    } catch (error) {
      console.error("Error fetching employee data: ", error);
    }
  };


const [role,setrole]=useState();

const [projectassign,setprojectassign]=useState();

const [role_id,setroleid]=useState();
  const toggleedit = async (row) => {
    console.log('row', row);



    console.log('row_project', row.original.employee_proj, row.original.employee_client);

    setprojectassign(row.original.employee_role_id)

    setedit(true);


    if (row.original.employee_proj !== null) {


let proj = projectstemp?.filter(Projects => row.original.employee_client.includes(Projects.client_id)) // Filter based on selected value
.filter(Projects => Projects?.Project_id != null && Projects?.projectname != null) // Filter out null values
      .map(Projects => ({
        value: Projects.Project_id,
        label: Projects.projectname,
      }));

      console.log(proj,"growwww")

      console.log(projectstemp,"greek")

    setprojects(proj);



    let clients
    let projects
    if(row.original.employee_role_id==22){
 
       projects =  row.original.employee_proj
       .replace(/\s+/g, '')  
       .slice(1, -1)        
       .split(',')           
       .map(Number);
  
     
      if(row.original.employee_client!=null){
        clients = row.original.employee_client
        .replace(/\s+/g, '')  // Remove all spaces and newlines
        .slice(1, -1)         // Remove the square brackets
        .split(',')           // Split by commas
        .map(Number);  
 
      }


      console.log(projects,"hghghghg")

      form.setFieldsValue({
        employeeName: row.original.employee_name,
        role: row.original.employee_role,
        employee_role_id: row.original.employee_role_id,
        email: row.original.employee_email,
        assign_Clients: projects?projects:null,
        assign_Projects: clients?clients:null,
        Grade: row.original.grade?parseInt(row.original.grade):null

      });
      

      
    }
    else{
   
      projects = row.original.employee_proj
      .replace(/\s+/g, '')  // Remove all spaces and newlines
      .slice(1, -1)         // Remove the square brackets
      .split(',')           // Split by commas
      .map(Number);
 
     
      if(row.original.employee_client!=null){
        clients = row.original.employee_client
        .replace(/\s+/g, '')  // Remove all spaces and newlines
        .slice(1, -1)         // Remove the square brackets
        .split(',')           // Split by commas
        .map(Number);  
 
      }
      console.log(projects,clients,"ergfuihdfv")

      form.setFieldsValue({
        employeeName: row.original.employee_name,
        role: row.original.employee_role,
        employee_role_id: row.original.employee_role_id,
        email: row.original.employee_email,
        assign_Clients: clients?clients:null,
        assign_Projects: projects?projects:null,
        Grade: row.original.grade?parseInt(row.original.grade):null

      });
      
    }

     

        console.log('projects', projects,clients);

      
    }
    else {
      

      form.resetFields();

      form.setFieldsValue({
        employeeName: row.original.employee_name,
        employee_role_id: row.original.employee_role_id,
        role: row.original.employee_role,
        email: row.original.employee_email,
      });
    }


    setrole(row.original.employee_role_id+','+row.original.employee_role)



    console.log(row.original.employee_role_id+','+row.original.employee_role,"rolerrr")

    setemp_id(row.original.employee_id);

    setemployelog(row.original)

    // const payload = {
    //   employeeName: row.original.employee_name,
    //   role: row.original.employee_role,
    //   email: row.original.employee_email,
    //   assignProject: projects, // This should be an array or formatted string as needed
    // };

    // await axios.post('https://localhost:44385/api/Projects/updateemployedata',payload) 

    // .then((response) => {
    //   console.log('response', response.data);
    //   if (response.data.Msg == "Employee updated successfully") {
    //     getemployee();
    //     setIsModalOpen(false);
    //   }
    // })



    setIsModalOpen(true);
  };



const handleemprole=(value)=>{

  console.log('value',value);

  setrole(value)
  setprojectassign(value.split(',')[0]);



  form.setFieldsValue({'assign_Clients':null,'assign_Projects':null}
     
  )


}

  const columns = useMemo(() => [

    // {
    //   accessorKey: 'employee_create_date',
    //   header: 'Create Date',
    //   size: 80,
    //   enableColumnActions: false,
    //   muiTableHeadCellProps: {
    //     align: 'center',
    //   },
    // },

    {
      accessorKey: 'employee_name',
      header: 'Employee Name',
      size: 200,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
     
    },
    
    
    // {
    //   accessorKey: 'employee_email',
    //   header: 'Email Id',
    //   size: 80,
    //   enableColumnActions: false,
    //   muiTableHeadCellProps: {
    //     align: 'center',
    //   },
    // },
    // {
    //   accessorKey: 'employee_update_date',
    //   header: 'Updated Date',
    //   size: 50,
    //   enableColumnActions: false,
    // },
    // {
    //   accessorKey: 'employee_status',
    //   header: 'Status',
    //   size: 80,
    //   // enableColumnActions: false,
    //   Cell: ({ row }) => (
    //     <div className="flex justify-center gap-3">
    //       <Switch size="small" defaultChecked
    //         checked={row.original.employee_status == 1}
    //         value={row.original.employee_status}
    //         onChange={(e) => SwitchonChange(e, row.original)} />
    //     </div>
    //   ),
    // },
    {
      accessorKey: 'employee_role',
      header: 'Role',
      size: 130,
      muiTableHeadCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'clients_name',
      header: 'Client',
      size: 130,
      muiTableHeadCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'project_name',
      header: 'Projects',
      size: 130,
      muiTableHeadCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'Total_hours',
      header: 'Worked Hours',
      size: 130,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      }
    },
    ...(localStorage.getItem('emp_role_id') === '3' ? [
    {
      accessorKey: 'action',
      header: 'Action',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <div className="flex justify-center gap-3">
          <FaRegEdit onClick={() => toggleedit(row)}
            className="text-[19.5px] text-[#3b82f6] cursor-pointer"
          />
          <FaTrashAlt
          style={{cursor:localStorage.getItem('emp_role_id')==3?"pointer":'not-allowed'}}
            onClick={() => localStorage.getItem('emp_role_id')==3 && toggledelete(row)}
            className="text-[16px] text-[#ff4d4d] cursor-pointer"
          />
        </div>
      ),
    },
  ]:[])
  ], [data]);




  // const handleDateChange =async (date, dateString) => {


  //   console.log('date',dateString[0],dateString[1]);
  //   setsdate(dateString[0]);
  //   setedate(dateString[1])


  //   // await Timesheetapi.get(`api/Projects/getemployee?sdate=${dateString[0]}&edate=${dateString[1]}`)
  //   //   .then(async (response) => {
  //   //     const respons = await Timesheetapi.get(`api/Projects/getprojectdetails`);

  //   //     const clientOptions = respons?.data?.projects?.map(Projects => ({
  //   //       value: Projects.client_id,
  //   //       label: Projects.client_name,
  //   //     })).filter((item, index, self) => index === self.findIndex(t => t.value === item.value)
  //   //     );
  //   //     setClients(clientOptions);

  //   //     // const projectOptions = respons.data.projects.map(Projects => ({
  //   //     //   value: Projects.projectname,     
  //   //     //   label: Projects.projectname,//Project_id
  //   //     // })).filter((item, index, self) => 
  //   //     //   index === self.findIndex(t => t.value === item.value)
  //   //     // );

  //   //     setprojectstemp(respons?.data?.projects);



  //   //     const clientOption = response?.data?.Emp_Role.map(client => ({
  //   //       value: client.employee_id+','+client.employee_role,
  //   //       label: client.employee_role,
  //   //     }));


  //   //     let data = response?.data?.Employee?.map((item) => {
  //   //       return {
  //   //         ...item,
  //   //         employee_create_date: item.employee_create_date ? moment(item.employee_create_date).format('YYYY-MM-DD') : null,
  //   //         employee_update_date: item.employee_update_date ? moment(item.employee_update_date).format('YYYY-MM-DD') : null,
  //   //       };
  //   //     })




  //   //     setemprole(clientOption)
  //   //     const filterdata = data?.filter((item) => item.del_employee_status !== '1');
  //   //     setData(filterdata);


  //   //   })
  //   //   .catch((error) => {
  //   //     setData([]);
  //   //     console.error('There was an error!', error);
  //   //   });

  // }

  const handleDateChange = async (date, dateString) => {
    setDates(date);
    console.log("heas")

    if (date && date.length === 2) {
    setloading(true)
      // Get the selected start month and end month
      const startMonth = date[0]; // Start month
      const endMonth = date[1]; // End month

      // Get the first day of the start month
      const startOfMonth = startMonth.startOf('month').format('YYYY-MM-DD');
      // Get the last day of the end month
      const endOfMonth = endMonth.endOf('month').format('YYYY-MM-DD');

      console.log('Start Date:', startOfMonth);
      console.log('End Date:', endOfMonth);


        await Timesheetapi.get(`api/Projects/getemployee?sdate=${startOfMonth}&edate=${endOfMonth}&emp_id=${localStorage.getItem('employee_id')}&roleid=${localStorage.getItem('emp_role_id')}`)
      .then(async (response) => {

        setloading(false)
        const respons = await Timesheetapi.get(`api/Projects/getprojectdetailinemployee?Role_id=${localStorage.getItem('emp_role_id')}&empid=${localStorage.getItem('employee_id')}`);

        const clientOptions = respons?.data?.projects?.map(Projects => ({
          value: Projects.client_id,
          label: Projects.client_name,
        })).filter((item, index, self) => index === self.findIndex(t => t.value === item.value)
        );
        setClients(clientOptions);

        // const projectOptions = respons.data.projects.map(Projects => ({
        //   value: Projects.projectname,     
        //   label: Projects.projectname,//Project_id
        // })).filter((item, index, self) => 
        //   index === self.findIndex(t => t.value === item.value)
        // );

        setprojectstemp(respons?.data?.projects);



        const clientOption = response?.data?.Emp_Role.map(client => ({
          value: client.employee_id+','+client.employee_role,
          label: client.employee_role,
        }));


        let data = response?.data?.Employee?.map((item) => {
          return {
            ...item,
            employee_create_date: item.employee_create_date ? moment(item.employee_create_date).format('YYYY-MM-DD') : null,
            employee_update_date: item.employee_update_date ? moment(item.employee_update_date).format('YYYY-MM-DD') : null,
            Total_hours:formatTime(item.Total_hours)
          };
        })




        setemprole(clientOption)
        const filterdata = data?.filter((item) => item.del_employee_status !== '1');
        setData(filterdata);


      })
      .catch((error) => {
        setData([]);
        console.error('There was an error!', error);
      });


      setsdate(startOfMonth);
      setedate(endOfMonth);
    }
  };


  const cancelDelete = () => {
    setemployeedeletemodal(false);
  };
  
  
  const confirmDelete = async () => {

    try {
      const response = await Timesheetapi.post(`api/Projects/deleteemployee?id=${deletedata}`);

      console.log('response', response.data);


      if (response.data == "deleted sucessfully") {

        setData((prevData) => prevData.filter((item) => item.employee_id !== deletedata));

        toast.message(response.data);

        console.log(data, 'delete')

      }


    } catch (error) {
      console.error('Error fetching client names:', error);

      toast.error("Not deleted");
    }

    setemployeedeletemodal(true);
  }



  return (
    <>
      <section className="employee_section">
      {contextHolder}

        <ToastContainer />
        <div>
          <h2 className="text-[20px] font-[500]">Employee</h2>
        </div>
        <div className="flex justify-start">
          <button
            onClick={toggleModalclick}
            className="bg-blue-500 text-white py-[4px] top-[20px] px-3 rounded flex items-center mb-4 relative z-[9] left-[1rem]"
          >
            <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Add Employee</span>
          </button>
          <div style={{ left: "100px" }}>
      <RangePicker
        className="z-[9] left-[10px] top-[20px]"
        style={{ left: "25px",paddingBlock:'5px' }}
        onChange={handleDateChange}
        defaultValue={selectedRange} // Show the selected range
        picker="month"
        placeholder={['Start Month', 'End Month']}
         format="MMMM YYYY"
      />
    </div>
    
        </div>

        <div>
<AntdModal
        title={<><ExclamationCircleFilled  style={{ color: 'orange', marginRight: '8px' }} /> Confirm</>}
        visible={employeedeletemodal}
        onOk={confirmDelete}  
        onCancel={cancelDelete}
        footer={[
          <Button key="cancel" onClick={cancelDelete}>
            Cancel
          </Button>,
          <Button key="confirm" type="primary" onClick={confirmDelete}>
            Delete
          </Button>
        ]}
      >
        <p>Are you sure you want to delete this Employee?</p>
      </AntdModal>

      </div>

{
  loading?(<>
  
  <Spin tip="Loading..." className="justify-center items-center flex" style={{height:'100vh'}}>
        
        <div className="mt-[-40px] overflow-auto custom-table">
          <CustomTable
            columns={columns}
            data={[]}
            pinColumn={['action']}
          />
        </div>
        </Spin>
        
  </>):(
    <>        
        <div className="mt-[-40px] overflow-auto custom-table">
          <CustomTable
            columns={columns}
            data={data}
            pinColumn={['action']}
          />
        </div>
    </>)
}
        
        {isModalOpen && (
          <Modal toggleModal={toggleModal}>
            <div className="bg-white rounded-lg">
              <Form form={form} onFinish={handleFinish} layout="vertical">
                <div className="mt-4">
                  <Form.Item
                    label="Employee Name"
                    name="employeeName"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the employee name',
                      },
                    ]}
                  >
                    <Input className="h-9" disabled={localStorage.getItem('emp_role_id')==2?true:false}  />
                  </Form.Item>
                </div>

                <div className="grid grid-cols-2 gap-x-3 mt-4">
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a role',
                      },
                    ]}
                  >
                    <Select className="h-10"
                      //defaultValue="member"
                      disabled={localStorage.getItem('emp_role_id')==2?true:false}
                      options={emprole}
                      onChange={handleemprole}
                      
                    >
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Email Id"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the email',
                      },
                      {
                        type: 'email',
                        message: 'Please enter a valid email',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || value.endsWith('@jetzerp.com')) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Email must be from the jetzerp.com domain'));
                        },
                      }),
                    ]}
                  >
                    <Input className="w-[100%] h-10" disabled={edit} />
                  </Form.Item>
                </div>

                {/* <Form.Item
                  label="YOE"
                  name="yoe"
                  rules={[
                    {
                      required: true,
                      message: 'Please select at least one year',
                    },
                  ]}
                >
                  <Input></Input>
                </Form.Item> */}



{
  (projectassign!=3) &&(


<div className="grid grid-cols-2 gap-x-3 mt-0">

               <Form.Item

                  label="Grade"
                  name="Grade"
                  rules={[
                    {
                      required: true,
                      message: 'Please select at least one Grade',
                    },
                  ]}
                >
                  
                  <Select
                    className="h-10"
                    placeholder="Please select"
                    options={grade}
                    //disabled={localStorage.getItem('emp_role_id')==2?true:false}
                  />
                </Form.Item>





                <Form.Item
                  label="Clients"
                  name="assign_Clients"
                  rules={[
                    {
                      required: true,
                      message: 'select at least one client name',
                    },
                  ]}
                >
 
                  <Select
                    mode= {projectassign==21?"single":"multiple"} 
                    onChange={handleChange}
                    className="h-10 custum-select"
                    placeholder="Please select"
                    options={clients}
                    disabled={localStorage.getItem('emp_role_id')==2?true:false}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>


          
</div>

  )
}



{
  projectassign!=3 &&(
    
    <Form.Item
    label="Assign Project"
    name="assign_Projects"
    rules={[
      {
        required: true,
        message: 'Please select at least one project',
      },
    ]}
  >
    
    <Select
      mode="multiple"
      onChange={handleChangeproject}
      className="h-10 custum-select"
      placeholder="Please select"
      options={projects}
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
      
    />
  </Form.Item>

  )
}


                <div className="flex justify-end gap-3 mt-8">
                  <Button onClick={toggleModal} className="text-gray-500 hover:text-gray-700">
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" className="bg-blue-500 text-white">
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        )}
      </section>

    </>
  );
};
