import React, { useState, useMemo,useEffect } from "react";
import CustomTable from '../common/CustomTable';
import dayjs from 'dayjs';
import { Timesheetapi } from "../../middleware/interceptor";
import * as moment from 'moment';
import { Switch, Input, Select, Tag, message, Button,Flex, Spin,Modal,Tooltip } from 'antd';

export const SiteDetails = ({ isAuthenticated, employee_id }) => {


  const [sitedata, setSitedata]=useState([]);


  const [Options,setOption]=useState([]);
  const [tempOptions,settempOption]=useState([]);








useEffect(() => {


  getsitedata()
},[]) 


const getsitedata=async()=>{


  await Timesheetapi.get(`api/projects/getsitedetails`).then((Response)=>{



    console.log(Response.data.SiteDetails,"datas");



const filters = Response.data.SiteDetails.map((item) => ({
  ...item, // Spread the existing properties
  Site_Pass: item.Site_Pass === '' ? '-' : item.Site_Pass,
  Site_Url:item.Site_Url===''?'-':item.Site_Url,
  Site_UserName:item.Site_UserName===''?'-':item.Site_UserName,
  Site_Ip:item.Site_Ip===''?'-':item.Site_Ip,
}));


setSitedata(filters)

settempOption(filters)

const uniqueSites = [...new Set(Response.data.SiteDetails.map((item) => item.Site_Name))];

// Generate options for dropdown
const options = [
  { label: "All", value: "All" }, // Default option
  ...uniqueSites.map((site) => ({ label: site, value: site }))
];

setOption(options);

  }).catch(()=>{

 })
}



const columns = useMemo(() => [
    // {
    //   accessorKey: 'site_name', 
    //   header: 'Site Name',
    //   size: 50,
    //   enableColumnActions: false,
    //   muiTableHeadCellProps: {
    //     align: 'center',
    //   },
    //   muiTableBodyCellProps: {
    //     style: {
    //       textAlign: 'center',
    //     },
    //   }
    // },
    {
      accessorKey: 'Site_Ip', 
      header: 'Site IP',
      size: 50,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'center',
        },
      }
    },
    {
        accessorKey: 'Site_Url', 
        header: 'Site Url',
        size: 50,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        Cell: ({ cell }) => (
          <a 
            href={cell.getValue()} 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            {cell.getValue()}
          </a>
        ),
      },
      {
        accessorKey: 'Site_UserName', 
        header: 'Site Username',
        size: 250,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'center',
          },
        }
      },
      {
        accessorKey: 'Site_Pass', 
        header: 'Site Password',
        size: 250,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          style: {
            textAlign: 'center',
          },
        }
      }
],[sitedata])



const handleclientChange=(values)=>{


  let filters;


  if(values==='All'){

    filters= tempOptions;


}
else{
   filters= tempOptions.filter((items)=>items.Site_Name===values);

}



setSitedata(filters)
}


return (
<>

<div className="flex justify-start">
        <Select className="z-[99] left-[10px] top-[54px]"
          style={{ width: 160 }}
          placeholder="Client"
          options={Options}  
          onChange={handleclientChange}
          >

</Select>
</div>
<div className="siteDetails-table">
<CustomTable
  columns={columns}
  data={sitedata}
  />
</div>

</>)
}