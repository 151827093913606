import React, { useState, useMemo,useEffect } from "react";
import CustomTable from '../common/CustomTable';
import { Select,DatePicker, Spin } from 'antd';
import { Timesheetapi } from "../../middleware/interceptor";
import dayjs from 'dayjs';
import * as moment from 'moment';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import CryptoJS from 'crypto-js';





const { Option } = Select;
const { RangePicker } = DatePicker;


export default function ClientAllocation({Secret_key}) 
{
  const [selectedRole, setSelectedRole] = useState('All');
  const [loading,setloading]=useState(false)
  const currentStartDate = dayjs().startOf('month');
  const currentEndDate = dayjs().endOf('month');

  // Initialize state for the selected date range
  const [ selectedRange, setSelectedRange] = useState([currentStartDate, currentEndDate]);






  const handleRoleChange = (value) => {
    setSelectedRole(value);

    console.log(clientlisttemp,'clientlisttemp')
    let tempdata;
    if(value=="All"){
      tempdata= clientlisttemp;

    }
    else{
     tempdata= clientlisttemp.filter((item) => item.client_name === value);

    }
    setClientlist(tempdata) 

};


  const formatTime = (totalMinutes) => {
    if (isNaN(totalMinutes) || totalMinutes == null) return "00:00"; // Handle NaN or null
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const data = [
    {
      project: 'Autobuy',
      emp_name: 'John Doe',
      hours: '3',
      internalRank: 1,
      externalRank: 3,
      total: '45',
    },
    {
      project: 'Fobesoft',
      emp_name: 'Jane Smith',
      hours: '6',
      internalRank: 2,
      externalRank: 4,
      total: '66',
    },
    {
      project: 'Transcredit',
      emp_name: 'Sam Wilson',
      hours: '8',
      internalRank: 3,
      externalRank: 5,
      total: '78',
    }
  ];


  const [clientlist, setClientlist] = useState([]);
  const [clientlisttemp, setClientlisttemp] = useState([]);



  const [clientoption, setClientoption] = useState([]);



  useEffect(() => {

    if(Secret_key!=null && Secret_key!="") {
      getclientname();
    }
  },[Secret_key]);

  const decryptData = (ciphertext, ivBase64) => {
    

    console.log(ciphertext,ivBase64,"callfunc")


    const iv = CryptoJS.enc.Base64.parse(ivBase64);
  
    // Decrypt using AES CBC mode
    const bytes = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(Secret_key), { iv: iv });
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  

    console.log(decryptedData,"joker")

    return decryptedData;
  };


  const getclientname = async () => {

    setloading(true)

    const currentStartDate = moment().startOf('month').format('YYYY-MM-DD');
    const currentEndDate = moment().endOf('month').format('YYYY-MM-DD');

    await Timesheetapi.get(`api/Projects/getclientallocationAsync?Role_id=${localStorage.getItem('emp_role_id')}&empid=${localStorage.getItem('employee_id')}&sdate=${currentStartDate}&edate=${currentEndDate}`).then((response) => {
    
    const projects = response.data.Clients;




    const uniqueProjects = Array.from(
      new Set(projects.map((project) => project.client_id)) // Extract unique project IDs
    ).map((id) => {
      // Return the project corresponding to each unique ID
      return projects.find((project) => project.client_id === id);
      
    });



    setClientoption(uniqueProjects);
    
    let data = projects?.map((item) => {

      let [ciphertext, ivBase64]="";
      let InternalRate = "";
      let ExternalRate = "";
      if(item.InternalRate){

       [ciphertext, ivBase64] = item.InternalRate.split(',');

       InternalRate = decryptData(ciphertext,ivBase64)
      }

      if(item.ExternalRate){

        [ciphertext, ivBase64] = item.ExternalRate.split(',');
 
        ExternalRate = decryptData(ciphertext,ivBase64)
       }


      return {
        ...item,
        Totalhours:formatTime(item.Totalhours),
        // InternalRate:formatTimeinter(item.InternalRate),
        // ExternalRate:formatTimeinter(item.ExternalRate)
        InternalRate:formatTimeinter(InternalRate),
        ExternalRate:formatTimeinter(ExternalRate)
      };
    })



    console.log(data,"jijijij");
      setClientlist(data);
      setClientlisttemp(data);

      setloading(false)
    }).catch((error) => {
      console.log(error);
      setloading(false)

    });

  }


  // Calculate totals
  const totalHours = data.reduce((acc, item) => acc + parseInt(item.hours || 0), 0);
  const totalInternalRank = data.reduce((acc, item) => acc + (item.internalRank || 0), 0);
  const totalExternalRank = data.reduce((acc, item) => acc + (item.externalRank || 0), 0);

  // Create a totals row
  const totalRow = {
    project: 'Total',
    emp_name: '',
    hours: totalHours,
    internalRank: totalInternalRank,
    externalRank: totalExternalRank,
    total: '25',
  };

  const columns = useMemo(() => [
    // {
    //   accessorKey: 'client_name',
    //   header: 'Clients',
    //   muiTableHeadCellProps: {
    //     align: 'center',
    //   },

    //   size: 190,
    //   enableColumnActions:    false,
    // },
    {
      accessorKey: 'Employee_name',
      header: 'Employee Name',
      muiTableHeadCellProps: {
        align: 'center',
      },
      size: 200,
    },
    {
      accessorKey: 'Totalhours',
      header: 'Worked Hours',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },
    },
    {
      accessorKey: 'InternalRate',
      header: 'Internal Rate',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },

      Cell: ({ cell }) => {
        return new Intl.NumberFormat().format(cell.getValue());
      },


    },
    {
      accessorKey: 'ExternalRate',
      header: 'External Rate',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },

      Cell: ({ cell }) => {
        return new Intl.NumberFormat().format(cell.getValue());
      },
    },
    // {
    //   accessorKey: 'total',
    //   header: 'Total',
    //   size: 80,
    //   enableColumnActions: false,
    // }
  ], [clientlist]);

  const formatTimeinter = (totalMinutes) => {
    if (isNaN(totalMinutes) || totalMinutes == null) return 0; // Handle NaN or null by returning 0
  
    // Convert totalMinutes to hours (in decimal format)
    const decimalHours = totalMinutes / 60;
  
    // Return the decimal value rounded to two decimal places (optional, depending on how precise you want it)
    return parseFloat(decimalHours.toFixed(2));  // You can adjust precision as needed (e.g., 2 decimal places)
  };



  const handleExportData = async(rows) => {



    const tableData = rows.map((row) => Object.values(row.original));


    console.log(selectedRange,'date')


    const tableDatas = tableData.map((row) => ({
      Employee_name: row[0],
      client_name: row[1],
      Totalhours: row[7],
      InternalRate: row[8],
      ExternalRate: row[9],

  }));

  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet(`Client_Allocation.xlsx`);

  const startDate = new Date(selectedRange[0]);
      const endDate = new Date(selectedRange[1]);
    
      // Get the month and year for both start and end dates
      const options = { year: 'numeric', month: 'long' };
      const startMonthYear = startDate.toLocaleString('en-US', options); // e.g., "October 2024"
      const endMonthYear = endDate.toLocaleString('en-US', options); 

  const selected = 'DATE: ' + startMonthYear + '  -  ' + endMonthYear;
  worksheet.addRow([selected]);

  worksheet.mergeCells('A1:D1');
  const Cell = worksheet.getCell('A1');
  Cell.font = { bold: true };
   Cell.alignment = { horizontal: 'center' };



worksheet.getCell('A3').value = 'Employe_Name';
worksheet.getCell('B3').value = 'Worked_Hours';
worksheet.getCell('C3').value = 'Internal_Rate';
worksheet.getCell('D3').value = 'External_Rate';


worksheet.getCell('A3').font = { bold: true };
worksheet.getCell('B3').font = { bold: true };
worksheet.getCell('C3').font = { bold: true };
worksheet.getCell('D3').font = { bold: true };

worksheet.getColumn('A').width = 25;
worksheet.getColumn('B').width = 15;
worksheet.getColumn('C').width = 15;
worksheet.getColumn('D').width = 15;


  console.log(tableDatas,tableData,"tableData")


  let rowIndex = 4;


  // Employee_name: row[0],
  //     client_name: row[1],
  //     Totalhours: row[7],
  //     InternalRate: row[8],
  //     ExternalRate: row[9],

  const filteredData = tableDatas.map(item => ({
    Employee_name: item.Employee_name,
    //client_name: item.client_name,
    Totalhours:item.Totalhours,
    Internal_rate: item.InternalRate,
    External_rate: item.ExternalRate
}));

filteredData.forEach(data => {
  worksheet.getCell(`A${rowIndex}`).value = data.Employee_name;
  worksheet.getCell(`B${rowIndex}`).value = data.Totalhours;
  worksheet.getCell(`C${rowIndex}`).value = data.Internal_rate;
  worksheet.getCell(`D${rowIndex}`).value = data.External_rate;
  rowIndex++;
});
const buffer = await workbook.xlsx.writeBuffer();
saveAs(new Blob([buffer]), `Client_Allocation.xlsx`);

    // console.log(columns, "ghghgh");
    // const rowData = data.map((row) => row.original);
    // const exportData = clientlist; 
    // const csv = convertToCSV(rowData);
    // downloadCSV(csv);
  };





  const handleDateChange =async (date, dateString) => {

    setSelectedRange(dateString)

    console.log(dateString,"dkcm")
    if (date && date.length === 2) {

    setloading(true)

    const startMonth = date[0]; 
    const endMonth = date[1]; 
    const startOfMonth = startMonth.startOf('month').format('YYYY-MM-DD');
    const endOfMonth = endMonth.endOf('month').format('YYYY-MM-DD'); 

    await Timesheetapi.get(`api/Projects/getclientallocationAsync?Role_id=${localStorage.getItem('emp_role_id')}&empid=${localStorage.getItem('employee_id')}&sdate=${startOfMonth}&edate=${endOfMonth}`).then((response) => {
    
      const projects = response.data.Clients;



      let data;


    let   temp=  projects?.map((item) => {


        let [ciphertext, ivBase64]="";
        let InternalRate = "";
        let ExternalRate = "";
        if(item.InternalRate){
         [ciphertext, ivBase64] = item.InternalRate.split(',');
         InternalRate = decryptData(ciphertext,ivBase64)
        }
  
        if(item.ExternalRate){
          [ciphertext, ivBase64] = item.ExternalRate.split(',');
          ExternalRate = decryptData(ciphertext,ivBase64)
         }
  
  
  
          return {
            ...item,
            Totalhours:formatTime(item.Totalhours),
            InternalRate:formatTimeinter(InternalRate),
            ExternalRate:formatTimeinter(ExternalRate)
          };
        })
      if(selectedRole=='All'){
        data = projects?.map((item) => {


          let [ciphertext, ivBase64]="";
          let InternalRate = "";
          let ExternalRate = "";
          if(item.InternalRate){
           [ciphertext, ivBase64] = item.InternalRate.split(',');
           InternalRate = decryptData(ciphertext,ivBase64)
          }
    
          if(item.ExternalRate){
            [ciphertext, ivBase64] = item.ExternalRate.split(',');
            ExternalRate = decryptData(ciphertext,ivBase64)
           }
    
    
    
            return {
              ...item,
              Totalhours:formatTime(item.Totalhours),
              InternalRate:formatTimeinter(InternalRate),
              ExternalRate:formatTimeinter(ExternalRate)
            };
          })


   
      }
      else{

        data = projects?.map((item) => {


          let [ciphertext, ivBase64]="";
          let InternalRate = "";
          let ExternalRate = "";
          if(item.InternalRate){
           [ciphertext, ivBase64] = item.InternalRate.split(',');
           InternalRate = decryptData(ciphertext,ivBase64)
          }
    
          if(item.ExternalRate){
            [ciphertext, ivBase64] = item.ExternalRate.split(',');
            ExternalRate = decryptData(ciphertext,ivBase64)
           }
    
            return {
              ...item,
              Totalhours:formatTime(item.Totalhours),
              InternalRate:formatTimeinter(InternalRate),
              ExternalRate:formatTimeinter(ExternalRate)
            };
          }).filter((item)=>item.client_name === selectedRole)
      }

console.log(temp,"temp")
        setClientlist(data);
        setClientlisttemp(temp)

        setloading(false)
      }).catch((error) => {
        console.log(error);
      });


     }
    
    }

    const options = [
      { label: 'All', value: 'All' },  // Add "All" option
      ...clientoption.map(client => ({
        label: client.client_name,
        value: client.client_name
      }))
    ];


  return (
    <section className='reports-section'>
      <div>
        <h2 className="text-[20px] font-[500]">Client Allocation</h2>
      </div>
      <div className="flex justify-start">
        <Select className="z-[99] left-[10px] top-[31px]"
          style={{ width: 130 }}
          placeholder="Client"
          value={selectedRole}
          options={options}
          onChange={handleRoleChange}
        />
                
        <div style={{left:"100px"}}>
        <RangePicker
        className="z-[99] left-[10px] top-[31px]"
        style={{ left: "20px" }}
        onChange={handleDateChange}
        defaultValue={selectedRange}
        picker="month"
        placeholder={['Start Month', 'End Month']}
         format="MMMM YYYY"
      />       </div>
      </div>
      <div className="mt-[-20px]  custom-table client-table">



      {
  loading?(<>
  <Spin tip="Loading..." className="justify-center items-center flex" style={{height:'100vh'}}>
  <CustomTable
          columns={columns}
          data={[...clientlist]} // totalRow
        />

  </Spin>
  </>
      
      ):(
        <>
         <CustomTable
          columns={columns}
          data={[...clientlist]} // totalRow
          component={"Allocation"}
          handleExportData={handleExportData}

        />
        </>
      )}
       
      </div>
    </section>
  );
}
