// src/components/modules/Settings.js

import React, { useState, useMemo, useEffect } from "react";
import { Modal } from "../common/Modal";
import CustomTable from "../common/CustomTable";
import { Switch, Input, Select, Space, Form, Button, DatePicker, Table, Spin, Modal as AntdModal } from 'antd';
import { FaRegEdit, FaSave } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import axios from 'axios';
import { data } from "autoprefixer";
import * as moment from 'moment';
import { Create } from "@mui/icons-material";

import { Timesheetapi } from "../../middleware/interceptor";
import { useMsal } from "@azure/msal-react";
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ExclamationCircleFilled } from '@ant-design/icons';



export const Project = ({ isAuthenticated }) => {



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenclient, setIsModalOpenclient] = useState(false);
  const [clientfilteroptions, setclientfilteroptions] = useState([]);
  const [clientfiltervalue, setclientfiltervalue] = useState(["All"]);
  const [data, setData] = useState([]);
  const [tempdata, settempData] = useState([]);
  const [errors, setErrors] = useState({});
  const [project, setProject] = useState([]);
  const [form] = Form.useForm();
  const [Clients] = Form.useForm();
  const [projectload, setprojectload] = useState(false);
  const [projectdeletemodal, setprojectdeletemodal] = useState(false);
  const [deletedata, setdeletedata] = useState();
  const [edit, setedit] = useState(false);
  const [user, setUser] = useState(null);
  const [projid, setprojid] = useState();
  const { instance } = useMsal();
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');



  const [newProject, setNewProject] = useState({
    projectName: '',
    client: '',
    status: 'Active',
    clientid: '',
    assignedTo: '',
  });

  useEffect(() => {
    if (isAuthenticated) {

      const { name, username } = instance?.getActiveAccount();
      console.log("username", name, username);
      setUser(username);
      fetchClientNames(username);
    }
  }, [isAuthenticated]);


  const toggleModalclick = () => {
    setedit(false);

    form.resetFields();

    toggleModal();
  };


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const toggleModalclient = () => {
    setIsModalOpenclient(!isModalOpenclient);
  }

  const toggledelete = async (row) => {

    setprojectdeletemodal(true);
    setdeletedata(row.original.Project_id)
    let del = row.original.Project_id;

  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
   
    const day = String(date.getDate()).padStart(2, '0'); 
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear(); 
    
    return `${day}-${month}-${year}`;
  };




  const toggleModals = (row) => {

    let startdate = formatDate(row.original.start_date)

    let enddate = formatDate(row.original.end_date)

    console.log('row', row.original.start_date,row.original.end_date);

   

    setedit(true);
    setprojid(row.original.Project_id);
    form.setFieldsValue({
      Client: row.original.client_name,
      client_id: row.original.client_id,
      Charge: row.original.chargeability,
      Project: row.original.projectname,
      start_date: row.original.start_date == null ? "" : dayjs(startdate, 'DD-MM-YYYY'), // Correct format
      end_date: row.original.end_date == null ? "" : dayjs(enddate, 'DD-MM-YYYY'),
      status: row.original.status,
      // Format the date
    });


    // setNewProject({ projectName: row.original.projectname, status: row.original.status, client: row.original.client_name, clientid: row.original.client_id, assignedTo: '' });
    // console.log('newProject', { id: row.original.client_id, name: row.original.client_name });
    // setSelectedClient({ id: row.original.client_id, name: row.original.client_name });
    setIsModalOpen(!isModalOpen);
  };

  //   const newErrors = {};

  //   if (!newProject.projectName) {
  //     newErrors.projectName = 'Project name is required';
  //   }

  //   if (!selectedClient) {
  //     newErrors.client = 'Please select a client';
  //   }

  //   if (!newProject.status) {
  //     newErrors.status = 'Please select a status';
  //   }

  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };




  const handleSubmit = async (values) => {

    

    const formattedStartDate = dayjs(values.start_date).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(values.end_date).format('YYYY-MM-DD');

    console.log(formattedStartDate,formattedEndDate,"ghgghg")

    console.log('formattedStartDate', values);
    if (!edit) {
      let payload = {

        "ClientName": values.Client,
        "projectName": values.Project,
        "Chargeability": values.Charge,
        "start_date": formattedStartDate,
        "status": 1,
        "end_date": formattedEndDate,
        "assignedTo": user
        //"rate_type" : values.Rate
      }

      console.log('payload', payload);

      try {

        await Timesheetapi.post('api/Projects/createnewproject', payload).then((response) => {
          console.log('response', response.data);
          if (response.data.Msg == "Project created successfully") {
            getprojectdetails();
            toast.success(response.data.Msg)

          }
        }
        );


      } catch (error) {
        console.error('Error fetching client names:', error);
      }
    }
    else {

      let payload = {
        "client_name": values.Client,
        "Chargeability": values.Charge,
        client_id: form.getFieldValue('client_id'),
        "projectName": values.Project,
        "start_date": formattedStartDate,
        status: form.getFieldValue('status'),
        Project_id: projid,
        "end_date": formattedEndDate,
        "assignedTo": user
        //"rate_type" : values.Rate

      }

      console.log('payload', payload);

      try {
        const response = await Timesheetapi.post('api/Projects/updateprojectdata', payload).then((response) => {
          console.log('response', response.data);
          if (response.data.Msg == "Project updated successfully") {
            getprojectdetails();
            toast.success(response.data.Msg)
          }
        }
        );
      } catch (error) {
        console.error('Error fetching client names:', error);
      }
    }
    // setNewProject({ projectName: '', client: '', status: 'Active', assignedTo: '' });
    toggleModal();


  };

  // To store the selected client



  useEffect(() => {
    //   try {
    //     const respons = await axios.get('https://localhost:44385/api/Projects/getprojectdetails');
    //     const filterdata = respons?.data?.projects?.filter((item) => item.Del_stattus !== '1');
    //     setData(filterdata);
    //     setProject(filterdata);
    //     console.log('Filtered data length:', filterdata.length); // Log the filtered data's length here
    //   } catch (error) {
    //     console.error('Error fetching project details:', error);
    //   }
    // };

    // fetchData();
    getprojectdetails();
  }, []);


  const [client, setClient] = useState([]);

  const getprojectdetails = async () => {
    try {

      setprojectload(true);



      const respons = await Timesheetapi.get(`api/Projects/getprojectdetails?Role_id=${localStorage.getItem('emp_role_id')}&empid=${localStorage.getItem('employee_id')}`);

      const response = await Timesheetapi.get(`api/Projects/getclientname`)


      const clientOptions = response.data.Client.map(Projects => ({
        value: Projects.Client_name,
        label: Projects.Client_name,
      })).filter((item, index, self) =>
        index === self.findIndex(t => t.value === item.value)
      );


      setClient(clientOptions);

      const clientfilterOptions = respons.data.projects.map(Projects => ({
        value: Projects.client_name,
        label: Projects.client_name,
      })).filter((item, index, self) =>
        index === self.findIndex(t => t.value === item.value)
      );


      const allOption = { value: "All", label: "All" };
      clientfilterOptions.unshift(allOption);



      setclientfilteroptions(clientfilterOptions);


      let data = respons?.data?.projects?.map((item) => {
        return {
          ...item,
          create_date: item.create_date ? moment(item.create_date).format('YYYY-MM-DD') : null,
          update_date: item.update_date ? moment(item.update_date).format('YYYY-MM-DD') : null,
        };
      });


      console.log('tytyty', data);

      const filterdata = data?.filter((item) => item.status != 2);
      const filteredstate = filterdata.filter(item => item.client_name == clientfiltervalue);

       
      if(clientfiltervalue  !="All")
      {
        setData(filteredstate)

      }
      else{
        setData(filterdata);

      }


       

      settempData(filterdata);

      setprojectload(false);

    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  };





  //  useEffect(() => {
  const fetchClientNames = async (username) => {
    // try {
    //   const response = await Timesheetapi.post(`api/Projects/getprojectname?email=${username}`);

    //   const clientOptions = response.data.Client.map(client => ({
    //     value: client.Client_name + ',' + client.Client_id,
    //     label: client.Client_name,
    //   }));
    //   setClients(clientOptions);
    // } catch (error) {
    //   console.error('Error fetching client names:', error);
    // }
  };

  //fetchClientNames();
  //}, []);

  const handleClientChange = (e) => {
    const { id, name } = JSON.parse(e.target.value);
    setSelectedClient({ id, name });


    console.log('id', e.target.value);

    setNewProject({ ...newProject, client: name, clientid: id });
  }



  const onChangeswitch = async (e, checked) => {

    console.log(e, checked);

    await Timesheetapi.post(`api/Projects/updateprojectstatus?id=${checked.Project_id}&status=${e ? 1 : 0}`)
      .then((response) => {
        const updatedData = data.map((item) => {
          if (item.Project_id == checked.Project_id) {
            item.status = e ? 1 : 0;
          }
          return item;
        });

        setData(updatedData)
      }).catch((error) => {
        console.error('Error updating project status:', error);
      });



    console.log('data2024', data);


  };
  const [editingKey, setEditingKey] = useState(null); // Keeps track of which row is being edited
  const [editedName, setEditedName] = useState('');


  const toggleModalsclient = (row) => {


    setEditingKey(row.key); // Set the row to be edited
    setEditedName(row.name); // Set the current name for the text field
  };

  const saveEdit = (rowKey) => {
    const newData = dataclient.map((row) => {
      if (row.key === rowKey) {
        return { ...row, name: editedName }; // Update the name with the new value
      }
      return row;
    });
    //setDataclient(newData);
    setEditingKey(null); // Stop editing mode
  };


  const columnsclient = [
    {
      title: 'Client Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, row) => {
        // If this row is being edited, render a text input field, otherwise render the name as text
        return editingKey === row.key ? (
          <Input
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
        ) : (
          text
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, row) => {
        // If the row is in edit mode, show the save button, otherwise show the edit and delete icons
        return editingKey === row.key ? (
          <FaSave
            onClick={() => saveEdit(row.key)}
            className="text-[19.5px] text-[#3b82f6] cursor-pointer"
          />
        ) : (
          <>
            <FaRegEdit

              onClick={() => toggleModalsclient(row)}
              className="text-[19.5px] text-[#3b82f6] cursor-pointer"
            />
            <FaTrashAlt
              onClick={() => toggledelete(row)}
              className="text-[16px] text-[#ff4d4d] cursor-pointer ml-2"
            />
          </>
        );
      },
    },
  ];





  const dataclient = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sydney No. 1 Lake Park',
    },
    {
      key: '4',
      name: 'Jim Red',
      age: 32,
      address: 'London No. 2 Lake Park',
    },
  ];





  const columns = useMemo(() => [

    {
      accessorKey: 'client_name',
      header: 'Clients',
      size: 200,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
    },

    {
      accessorKey: 'projectname',
      header: 'Project Name',
      size: 200,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },

      Cell: ({ row }) => {

        const value = row.original.projectname;

        return value == null || value === '' ? '-' : value;
      },

    },
    {
      accessorKey: 'start_date',
      header: 'Start Date',
      size: 150,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },
      Cell: ({ row }) => {


        const value = row.original.start_date;

        return value == null || value === '' ? '-' : value;
      },
    },
    {
      accessorKey: 'end_date',
      header: 'End Date',
      size: 100,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },
      Cell: ({ row }) => {

        const value = row.original.end_date;

        return value == null || value === '' ? '-' : value;
      },
    },
    ...(localStorage.getItem('emp_role_id') === '3' ? [
      {
        accessorKey: 'id',
        header: 'Action',
        size: 80,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          <div className="flex justify-center gap-3">
            <FaRegEdit
              onClick={() => row.original.status !== 0 && toggleModals(row)}
              className={`text-[19.5px] ${row.original.status === 0 ? 'text-gray-400 cursor-not-allowed' : 'text-[#3b82f6] cursor-pointer'}`}
            />
            <FaTrashAlt
              onClick={() => toggledelete(row)}
              className="text-[16px] text-[#ff4d4d] cursor-pointer"
            />
          </div>
        ),
      },
    ] : []),
  ], [data]);



  const handleChange = (selected) => {

    console.log(clientfilteroptions, "fgfgf")

    console.log(selected, "rwrwr")

    setclientfiltervalue(selected)
    if (selected == "All") {

      setData(tempdata);


    }
    else {

      const filtered = tempdata.filter(item => item.client_name === selected);

      setData(filtered);


    }




  }




  const cancelDelete = () => {
    setprojectdeletemodal(false);
  };


  const confirmDelete = async () => {


    try {
      const response = await Timesheetapi.post(`api/Projects/deleteproject?id=${deletedata}`);

      console.log('response', response.data);


      if (response.data == "deleted sucessfully") {


        getprojectdetails();

        toast.success(response.data)

        setData((prevData) => prevData.filter((item) => item.id !== deletedata));

      }


    } catch (error) {
      console.error('Error fetching client names:', error);
    }

    setprojectdeletemodal(false);
  }

  return (
    <div>
      <ToastContainer />
      <div className="container mx-auto px-0">
        <div>
          <h2 className="text-[20px] font-[500]">Projects</h2>
        </div>
        {/* Create new project button */}
        <div className="flex justify-start items-center gap-[25px]">
          <button
            onClick={toggleModalclick}
            disabled={localStorage.getItem('emp_role_id') === '3' ? false : true}
            style={{ opacity: localStorage.getItem('emp_role_id') === '3' ? 1 : 0.5, cursor: localStorage.getItem('emp_role_id') === '3' ? 'pointer' : 'not-allowed' }}
            className="bg-blue-500 text-white py-[5px] px-3 rounded flex items-center relative top-[20px] z-[9] left-[1rem]"
          >
            <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Create a Project</span>
          </button>
          <Select className="w-[150px] relative z-[9] top-[20px]"
            value={clientfiltervalue}
            onChange={handleChange}
            options={clientfilteroptions}
            placeholder="Select a Client">

          </Select>
        </div>
        {isModalOpen && (
          <Modal className="custom_modal" toggleModal={toggleModal}>
            <div className="bg-white rounded-lg">
              <h3 className="text-[20px] font-[500] mb-2">{edit ? "Update a Project" : "Create a Project"}</h3>
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <div className="grid grid-cols-2 gap-x-4">
                  <div>
                    <Form.Item
                      label="Client"
                      name="Client"
                      placeholder="Please select"
                      rules={[{ required: true, message: 'Please select a client' }]}
                    >
                      <Select
                        options={client}
                        className="h-10"
                      >
                      </Select>

                    </Form.Item>

                  </div>

                  <div>
                    <Form.Item
                      label="Project"

                      name="Project"
                      placeholder="Please select"
                      rules={[{ required: true, message: 'Please select a client' }]}
                    >
                      <Input className="h-10"></Input>


                    </Form.Item>

                  </div>

                  <div>
                    <Form.Item
                      name="start_date"
                      label="Start date"
                      rules={[{ required: true, message: 'Please select a start date!' }]}
                    >
                      <DatePicker className="w-[100%] h-10 " format="DD-MM-YYYY" />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      name="end_date"
                      label="End date"
                      rules={[{ required: true, message: 'Please select an end date!' }]}
                    >
                      <DatePicker className="w-[100%] h-10" format="DD-MM-YYYY" />
                    </Form.Item>

                  </div>

                  <div>
                    <Form.Item
                      label="Chargability"
                      name="Charge"
                      placeholder="Please select"
                      rules={[{ required: true, message: 'Please select a client' }]}
                    >
                      <Select className="h-10" >
                        <option value="1">
                          Billable
                        </option>
                        <option value="2" >
                          Non-Billable
                        </option >
                      </Select>

                    </Form.Item>
                  </div>
                </div>

                <Form.Item className="text-right footer-form-item">
                  <Button type="default" onClick={toggleModal} style={{ marginRight: '8px' }}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {edit ? "Update" : "Create"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Modal>
        )}

        {isModalOpenclient && (
          <Modal toggleModalclient={toggleModalclient}  >
            <div className="bg-white rounded-lg">
              <h3 className="text-[20px] font-[500] mb-2">{"Add a Client"}</h3>
              <Table columns={columnsclient} dataSource={dataclient} />
            </div>
          </Modal>
        )}


        <div>
          <AntdModal
            title={<><ExclamationCircleFilled style={{ color: 'orange', marginRight: '8px' }} /> Confirm</>}
            visible={projectdeletemodal}
            onOk={confirmDelete}
            onCancel={cancelDelete}
            footer={[
              <Button key="cancel" onClick={cancelDelete}>
                Cancel
              </Button>,
              <Button key="confirm" type="primary" onClick={confirmDelete}>
                Delete
              </Button>
            ]}
          >
            <p>Are you sure you want to delete this project? Deleting the project will also remove the assigned tasks for employees</p>
          </AntdModal>

        </div>








        {/* Table component */}
        {projectload ? (


          <Spin tip="Loading..." className="justify-center items-center flex" style={{ height: '100vh' }}>
            <div className="mt-[-25px] overflow-auto custom-table">
              <CustomTable
                columns={columns}
                key={data.length}
                data={data}
                pinColumn={['action']}
              />

            </div>
          </Spin>

        ) : (
          <div className="mt-[-25px] overflow-auto custom-table">








            <CustomTable
              columns={columns}
              key={data.length}
              data={data}
              pinColumn={['action']}
            />

          </div>
        )}


      </div>
    </div>
  );
};
