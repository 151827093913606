import React, { useEffect, useRef } from 'react';
import { BiLogOutCircle } from 'react-icons/bi';
 
export const Header = ({ user_name, initials, toggleSignOut, isSignOutVisible, handleSignOut }) => {
  const headerRef = useRef(null);
  const toggleBtnRef = useRef(null); // Reference to the toggle button
 
  useEffect(() => {
    // Function to hide the sign-out button when clicking outside the header
    const handleClickOutside = (event) => {
      // If click is outside the headerRef, hide the sign-out button
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        if (isSignOutVisible) {
          toggleSignOut(false); // Hide the sign-out button
        }
      }
    };
 
    // Add event listener to detect clicks outside
    document.addEventListener('mousedown', handleClickOutside);
 
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSignOutVisible, toggleSignOut]);
 
  return (
    <div
    ref={headerRef}
    className="absolute top-5 right-[15px] flex items-center gap-3 bg-white shadow-lg rounded-full border border-gray-200"
    style={{ paddingInline: '12px 7px', paddingBlock: '7px' }}
  >
    <div className="text-gray-700 text-[15px] font-[500]">
      Hi, <span className="font-[500] text-blue-600">{user_name}</span>
    </div>
    <div
      className="toggle-btn bg-blue-500 text-white rounded-full w-9 h-9 flex items-center justify-center font-[500] text-[14px] cursor-pointer relative hover:bg-blue-600 transition-all"
      onClick={() => toggleSignOut(!isSignOutVisible)} // Toggle the visibility of the sign-out button
      title="Click to sign out"
    >
      {initials}
    </div>
    {isSignOutVisible && (
      <div className="absolute top-full mt-2 right-0 bg-white border border-gray-300 rounded shadow-lg w-28 text-center z-[99]">
        <button
          className="px-3 py-2 text-red-500 hover:bg-gray-100 w-full flex items-center gap-[5px] whitespace-nowrap"
          onClick={handleSignOut}
        >
          <BiLogOutCircle className="text-[20px]" /> <span className="text-[15px]">Sign Out</span>
        </button>
      </div>
    )}
 
    </div>
  );
};