
import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, MenuItem, TextField } from '@mui/material';
import { Switch, Input, Select, Tag, message, Button,Flex, Spin,Modal,Tooltip } from 'antd';
import { useMsal } from "@azure/msal-react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";


import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Timesheetapi } from "../../middleware/interceptor";

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from 'antd/es/dropdown/dropdown';
//const { RangePicker } = DatePicker;
import {ExclamationCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import CheckIcon from '@mui/icons-material/Check'; // Check mark icon
import ClearIcon from '@mui/icons-material/Clear'; // Clear (X) icon
import { Label } from '@mui/icons-material';

import * as moment from 'moment';

const contentStyle = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};

export const Teamtimesheet = ({ isAuthenticated, employee_id }) => {

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const { instance } = useMsal();

  const [tableData, setTableData] = useState([]);

  const [temptableData, settempTableData] = useState([]);

  const [teammember, setteammember] = useState("");
 
  const [teammembertemp, setteammembertemp] = useState([]);

  const [defaultvalue, setdefaultvalue] = useState('ME');

  const [ClientHours, setClientHours] = useState({});

  const [weekapproved, setweekapproved] = useState("1");



  const[dayapprovedhours, setdayapprovedhours] = useState("");

  const[dayapprhrs, setdayapprhrs] = useState("0");


   
  const [teammemberOptions, setTeammemberOptions] = useState([]);

  const [teamclients, setteamclients] = useState([]);

  const [selectteamclients, setselectteamclients] = useState(["0"]);

  const [showDropdownmember, setShowDropdownmember] = useState(false);
 
  const [open, setOpen] = useState(false);


  const [project, setProject] = useState();

  const [messageApi, contextHolder] = message.useMessage();


  const [TeamData, setTeamData] = useState([]);
  const [TeamDataClient, setTeamDataClient] = useState([]);

  const [TeamIds, setTeamIds] = useState([]);

  const [hideapprove, sethideapprove] = useState(false);

  const success = (msg) => {
    messageApi.open({
      type: 'success',
      title: 'Success',
      content: msg,
      duration: 2
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      duration:1.5,
      content: 'This is an error message',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      title: 'Warning',
      duration:1.5,
      content: 'No approvals  available',
    });
  };

  const formatTimes = (totalMinutes) => {
    if (isNaN(totalMinutes) || totalMinutes == null) return "00:00"; // Handle NaN or null
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };
   

  useEffect(() => {
    if (isAuthenticated) {
      const { name, username } = instance?.getActiveAccount();
      console.log("username", name, username);
      //getprojectname(username)


      console.log("gggg", selectedDates.length, employee_id);

      if (localStorage.getItem('employee_id')!=null && localStorage.getItem('employee_id') != undefined) {

        getprojectname (localStorage.getItem('employee_id'))

      }

    }
  }, [isAuthenticated,localStorage.getItem('employee_id')]);

  const getemployee = async (username) => {
    const response = await Timesheetapi.get(`api/Projects/getemployeebyid/${username}/`);
    console.log("response", response.data.Employee[0].employee_id);

    console.log(response.data.Employee, "employee_id");

  }

  const[Loading,setLoading]=useState(false);


  useEffect(() => {


    console.log(employee_id, "employee_idsssss");
  }, [employee_id]);




  const [selectedvalue, setSelectedvalue] = useState();



  const [timesheet_id, setTimesheet_id] = useState();


  const [dateList, setDateList] = useState([]);



  const getprojectbyclients = async (username) => {



    const respons = await Timesheetapi.get(`api/Projects/timesheetprojects?empid=${username}`);

    const clientOptions = respons.data.Projects.map(Projects => ({
      value: Projects.Client_name + '/' + Projects.Proj_name + ',' + Projects.Client_id+','+ Projects.Proj_id,
      label: Projects.Client_name + '/' + Projects.Proj_name, 
    }));



    setProject(clientOptions);




  }








  const getprojectname = async (username) => {



    // const respons = await Timesheetapi.get(`api/Projects/timesheetprojects?empid=${username}`);

    // const clientOptions = respons.data.Projects.map(Projects => ({
    //   value: Projects.Client_name + '/' + Projects.Proj_name + ',' + Projects.Client_id+','+ Projects.Proj_id,
    //   label: Projects.Client_name + '/' + Projects.Proj_name, 
    // }));



    // const teamclients = respons.data.Projects.map(Projects => ({
    //   value: Projects.Client_id,
    //   label: Projects.Client_name
    // }));


    // const meOption = {
    //   value: '0', 
    //   label: 'ALL',
    // };
  
    // // Optionally prepend or append the "ME" option
    // const updatedOptions = [meOption, ...teamclients];


    // console.log(updatedOptions.length,"gathere")

    // setteamclients(updatedOptions)

    // setProject(clientOptions);




  }

  const handleAddRow = () => {


    const startDate = formatDateToDDMMYYYY(new Date(selectedDates[0]));
    const endDate = formatDateToDDMMYYYY(new Date(selectedDates[1]));
    console.log(formatDateToDDMMYYYY(new Date(selectedDates[0])),"dayswwwww")
    const weekDates = [];
    let currentDate = new Date(startDate);
    const finalDate = new Date(endDate);
    
    while (currentDate <= finalDate) {
        weekDates.push(formatDateToDDMMYYYY(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    const daysArray = weekDates.map((hours, index) => ({
      'task-hours': 0, 
      date: weekDates[index] 
  }));




 // let projects = tableData.filter((item) => item.Defaults ==='0');

  let prodcm=[];



  if(prodcm.length <=project.length){



    prodcm=[{ project: '', task: '', Defaults:'0',days:daysArray }, ...tableData];


    let projects = prodcm.filter((item) => item?.Defaults ==='0');


    if(projects.length <=project.length ){

      setTableData([{ project: '', task: '', Defaults:'0',days:daysArray }, ...tableData]);

    }
    else{
        warning();
        }


  }
  else{
toast.warning('No project available')
  }

  };
  

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);



  const handleDeleteRow = (rowIndex) => {
    setRowToDelete(rowIndex);  // Set the row to delete
    setIsModalOpen(true);  
  
    //setTableData(tableData.filter((_, index) => index !== rowIndex));
  };

  const confirmDelete = () => {
    // Filter out the row to delete
    setTableData((prevData) => prevData.filter((_, index) => index !== rowToDelete));
    setIsModalOpen(false);  // Close the modal
    setRowToDelete(null);   // Reset the row to delete
  };


  const closeapprove=()=>{

    setOpen(false); 
  }

  const cancelDelete = () => {
    setIsModalOpen(false);  // Close the modal without deleting
    setRowToDelete(null);
  };


  const [totalRowCount, setTotalRowCount] = useState(new Array(7).fill(0)); // Initialize an array for 7 columns




  // const handleInputChange = (rowIndex, dayIndex, value) => {
  //   // Update the specific day's hours for the given row
  //   const updatedData = [...tableData];
  //   updatedData[rowIndex].days[dayIndex]["task-hours"] = value;
  
  //   // Recalculate totals dynamically
  //   const newTotals = new Array(7).fill(0); // Assuming 7 columns
  //   updatedData.forEach((row) => {
  //     row.days.forEach((day, index) => {
  //       newTotals[index] += Number(day["task-hours"] || 0); // Add values or 0 if empty
  //     });
  //   });
  
  //   // Update the state with updated data and totals
  //   setTableData(updatedData);
  //   setTotalRowCount(newTotals.map((total) => total.toString()));
  
  //   console.log(newTotals, "newTotals");
  // };


  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    // Constructing the Date object
    return new Date(year, month - 1, day); // month is 0-indexed
};




  const handleSelectChange = (rowIndex, field, value) => {


    let filter = value.split(',');
   let clientname = filter[0].split('/')[0];

    console.log(filter, "filter");
    const updatedData = [...tableData];

    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      Project_name: filter[0],
      Project_id: filter[2],
      Employee_id: employee_id,
      Client_id: filter[1],
      Client_name: filter[0].split('/')[0],
      //days: daysArray,
    };

    console.log(updatedData, "updatedData");
    //setSelectedvalue(value);
    setTableData(updatedData);
  };


  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentWeekDay = currentDate.getDay();


  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDay - (currentWeekDay === 0 ? 6 : currentWeekDay - 1));



  const handleKeyUp = (e) => {
    const value = e.target.value;

    const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]?$/;
    if (!timeRegex.test(value)) {
      e.target.value = value.slice(0, -1);
    }
  };










  const formatDateToDDMMYYYY = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Format as yyyy-mm-dd
};


  const handlesubmit = async () => {


    const startDate = formatDateToDDMMYYYY(new Date(selectedDates[0]));
    const endDate = formatDateToDDMMYYYY(new Date(selectedDates[1]));



    setLoading(true)
    console.log(tableData, "tableData");
    const weekDates = [];



    let currentDate = new Date(startDate);
    const finalDate = new Date(endDate);
    
    while (currentDate <= finalDate) {
        weekDates.push(formatDateToDDMMYYYY(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    // Transform the tableData


    const transformedTableData = tableData.map(client => {
      const daysArray = client.days.map((hours, index) => {


        console.log(hours,"HUYIYNU")
        return {
          'task-hours': parseInt(hours['task-hours'], 10)?parseInt(hours['task-hours'], 10):0, // Convert string to number
          date: weekDates[index],
          dayapprove: hours['dayapprove'] !="" ? hours['dayapprove'] : "0"

          //dayapprove: "0"   // Match the date from weekDates array
          
        };
      });
    
      return {
        Client_id: client.Client_id,
        Client_name: client.Client_name,
        Employee_id: client.Employee_id,
        Project_id: client.Project_id,
        Project_name: client.Project_name,
        Timesheet_id: client.Timesheet_id,
        Defaults: client.Defaults,
        days: daysArray // Replace the days array with the new format
      };
    });
    
    // Final payload
    const payload = {
      Timesheetid:timesheet_id,
      Weekstart_date:startDate,
      Weekend_date:endDate,
      employee_id: employee_id,
      tableData: transformedTableData,
      Approved: "0"
    };
    
    console.log(payload,tableData,"Transformed Payload");
    
    await Timesheetapi.post('api/Projects/AddEmployeeWorkingHours', payload).then((response) => {
      success("Successfully submitted for approval");
      console.log('yess')
      gettimesheetdata();
      setLoading(false)

     }).catch((error) => {
      console.log(error, "error");
    });
    console.log(payload, "payload");
  }


  const handleAproved = async (data, index, timesheetid, empid , empname) => {



    const startDate = formatDateToDDMMYYYY(new Date(selectedDates[0]));
    const endDate = formatDateToDDMMYYYY(new Date(selectedDates[1]));
    setLoading(true)
    console.log(temptableData, "tableData");
    const weekDates = [];
    let currentDate = new Date(startDate);
    const finalDate = new Date(endDate);
    
    while (currentDate <= finalDate) {
        weekDates.push(formatDateToDDMMYYYY(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    // Transform the tableData
    const storedClientIds = JSON.parse(localStorage.getItem('Emp_Assign_client'));


    const transformedTableData = TeamDataClient[index].WeekData.map(client => {
      // Check if the Client_id is in the approvedClientIds list
      const isApproved = client.Client_id==(parseInt(teammember));

      return {
        ...client,
        // Client_id: client.Client_id,
        // Client_name: client.Client_name,
        // Employee_id: client.Employee_id,
        // Project_id: client.Project_id,
        // Project_name: client.Project_name,
        // Timesheet_id: client.Timesheet_id,
        // Defaults: client.Defaults,
        Approved_status: isApproved ?"1":client.Approved_status 
      }; 
    });



    console.log(transformedTableData,temptableData,'Formsss');
    
    // Final payload
    const payload = {
      Timesheetid:timesheet_id,
      Weekstart_date:startDate,
      Weekend_date:endDate,
      employee_id: empid,
      tableData: transformedTableData,
      Approved: "1"
    };
    
    console.log(payload,tableData,"Transformed Payload") 
    
    await Timesheetapi.post('api/Projects/AddEmployeeWorkingHours', payload).then((response) => {
      success(`${empname} worked  hours has  been approved the this week.`);
      gettimesheetdata();
      setLoading(false)

     }).catch((error) => {
      console.log(error, "error");
    });
    console.log(payload, "payload");
  }
  

  const handledenied = async (data, index, timesheetid, empid ,empname) => {



    const startDate = formatDateToDDMMYYYY(new Date(selectedDates[0]));
    const endDate = formatDateToDDMMYYYY(new Date(selectedDates[1]));
    setLoading(true)
    console.log(tableData, "tableData");
    const weekDates = [];
    let currentDate = new Date(startDate);
    const finalDate = new Date(endDate);
    
    while (currentDate <= finalDate) {
        weekDates.push(formatDateToDDMMYYYY(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    // Transform the tableData


    const storedClientIds = JSON.parse(localStorage.getItem('Emp_Assign_client'));


    const transformedTableData = TeamDataClient[index].WeekData.map(client => {
      const isApproved = client.Client_id==(parseInt(teammember));

      return {
        ...client,
        // Client_id: client.Client_id,
        // Client_name: client.Client_name,
        // Employee_id: client.Employee_id,
        // Project_id: client.Project_id,
        // Project_name: client.Project_name,
        // Timesheet_id: client.Timesheet_id,
        // Defaults: client.Defaults,
        Approved_status: isApproved ?"2":client.Approved_status,
      };
    });
    
    // Final payload
    const payload = {
      Timesheetid:timesheet_id,
      Weekstart_date:startDate,
      Weekend_date:endDate,
      employee_id: empid,
      tableData: transformedTableData,
      Approved: "2"
    };
    
    console.log(payload, transformedTableData, "Transformed Payload");
    
    await Timesheetapi.post('api/Projects/AddEmployeeWorkingHours', payload).then((response) => {
      success(`${empname} worked hours has been denied the this week.`);
      console.log('yess')
      gettimesheetdata();
      setLoading(false)

     }).catch((error) => {
      console.log(error, "error");
    });
    console.log(payload, "payload");
  }









  // const columns = useMemo(() => [
  //   {
  //     accessorKey: 'client_name',
  //     header: 'Project',
  //     Cell: ({ row }) => {


  //       console.log(row.original, "row.original");
  //       const isTotalRow = row.original.Project_name == "Total" ||  row.original.Defaults === '1' ;
  //       return isTotalRow ? (
  //         <Input
  //           className='w-[150px] timsheet-input'
  //           value={row.original.Project_name}
  //           disabled={true}
  //           style={{ color: "black" }}
  //         />
  //       ) : (
  //         <Select
  //           className='w-[210px]'
  //           value={row.original.Project_name || ''}
  //           onChange={(e) => handleSelectChange(row.index, 'project', e)}
  //           displayEmpty
  //           options={project}
  //           disabled={row.original.Defaults =='1' }
  //         />
  //       );
  //     },
  //   },
  //   ...dateList.map((date, index) => ({
  //     accessorKey: date.accessorKey,
  //     header: date.header,
  //     size: 120,
  //     Cell: ({ row }) => (
        
  //       <Input
  //         onChange={(e) => handleInputChange(row.index, index, e.target.value)}
  //         value={
  //           row.original.days && row.original.days[index] && row.original.days[index]['task-hours'] !== undefined
  //               ? row.original.days[index]['task-hours']
  //               : 0
  //       }
  //                 style={{ textAlign: 'center' }}
  //         disabled={row.original.Project_name === 'Total'}
  //       />
  //     ),
  //   })),
  // ], [dateList, tableData]);
  



  const columns = useMemo(() => [
    {
      accessorKey: 'client_name',
      header: 'Project',
      size : 80,
      Cell: ({ row }) => {


        console.log(row.original, "row.original");
        const isTotalRow = row.original.Project_name == "Total" ||  row.original.Defaults === '1' ;
        return isTotalRow ? (
          <Input
            className='w-[100px] timsheet-input'
            value={row.original.Project_name}
            disabled={true}
            style={{ color: "black" }}
          />
        ) : (
          <Select
            className='w-[205px]'
            value={row.original.Project_name || ''}
            onChange={(e) => handleSelectChange(row.index, 'project', e)}
            displayEmpty
            options={project}
            disabled={true}
          />
        );
      },
    },
    ...dateList.map((date, index) => ({
      accessorKey: date.accessorKey,
      header: date.header,
      size: 120,
      Cell: ({ row }) => {
        const rowIndex = row.index;
        const dayIndex = index;
  
        // Retrieve and format the current "task-hours" value for display in HH:mm
        const currentMinutes = row.original.days?.[dayIndex]?.["task-hours"] || 0;
        const formattedTime = formatTime(currentMinutes); // Assumes formatTime is available

      
         return (
            <label

            //style={{color:row.original.days?.[dayIndex]?.["dayapprove"]==1 ? "green":row.original.days?.[dayIndex]?.["dayapprove"]==2 ?'red':row.original.days?.[dayIndex]?.["dayapprove"]==3 ?'orange':''}}
            value={formatTimes(row.original.days?.[dayIndex]?.["task-hours"])}
            //onClick={ ()=>{row.original.days?.[dayIndex]?.["task-hours"]>0 && handleClickOpen(rowIndex,dayIndex,formatTimes(row.original.days?.[dayIndex]?.["task-hours"]),row.original.days?.[dayIndex])}}
        
            >{formatTimes(row.original.days?.[dayIndex]?.["task-hours"])}</label>
        
         );
      },
    })),

    {
      accessorKey: 'Total',
      header: 'WeekTotal',
      size: 100,
      Cell: ({ row, index }) => {
        const isTotalRow = row.original.Project_name === "Total" || row.original.Defaults === '1';
    
        //if (isTotalRow) {
          // Calculate weekly total for the "Total" row
          const weekTotalMinutes = row?.original?.days?.reduce((acc, day) => {
            return acc + parseInt(day["task-hours"] || 0, 10);
          }, 0);
    


          console.log( row.original.days, weekTotalMinutes,"weekTotalMinutes");

         
          // Format the total time in HH:mm
          const formattedWeekTotal = formatTimes(weekTotalMinutes);
    
          return (
            <>
              <label
              
                className="w-[150px] timesheet-input"
                style={{ color: "black",fontWeight:'bold' }}
              >
                {formattedWeekTotal}
              </label>
            </>
          );
          
      //  }
    
        //return null;
      },
    },


  ], [dateList, TeamData]);







  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return dayjs().hour(hours).minute(mins);
  
  
  }
    
  
    
  // const handleInputChange = (rowIndex, dayIndex, value) => {
  //   // Parse the input only if it's in "HH:mm" format
  //   if (true) {



  //     console.log(value, "totalMinutes");

  
  //     const updatedData = [...tableData];
  //     updatedData[rowIndex].days[dayIndex]["task-hours"] = value;
  
  //     // Recalculate totals for each column dynamically
  //     const newTotals = new Array(7).fill(0); // Adjust 7 based on the number of days if needed
  
  //     updatedData.forEach((row) => {
  //       row.days.forEach((day, idx) => {
  //         newTotals[idx] += Number(day["task-hours"] || 0);
  //       });
  //     });
  
  //     // Update the tableData and totals
  //     setTableData(updatedData);
  //     setTotalRowCount(newTotals.map(formatTime));
  //   }
  // };




  const [aooroveindex,setapproveindex]=useState({})

  const handleClickOpen = (rowIndex,dayIndex,e,index) => {
    console.log(rowIndex,dayIndex,index,'rrrr')
    setdayapprhrs("0")


 
    setapproveindex({
      rowIndex: rowIndex,
      dayIndex: dayIndex,
      value: e,
      index: index
    })


    setdayapprovedhours(e)
    setOpen(true);
  };

  const confirmapprove = () => {

    setOpen(false);
    setdayapprhrs("1");


    console.log(aooroveindex,"aooroveindex")
    const updatedData = [...tableData];
    updatedData[aooroveindex.rowIndex].days[aooroveindex.dayIndex]["dayapprove"] = "1";



    console.log(updatedData,"updatedData")


    setTableData(updatedData)


    handleAproved();


  };

  const denyapprove = (rowIndex,dayIndex,e,index) => {

    setOpen(false);
    setdayapprhrs("2")
    

    const updatedData = [...tableData];
    updatedData[aooroveindex.rowIndex].days[aooroveindex.dayIndex]["dayapprove"] = "2";



    console.log(updatedData,"updatedData")


    setTableData(updatedData)


    handledenied();


  };



  const handleInputChange = (rowIndex, dayIndex, value) => {
    // Parse the input only if it's in "HH:mm" format
    const [hours, minutes] = value.split(':').map(Number);
    const totalMinutes = (hours || 0) * 60 + (minutes || 0);

    // Update the specific day's hours for the given row in tableData
    const updatedData = [...tableData];
    updatedData[rowIndex].days[dayIndex]["task-hours"] = totalMinutes;
    updatedData[rowIndex].days[dayIndex]["dayapprove"] = "3";

    // Recalculate totals for each column dynamically
    const newTotals = new Array(7).fill(0); // Adjust 7 based on the number of days if needed

    //row.original.days?.[dayIndex]?.["dayapprove"]==1
    updatedData.forEach((row) => {
      row.days.forEach((day) => {
        newTotals[dayIndex] += Number(day["task-hours"] || 0);
        //newTotals[dayIndex] += Number(day["task-hours"] || 0);

      });
    });


    console.log(updatedData,"ININ")
    // Update the tableData and totals
    setTableData(updatedData);
    setTotalRowCount(newTotals.map(formatTime));
  };

 



  const handleWeekSelection = async (selectedDates) => {
    if (selectedDates.length > 0) {
      const selectedDate = selectedDates[0];
      const startOfWeek = new Date(selectedDate);
      const dayOfWeek = startOfWeek.getDay();
      const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      startOfWeek.setDate(startOfWeek.getDate() - daysToMonday);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      setSelectedDates([startOfWeek, endOfWeek]);
      const newDateList = Array.from({ length: 7 }, (_, index) => {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + index);




        const formattedDate = date.toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric'
        });

        return {
          accessorKey: formattedDate,
          header: formattedDate,
        };
      });
      setDateList(newDateList);
      console.log(newDateList, "newDateList");
      console.log("Selected Dates:", startOfWeek + "----" + endOfWeek);
    }
  };
  console.log(employee_id, "Timesheets");


const getteammembers = async() =>{

setLoading(true);
  const payload = {

      employee_id: localStorage.getItem('employee_id'),
      Client_id:JSON.parse(localStorage.getItem('Emp_Assign_client')),
      //Client_id:selected
    }

    await Timesheetapi.post('api/Projects/getteammembers', payload)
      .then(async(response) => {
        console.log(response.data, defaultvalue, "members");

        const teamMembers = response.data.Teamlist;
        const clientlist = response.data.ClientList;

        const memberoptions = teamMembers
          .map(member => ({
            value: member.employee_id,
            label: member.employee_name,
          }))
          .filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.value === value.value
            ))
          ); 


        const memberValues = teamMembers
          .map(member => member.employee_id) 
          .filter((value, index, self) =>
            self.indexOf(value) === index 
          );


          const clientlst = clientlist
          .map(client => ({
            value: client.Client_Id,
            label: client.Client_Name,
          }))

        setteamclients(clientlst)



        console.log(clientlst, "length");

      
        // Set the first member as the default team member value
        setteammember(clientlst[0]?.value);



        
        setteammembertemp([memberoptions[0]?.value])

    // Update the team member options state with the unique values
    setTeammemberOptions(memberoptions);
  })
  .catch((error) => {
    console.error("Error fetching team members:", error);
  });

  setLoading(false);


}





  const gettimesheetdata = async () => {
    const startDate = formatDateToDDMMYYYY(new Date(selectedDates[0])); // Convert start date
    const endDate = formatDateToDDMMYYYY(new Date(selectedDates[1])); // Convert end date

    setLoading(true)

    const storedClientIds = JSON.parse(localStorage.getItem('Emp_Assign_client'));

    const selectedDate = new Date(selectedDates[1]);

    const startOfMonth = new Date(selectedDate);
    startOfMonth.setDate(1);
    selectedDate.setMonth(selectedDate.getMonth() + 1);
    selectedDate.setDate(0);

    selectedDate.setHours(23, 59, 59, 999);

    const startOfMonthDate = formatDateToDDMMYYYY(startOfMonth);
    const endOfMonthDate = formatDateToDDMMYYYY(selectedDate);



   // await Timesheetapi.get(`api/Projects/GetEmployeeWorkingHours?empid=${teammember}&sdate=${startDate}&edate=${endDate}&Role_id=${localStorage.getItem('emp_role_id')}&Teamleadid=${localStorage.getItem('employee_id')}`).then(async (response) => {

   

      await Timesheetapi.get(`api/Projects/GetTeamsWorkingHours?empid=${TeamIds}&sdate=${startDate}&edate=${endDate}&Role_id=${localStorage.getItem('emp_role_id')}&Teamleadid=${localStorage.getItem('employee_id')}`).then(async(response) => {

   setLoading(false)



   let filteredTeamWeekData;
   if(teammember!=null && teammember!=""){

    const parsedTeamMember = parseInt(teammember);


    filteredTeamWeekData = response.data.TeamWeekData.map((teamMember) => {
      return {
        EmployeeId: teamMember.EmployeeId,
        EmployeeName: teamMember.EmployeeName,
        TimesheetId: teamMember.TimesheetId,
        WeekData:  teamMember.WeekData &&  teamMember.WeekData?.length>0 && teamMember.WeekData.filter(
          (item) => item.Client_id == parsedTeamMember || (item.Defaults == 1)
        ),
      };
    });


  if(filteredTeamWeekData.length>0){

setTeamData(filteredTeamWeekData)
  }        


   }




        



        setTeamDataClient(response.data.TeamWeekData)



        await Timesheetapi.get(`api/projects/getreportsclientAsync?sdate=${startOfMonthDate}&edate=${endOfMonthDate}&clientid=${teammember}`).then((Res)=>{




          const clients= Res.data.Clients

          console.log("clients",clients[0])
          setClientHours(clients[0])


        }).catch((err)=>{

        })







      });


      // Filter the WeekData to include only items with clientId in the storedClientIds
      // const filteredWeekData = response?.data?.WeekData?.filter((item) =>
      //   storedClientIds.includes(parseInt(item.Client_id)) || item.Defaults == 1
      // );




      // console.log(storedClientIds, localStorage.getItem('Emp_Assign_client'), "Emp_Assign_client");


      //setTableData(filteredWeekData);
      //settempTableData(response.data.WeekData)

      //console.log(response.data.Timesheet_id, "Timesheet_id");
      //setTimesheet_id(response?.data?.Timesheet_id ? response?.data?.Timesheet_id : "");

      // const filteredWeekDataApprove = response.data.WeekData.filter((item) =>
      //   storedClientIds.includes(parseInt(item.Client_id))
      // );
      //const filters = filteredWeekDataApprove && Array.isArray(filteredWeekDataApprove) && filteredWeekDataApprove.every((item) => item.Approved_status == 1) ? true : false;


     // setweekapproved(filters && filters ? '1' : "0")

     // const hasDefaultZero = filteredWeekData.some((item) => item.Defaults === "0");

      //sethideapprove(hasDefaultZero);


    //})



  }






  console.log(hideapprove, "gitss")



  const today = new Date();

  useEffect(() => {
    handleWeekSelection([today]);
    console.log("today",today, selectedDates);



    const selectedDate = today;
    const startOfWeek = new Date(selectedDate);
    const dayOfWeek = startOfWeek.getDay();
    const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    startOfWeek.setDate(startOfWeek.getDate() - daysToMonday);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    setSelectedDate([startOfWeek, endOfWeek]);

  }, []);

  useEffect(() => {
    console.log("selectedDates", selectedDates, localStorage.getItem('employee_id'));
    if (selectedDates.length > 0 && localStorage.getItem('employee_id') != undefined && TeamIds.length>0) {


      console.log(TeamIds,"TeamIds");

      //setteammember(localStorage.getItem("employee_id"));
      gettimesheetdata();

    }
  }, [selectedDates,TeamIds]);



  useEffect(() => {
    getteammembers();

  }, [])




  useEffect(() => {
    if(teammember!=null && teammember!="") {
      const temadata= async()=>{
        await Timesheetapi.post(`api/projects/getTeamMember?empid=${12}&clientid=${teammember}`).then((response) => {
      
      
          const memberValues = response.data.Team
          .map(member => member.TeamMember_Id) 
          .filter((value, index, self) =>
            self.indexOf(value) === index 
          );
       setTeamIds(memberValues);
        })
      }
      
    temadata();
    }
  },[teammember]) 


  //   useEffect(() => {
  //     console.log("selectedDates", selectedDates, localStorage.getItem('employee_id'));
  //     if (selectedDates.length > 0 && localStorage.getItem('employee_id') != undefined) {
  //       //setteammember(localStorage.getItem("employee_id"));

  //       getteammembers();

  //      defaultgettimesheetdata()

  //     }
  //   }, []);





  const totalRowPerTable = useMemo(() => {
    return TeamData.map(row => {
      const newTotals = new Array(7).fill(0); // Assuming 7 days/columns
      row?.WeekData?.forEach(member => {
        member?.days?.forEach((day, index) => {
          newTotals[index] += Number(day["task-hours"] || 0);
        });
      });

      return {
        Project_id: "null",
        Project_name: 'Total',
        employee_id: localStorage.getItem('employee_id'),
        days: newTotals.map(total => ({ "task-hours": total })), // Map totals to "days" format
      };
    });
  }, [TeamData]);

  const grandTotalRow = useMemo(() => {
    const newTotals = new Array(7).fill(0); // Assuming 7 days/columns

    // Accumulate totals across all tables
    totalRowPerTable.forEach(totalRow => {
      totalRow.days.forEach((day, index) => {
        newTotals[index] += Number(day["task-hours"] || 0);
      });
    });

    return {
      Project_id: "null",
      Project_name: 'Grand Total',
      employee_id: "all",
      days: newTotals.map(total => ({ "task-hours": total })), // Map totals to "days" format
    };
  }, [totalRowPerTable]);


  const changeWeek = (direction) => {
    const newDates = [...selectedDates];
    const increment = direction === 'next' ? 7 : -7;
 
    newDates[0] = new Date(newDates[0].getTime() + increment * 24 * 60 * 60 * 1000);
    newDates[1] = new Date(newDates[0].getTime() + 6 * 24 * 60 * 60 * 1000);
 
    setSelectedDates(newDates);
    handleWeekSelection(newDates);
    
  };

  
  const handleChangeclient = async (selected) => {


    console.log(selected,"clientyyyy")
    setselectteamclients(selected);

      if(selected == 0){

      setteammember(null);
      setShowDropdownmember(false);

      }
      else{

        setShowDropdownmember(true);

      }

   // getteammembers(selected);
    gettimesheetdata();
    getprojectname (localStorage.getItem('employee_id'));


  }




  const handleChange = async (selected) => {


    setteammembertemp(selected);

    console.log(selected, "selected")
    setteammember(selected);


  };


  const handleChanges = async (selected) => {


    setteammembertemp(selected);

  };
  const [isModalOpenpending, setIsModalOpenpending] = useState(false);



  const [weekData, setweekData] = useState([])

  const showModal = async () => {

    const currentStartDate = moment().startOf('month').format('YYYY-MM-DD');
    const currentEndDate = moment().endOf('month').format('YYYY-MM-DD');


    const selectedDate = new Date(selectedDates[1]);

    const startOfMonth = new Date(selectedDate);
    startOfMonth.setDate(1);
    selectedDate.setMonth(selectedDate.getMonth() + 1);
    selectedDate.setDate(0);

    selectedDate.setHours(23, 59, 59, 999);

    const startOfMonthDate = formatDateToDDMMYYYY(startOfMonth);
    const endOfMonthDate = formatDateToDDMMYYYY(selectedDate);

    console.log("Start of Selected Month:", startOfMonthDate);
    console.log("End of Selected Month:", endOfMonthDate);



    console.log(teammember, "team")


    await Timesheetapi.post(`api/Projects/PendingApprovalAsync?emp_id=${teammember}&sdate=${startOfMonthDate}&edate=${endOfMonthDate}&tlid=${localStorage.getItem('employee_id')}`).then((response) => {



      if (response.data.length == 0 || !hideapprove) {
        warning();
      }
      else {
        setIsModalOpenpending(true);
        setweekData(response.data)
      }

    }).catch(() => {

    })


  };

  const handleOk = () => {
    setIsModalOpenpending(false);
  };
  const handleCancel = () => {
    setIsModalOpenpending(false);
  };

  const isSameDayMonthYear = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&  date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear());
  };
  
  // Usage
  if (selectedDate[1] && selectedDates[1]) {
    //console.log(isSameDayMonthYear(selectedDate[1], selectedDates[1]), "date comparison");
  } else {
    console.log("One of the dates is undefined ");
  }
  


  
  const isSameDay = (date1, date2) => {
    return (
      (date1.getDate() >= date2.getDate()  && date1.getDate() <= date2.getDate() ) &&  (date1.getMonth() >= date2.getMonth() && date1.getMonth() <= date2.getMonth()) && (date1.getFullYear() >= date2.getFullYear() && date1.getFullYear() <= date2.getFullYear()));
  };
  
  // Usage
  if (selectedDate[1] && selectedDates[1]) {
    //console.log(isSameDayMonthYear(selectedDate[1], selectedDates[1]), "date comparison");
  } else {
    console.log("One of the dates is undefined ");
  }

  return (
    <>
    <div className='flex items-center gap-[4rem]'>
    <span className="text-[16px] font-[500] relative top-[15px]">TEAM TIME SHEET</span>
    <div>
    <span className="text-[16px] font-[500] relative top-[15px]">Approved_Hours : {ClientHours.assigned_hrs}</span>
    </div>
    {/* <div>
    <span className="text-[16px] font-[500] relative top-[15px]">Approved_Employee : {ClientHours.approved_emp}</span>
    </div> */}
    <div>
    <span className="text-[16px] font-[500] relative top-[15px]">Assigned_Employee : {ClientHours.employee_count}</span>
    </div>
    <div>
    <span className="text-[16px] font-[500] relative top-[15px]">TotalWorked_Hours : {formatTimes(ClientHours.Totalhours)} </span>
    </div>
    </div>

      <Box className='custom-material mt-[20px] timesheet_section'>
        {contextHolder}

      <ToastContainer />

        {
          !Loading ? (
            TeamData.map((row, index) => (
              <>
                <div className='mb-5'>
                  <MaterialReactTable
                    columns={columns}
                    data={row.WeekData.length > 0 ? [...row.WeekData, totalRowPerTable[index]] : []}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableColumnVisibility={false}
                    enableSorting={false}
                    enableGlobalFilter={false}
                    enableHiding={false}
                    enablePagination={false}
                    renderTopToolbarCustomActions={() => (
                      <>
                        <div className='flex items-center'>

            {/* <button
          disabled={localStorage.getItem('emp_role_id')==1?true:false}

              onClick={handleAddRow}
              className="bg-blue-500 text-white py-1.5 px-3 rounded flex items-center mb-4 relative top-[10px] z-[9] left-[10px]"
            >
              <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Add Project</span>
            </button> */}
                          <span className='cursor-pointer text-[#000] text-[16px] mr-2 font-[600]'  >
                            {row.EmployeeName}
                          </span>


          </div>
    {/* { (teamclients.length > 2 && localStorage.getItem('emp_role_id') == 2) &&(
            <div>
      
           <span>Clients</span>
           <div>

           <Select style={{width : '150px'}}
        
           value={selectteamclients}
           onChange={handleChangeclient}
           options={teamclients}
           placeholder="Select a client"
            
           >
         
           </Select>
           </div>
          </div>
        )} */}
          
          {/* <div>
          <p style={{color:"black"}}>Select team members</p>
          </div> */}

                        <div className="date-picker-container">
                          {/* <button className="nav-button" onClick={() => changeWeek('prev')}>Prev</button> */}
                          <div className='flex items-start'>
                            {/* { tableData.length > 0 && ( */}
                            <div >

                              <div style={{ marginRight: "10px", marginTop: '2px' }}>

                                {
                                  weekData?.length > 0 && (
                                    <>
                                      <Modal title="Approval Status" open={isModalOpenpending} onOk={handleOk} onCancel={handleCancel}>
                                        {weekData.map((item, index) => (
                                          <p key={index}>
                                            {`Week ${item['Week of day']}   : ${item["Week Approved"] === "0" ? "Pending Approval" : item["Week Approved"] === "2" ? "Denied" : "Approved"
                                              }`}
                                          </p>
                                        ))}
                                      </Modal>




                                    </>
                                  )
                                }




                                {/* <span className='cursor-pointer text-[#3b82f6] underline text-[14px] mr-2' onClick={showModal} >
            View Approval
          </span> */}


                                {
                                  index == 0 && (
                                    <>
                                      <Select 
           className='timesheet-select' style={{ maxWidth: '280px', height: '38px' }}        
           value={teammember}
           onChange={handleChange}
           options={teamclients}
           placeholder="Select a Client"  
           >
         
           </Select>
                                    </>
                                  )
                                }


                                {/* <Select 
          mode='multiple'
           className='timesheet-select' style={{ maxWidth: '280px', height: '38px' }}        
           value={teammembertemp}
           onChange={handleChanges}
           options={teammemberOptions}
           placeholder="Select a Team member"
           >
         
           </Select> */}



                              </div>
                            </div>
                            {/* ) } */}


                            {
                              index == 0 && (

                                <>
                                  <div className='custom-flatpicker' style={{ borderRadius: '5px 0px 0px 5px' }}>
                                    <Flatpickr
                                      className="date-picker"
                                      value={selectedDates}
                                      options={{
                                        mode: "range", // Enables range selection
                                        dateFormat: "D, M j", // Format the date display
                                        //minDate: selectedDates[0], // Restrict to the start of the month
                                        maxDate: selectedDate[1],   // Restrict to the end of the month
                                        onChange: handleWeekSelection, // Callback for date change
                                      }}
                                    />
                                  </div>

                                  <div className='custom-flatpicker' style={{ padding: '12px 10px 11px', borderLeft: '0px', cursor: 'pointer' }}>
                                    <MdOutlineArrowBackIos onClick={() => changeWeek('prev')} />
                                  </div>
                                  {/* console.log(selectedDate[1] && selectedDates[1] && selectedDate[1], selectedDates[1],"dateEDFR") */}
                                  <div className='custom-flatpicker' style={{ padding: '12px 10px 11px', borderLeft: '0px', borderRadius: '0px 5px 5px 0px', cursor: selectedDate[1] && selectedDates[1] && isSameDayMonthYear(selectedDate[1], selectedDates[1]) ? "not-allowed" : "pointer" }}>
                                    <MdOutlineArrowForwardIos onClick={() => {
                                      if (selectedDate[1] && selectedDates[1] && !isSameDayMonthYear(selectedDate[1], selectedDates[1])) {
                                        changeWeek('next');
                                      }
                                    }} />
                                  </div>

                                </>
                              )
                            }


                          </div>


            {/* <button className="nav-button" onClick={() => changeWeek('next')}>Next</button> */}
        </div>

          </>
          

        )}

                  />
                </div>





            {/* {tableData.length > 0 && (
              <div
                style={{
                  justifyContent: 'end',
                  position: 'relative',
                  top: '-3.3rem'
                }}>

<button
          disabled={true}

              //onClick={handleAddRow}
              className="bg-white-500 text-white py-1.5 px-3 rounded flex items-center mb-4 relative top-[10px] z-[9] left-[10px]"
            >
              <span className="mr-2"></span><span className="text-[14px]" style={{color:"black",fontWeight:"bold"}}>Weekly based hours submission</span>
            </button>  </div>

            )} */}

<>
      <Modal
        title={<><ExclamationCircleFilled  style={{ color: 'orange', marginRight: '8px' }} /> Confirm</>}
        visible={isModalOpen}
        onOk={confirmDelete}
        onCancel={cancelDelete}
        footer={[
          <Button key="cancel" onClick={cancelDelete}>
            Cancel
          </Button>,
          <Button key="confirm" type="primary" onClick={confirmDelete}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this row?</p>
      </Modal>
      </>
<>
<Modal
        title={<><ExclamationCircleFilled  style={{ color: 'orange', marginRight: '8px' }} /> Confirm</>}
        visible={open}
        onOk={confirmapprove}
        onCancel={closeapprove}
        footer={[
          <Button key="cancel" type="primary" onClick={confirmapprove}
           disabled={aooroveindex?.index?.dayapprove == 1}
          >
            APPROVE
          </Button>,
          <Button key="confirm"  onClick={denyapprove}
          disabled={aooroveindex?.index?.dayapprove == 2}
          >
            DENY
          </Button>
        ]}
      >
     {aooroveindex?.index?.dayapprove == "1" ? <p>Do you want to denied this {dayapprovedhours} hours?</p> : <p>Do you want to approve this {dayapprovedhours} hours?</p>}
      </Modal>
</>

                {(() => {
                  const hasDefaultZero = row.WeekData.some((data) => data.Defaults === "0"); // Check if any item has Defaults === "0"
                  const showButtons = teammember !== localStorage.getItem('employee_id') && hasDefaultZero;
                  const isApproved = row.WeekData
                    .filter((data) => data.Defaults === "0")
                    .every((data) => data.Approved_status == 1)
                  console.log("Timesheet Data:", isApproved);

                  return (
                    <>

                      {showButtons && (
                        <div
                          className="flex items-center gap-[10px]"
                          style={{
                            justifyContent: 'end',
                            position: 'relative',
                            right: '1rem',
                            top: '-4.6rem',
                          }}
                        >
                          <Button
                            className="approve-btn bg-green-500 text-white py-1.5 px-3 rounded flex items-center relative top-[10px] z-[9] left-[10px]"
                            disabled={isApproved}
                            onClick={() => handleAproved(row.WeekData, index, row.TimesheetId, row.EmployeeId,row.EmployeeName)} // Pass the entire WeekData for approval
                          >
                            APPROVED
                          </Button>

                          <Button
                            className="approve-btn bg-red-500 text-white py-1.5 px-3 rounded flex items-center relative top-[10px] z-[9] left-[10px]"
                            onClick={() => handledenied(row.WeekData, index, row.TimesheetId, row.EmployeeId,row.EmployeeName)} // Pass the entire WeekData for denial
                          >
                            DENIED
                          </Button>
                        </div>
                      )}
                    </>
                  );
                })()}

              </>


            )
            )

          ) : (
            <>
              <Spin tip="Loading...">
                <MaterialReactTable
                  columns={columns}
                  data={[...tableData]}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableColumnVisibility={false}
                  enableSorting={false}
                  enableGlobalFilter={false}
                  enablePagination={false}



        renderTopToolbarCustomActions={() => (
          <div className='flex items-center gap-[20px]'>
            {/* <button
              onClick={handleAddRow}
              disabled={localStorage.getItem('emp_role_id')==1?true:false}
              className="bg-blue-500 text-white py-1.5 px-3 rounded flex items-center mb-4 relative top-[10px] z-[9] left-[10px]"
            >
              <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Add Project</span>
            </button> */}
            <span className="bg-blue-500 text-white py-1.5 px-3 rounded flex items-center mb-4 relative top-[10px] z-[9] left-[10px]"
            >
              <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Time Sheet</span>
            </span>
            <div className='custom-flatpicker'>
              <Flatpickr
                className="date-picker"
                value={selectedDates}
                options={{
                  mode: "range",
                  dateFormat: "D, M j",
                  onChange: handleWeekSelection
                  
                  
                }}
              />

            </div>

            <div className='flex items-center gap-[20px]'>

              <Button
                className="bg-blue-500 text-white py-1.5 px-3 rounded flex items-center mb-4 relative top-[10px] z-[9] left-[10px]"
             
                onClick={handlesubmit}>
                Save
              </Button>
            </div>
          </div>

        )}
      />
      </Spin>
          </>

        )
      }


      </Box>
    </>
  );
}
