import React, { useState, useMemo,useEffect } from "react";
import CustomTable from '../common/CustomTable';
import { Select,DatePicker, Spin } from 'antd';
import { Timesheetapi } from "../../middleware/interceptor";
import dayjs from 'dayjs';
import * as moment from 'moment';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


const { RangePicker } = DatePicker;



export const  Employee_report = ()=>{


    const currentStartDate = dayjs().startOf('month');
     const currentEndDate = dayjs().endOf('month');
    const [employeelist, setemployeelist] = useState([]);
    const [ selectedRange, setSelectedRange] = useState([currentStartDate, currentEndDate]);
    const [loading,setloading]=useState(false)




    const columns = useMemo(() => [
        {
          accessorKey: 'Employee_name',
          header: 'Employee name',
          size: 50,
          enableColumnActions: false,
          muiTableHeadCellProps: {
            align: 'center',
          },
        },
        {
          accessorKey: 'Role',
          header: 'Role',
          size: 5,
          enableColumnActions: false,
          muiTableHeadCellProps: {
            align: 'center',
          },
        },
        {
          accessorKey: 'Total_hours',
          header: 'Total hours',
          size: 5,
          enableColumnActions: false,
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            style: {
              textAlign: 'right',
            },
          },
          
        },
        
        
      ], [employeelist]);


      const formatTime = (totalMinutes) => {
        if (isNaN(totalMinutes) || totalMinutes == null) return "00:00"; // Handle NaN or null
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      };
      
      const formatTimeinter = (totalMinutes) => {
        if (isNaN(totalMinutes) || totalMinutes == null) return 0; // Handle NaN or null by returning 0
      
        // Convert totalMinutes to hours (in decimal format)
        const decimalHours = totalMinutes / 60;
      
        // Return the decimal value rounded to two decimal places (optional, depending on how precise you want it)
        return parseFloat(decimalHours.toFixed(2));  // You can adjust precision as needed (e.g., 2 decimal places)
      };


      // const handleExportData = (data) => {
      //   const rowData = data.map((row) => row.original);
    
      //   const csv = convertToCSV(rowData);
      //   downloadCSV(csv);
      // };


      const handleExportData = async(rows) => {



        const tableData = rows.map((row) => Object.values(row.original));
    
    
        console.log(selectedRange,'date')
    
    
        const tableDatas = tableData.map((row) => ({
          Employee_name: row[0],
          Role: row[1],
          Totalhours: row[2],
         
    
      }));
    
      const workbook = new ExcelJS.Workbook();
    
      const worksheet = workbook.addWorksheet(`Employee_Report.xlsx`);
      const startDate = new Date(selectedRange[0]);
      const endDate = new Date(selectedRange[1]);
    
      // Get the month and year for both start and end dates
      const options = { year: 'numeric', month: 'long' };
      const startMonthYear = startDate.toLocaleString('en-US', options); // e.g., "October 2024"
      const endMonthYear = endDate.toLocaleString('en-US', options); 
    
      const selected = 'DATE: ' + startMonthYear + '  -  ' + endMonthYear;
      worksheet.addRow([selected]);
    
      worksheet.mergeCells('A1:D1');
      const Cell = worksheet.getCell('A1');
      Cell.font = { bold: true };
       Cell.alignment = { horizontal: 'center' };
    
    
    
    worksheet.getCell('A3').value = 'Employe_Name';
    worksheet.getCell('B3').value = 'Role';
    worksheet.getCell('C3').value = 'Totalhours';
    
    
    worksheet.getCell('A3').font = { bold: true };
    worksheet.getCell('B3').font = { bold: true };
    worksheet.getCell('C3').font = { bold: true };
    
    worksheet.getColumn('A').width = 25;
    worksheet.getColumn('B').width = 15;
    worksheet.getColumn('C').width = 15;
    
    
      console.log(tableDatas,tableData,"tableData")
    
    
      let rowIndex = 4;
    
    
      // Employee_name: row[0],
      //     client_name: row[1],
      //     Totalhours: row[7],
      //     InternalRate: row[8],
      //     ExternalRate: row[9],
    
      const filteredData = tableDatas.map(item => ({
        Employee_name: item.Employee_name,
        Role: item.Role,
        Totalhours: item.Totalhours
    }));
    
    filteredData.forEach(data => {
      worksheet.getCell(`A${rowIndex}`).value = data.Employee_name;
      worksheet.getCell(`B${rowIndex}`).value = data.Role;
      worksheet.getCell(`C${rowIndex}`).value = data.Totalhours;
      rowIndex++;
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `Employee_Report.xlsx`);
    
        // console.log(columns, "ghghgh");
        // const rowData = data.map((row) => row.original);
        // const exportData = clientlist; 
        // const csv = convertToCSV(rowData);
        // downloadCSV(csv);
      };
    






    
      const convertToCSV = (data) => {
        const headers = Object.keys(data[0]);
        const rows = data.map(row => 
          headers.map(field => row[field]).join(',')
        );
        return [headers.join(','), ...rows].join('\n');
      };
    
      const downloadCSV = (csv) => {
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Reports.csv';
        link.click();
      };


      const getemployeereport = async () => {
        
        setloading(true);
        const currentStartDate = moment().startOf('month').format('YYYY-MM-DD');
        const currentEndDate = moment().endOf('month').format('YYYY-MM-DD');
    
        await Timesheetapi.get(`api/Projects/employeegetreportsAsync?sdate=${currentStartDate}&edate=${currentEndDate}`).then((response) => {
        
        const employees = response.data.employeedetails;
    
    
        let data = employees?.map((item) => {
          return {
            ...item,
            Total_hours:formatTime(item.Total_hours),
          };
        })
    
    
          setemployeelist(data);
    
         
        }).catch((error) => {
          console.log(error);
        });

        setloading(false);

    
      }


      const handleDateChange = async(date, dateString) => {
      
        if (date && date.length === 2) {
          setSelectedRange(dateString)

            setloading(true);

            const startMonth = date[0];
            const endMonth = date[1];
            const startOfMonth = startMonth.startOf('month').format('YYYY-MM-DD');
            const endOfMonth = endMonth.endOf('month').format('YYYY-MM-DD');
            console.log('date',startOfMonth,endOfMonth);
    
            await Timesheetapi.get(`api/Projects/employeegetreportsAsync?sdate=${startOfMonth}&edate=${endOfMonth}`).then((response) => {
        
              const employees = response.data.employeedetails;
          
          
              let data = employees?.map((item) => {
                return {
                  ...item,
                  Total_hours:formatTime(item.Total_hours),
                  
                };
              })
          
          
                setemployeelist(data);
          
              }).catch((error) => {
                console.log(error);
              });

              setloading(false);

    
        }
    
    
    
      }
    






      useEffect(() => {

        getemployeereport();
      },[]);
    






return (

    <section className='reports-section'>
    <div>
      <h2 className="text-[20px] font-[500]">Employee Reports</h2>
    </div>
    <div className="flex justify-start">
    
              
      <div style={{left:"100px"}}> 
      <RangePicker
        className="z-[99] left-[10px] top-[31px] py-[5px]"
        onChange={handleDateChange}
        defaultValue={selectedRange} // Show the selected range
        picker="month"
        placeholder={['Start Month', 'End Month']}
         format="MMMM YYYY"
      />

     </div>
    </div>
    <div className="mt-[-20px]  custom-table employee-table">



    {loading?(<>
  <Spin tip="Loading..." className="justify-center items-center flex" style={{height:'100vh'}}>
       <CustomTable
          columns={columns}
          data={employeelist} 
          component={"Report"} 
        /></Spin>
</>):(
    <>
    <CustomTable
  columns={columns}
  data={employeelist} // Append totals row
  component={"Report"}
  handleExportData={handleExportData}
/>
  </>

)
}




     
    </div>
  </section>


);




}