
import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import {  Input, Select, Tag, message, Button,Flex, Spin,Modal,Tooltip } from 'antd';
import { useMsal } from "@azure/msal-react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";


import { MaterialReactTable } from 'material-react-table';
import { Timesheetapi } from "../../middleware/interceptor";

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ExclamationCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import moment from 'moment';


export const Timesheet = ({ isAuthenticated, employee_id }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const { instance } = useMsal();

  const [tableData, setTableData] = useState([]);
  const [temptableData, settempTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);

  const [defaultvalue, setdefaultvalue] = useState('ME');

   const [disablesubmitbutton, setdisablesubmitbutton] = useState(true);
  const [weekapproved, setweekapproved] = useState("0");

  const [teammemberOptions, setTeammemberOptions] = useState([]);

  const [teamclients, setteamclients] = useState([]);


  const [open, setOpen] = useState(false);


  const [project, setProject] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();
  const [arrow, setArrow] = useState('Show');

  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);


  const success = (msg) => {
    messageApi.open({
      type: 'success',
      title: 'Success',
      content: msg,
      duration:1.5
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      duration:1.5,
      content: 'This is an error message',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      title: 'Warning',
      duration:1.5,
      content: 'No more project available',
    });
  };

  const formatTimes = (totalMinutes) => {
    if (isNaN(totalMinutes) || totalMinutes == null) return "00:00"; // Handle NaN or null
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };
   

  useEffect(() => {
    if (isAuthenticated) {
      const { name, username } = instance?.getActiveAccount();
      console.log("username", name, username);


      console.log("gggg", selectedDates.length, employee_id);

      if (localStorage.getItem('employee_id')!=null && localStorage.getItem('employee_id') != undefined) {

        getprojectname (localStorage.getItem('employee_id'))

      }

    }
  }, [isAuthenticated,localStorage.getItem('employee_id')]);

  const getemployee = async (username) => {
    const response = await Timesheetapi.get(`api/Projects/getemployeebyid/${username}/`);
    console.log("response", response.data.Employee[0].employee_id);

    console.log(response.data.Employee, "employee_id");

  }

  const[Loading,setLoading]=useState(false);


  useEffect(() => {


    console.log(employee_id, "employee_idsssss");
  }, [employee_id]);



  const [timesheet_id, setTimesheet_id] = useState();


  const [dateList, setDateList] = useState([]);

  const [pendingAction, setPendingAction] = useState([]);


  const [tempoptions,settemoptions]=useState([])

  


  const getprojectname = async (username) => {

   

    const respons = await Timesheetapi.get(`api/Projects/timesheetprojects?empid=${username}`);

    const clientOptions = respons.data.Projects.map(Projects => ({
      value: Projects.Client_name + '/' + Projects.Proj_name + ',' + Projects.Client_id+','+ Projects.Proj_id+','+Projects.Expire_date,
      label: Projects.Client_name + '/' + Projects.Proj_name, 
      id:Projects.Proj_id,
      expire_date:Projects.Expire_date
    }));



    const teamclients = respons.data.Projects.map(Projects => ({
      value: Projects.Client_id,
      label: Projects.Client_name
    }));


    const meOption = {
      value: '0', 
      label: 'ALL',
    };
  
    // Optionally prepend or append the "ME" option
    const updatedOptions = [meOption, ...teamclients];


    console.log(updatedOptions.length,"gathere")

   
    settemoptions(clientOptions)

    setProject(clientOptions);




  }





  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);


  const[deletedata,setdeletedata]=useState()

  const handleDeleteRow = (rowIndex,row) => {
    setRowToDelete(rowIndex-1);  // Set the row to delete
    setIsModalOpen(true);  
    setdeletedata(row.original.Project_id)
   // console.log(row, "index")

  };

  const confirmDelete = () => {
    // Filter out the row to delete
    setTableData((prevData) => {
      const newData = prevData.filter((_, index) => index !== rowToDelete);
  
      // Use the updated table data to set the disabled status
      const updatedOptions = tempoptions.map(option => {
        const isDisabled = newData.some(item => item.Project_id == option.id);
        return {
          ...option,
          disabled: isDisabled,
        };
      });



    setIsModalOpen(false);  // Close the modal
    setRowToDelete(null);   // Reset the row to delete
    setProject(updatedOptions);
    return newData;
  });
  };




  const cancelDelete = () => {
    setIsModalOpen(false);  // Close the modal without deleting
    setRowToDelete(null);
  };


  const [totalRowCount, setTotalRowCount] = useState(new Array(7).fill(0)); // Initialize an array for 7 columns




const [selectedProjects, setSelectedProjects] = useState([]);


  
  const handleAddRow = (rowIndex, field, value) => {



    console.log("kils", rowIndex, field, value)
    const startDate = formatDateToDDMMYYYY(new Date(selectedDates[0]));
    const endDate = formatDateToDDMMYYYY(new Date(selectedDates[1]));
    console.log(formatDateToDDMMYYYY(new Date(selectedDates[0])), "dayswwwww")
    const weekDates = [];
    let currentDate = new Date(startDate);
    const finalDate = new Date(endDate);

    while (currentDate <= finalDate) {
      weekDates.push(formatDateToDDMMYYYY(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const daysArray = weekDates.map((hours, index) => ({
      'task-hours': 0,
      date: weekDates[index]
    }));




    let prodcm = [];


    console.log(prodcm.length, project.length, "yeast")
    if (prodcm.length <= project.length) {



      prodcm = [{ project: '', task: '', Defaults: '0', days: daysArray }, ...tableData];


      let projects = prodcm.filter((item) => item?.Defaults === '0');
      console.log(projects.length, project.length, "yeast")


      if (projects.length <= project.length) {

        setTableData([{ Defaults: '0', days: daysArray }, ...tableData]);

        setPendingAction([{ Defaults: '0', days: daysArray,rowIndex:rowIndex,field:field,value:value }, ...tableData])

      }
      else {
       
        setTableData([{ Defaults: '0', days: daysArray }, ...tableData]);

        setPendingAction([{ Defaults: '0', days: daysArray,rowIndex:rowIndex,field:field,value:value }, ...tableData])
 //warning();
      }


    }
    else {
      toast.warning('No project available')
    }




    

  };

  const handleSelectChange = (rowIndex, field, value) => {
    rowIndex=rowIndex-1;  
    let filter = value.split(',');
  //  let clientname = filter[0].split('/')[0];


  //  const selectedProjectId = filter[2];
  //  setSelectedProjects((prevSelected) => [...prevSelected, selectedProjectId]);


    console.log(filter, "filter");
    const updatedData = [...tableData];

    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      Project_name: filter[0],
      Project_id: filter[2],
      Employee_id: employee_id,
      Client_id: filter[1],
      Client_name: filter[0].split('/')[0],
      Expire_Date:filter[3],
      //days: daysArray,
    };

    console.log(updatedData, "updatedData");
    //setSelectedvalue(value);
    setTableData(updatedData);

    setweekapproved('0')
  };


  useEffect(() => {
    let rowIndex=1;
    if(pendingAction.length>0){
       handleSelectChange(rowIndex,'projects',pendingAction[0].value);
      console.log("pending",pendingAction[0].value);


    }

  },[pendingAction])




  const handleSelectChangeselect=(rowIndex, field, value)=>{

    rowIndex=rowIndex-1;

    
    console.log(value,rowIndex,'kil');
    handleAddRow(rowIndex, field, value);
 
  }




  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentWeekDay = currentDate.getDay();


  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDay - (currentWeekDay === 0 ? 6 : currentWeekDay - 1));





  const formatDateToDDMMYYYY = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Format as yyyy-mm-dd
};


  const handlesubmit = async () => {


    const startDate = formatDateToDDMMYYYY(new Date(selectedDates[0]));
    const endDate = formatDateToDDMMYYYY(new Date(selectedDates[1]));



    setLoading(true)

    const storedClientIds = JSON.parse(localStorage.getItem('Emp_Assign_client'));

    const transformedTableData = tableData.map(client => {
      const isApproved = storedClientIds.includes(parseInt(client.Client_id));
    
      return {
        ...client,
        Approved_status: localStorage.getItem('emp_role_id')==2 ? '1' :client.Approved_status,
      };
    });
    const payload = {
      Timesheetid:timesheet_id,
      Weekstart_date:startDate,
      Weekend_date:endDate,
      employee_id: employee_id,
      tableData: transformedTableData,
      Approved: localStorage.getItem('emp_role_id') == "2" ? "1" : "0"
    };
    
    console.log(payload,tableData,"Transformed Payload");
    
    await Timesheetapi.post('api/Projects/AddEmployeeWorkingHours', payload).then((response) => {
      success("Successfully submitted for approval");
      let logpayload = {

        employee_id: localStorage.getItem('emp_role_id'),
        actionEvent: "Timesheet-Entries",
        changed_by: localStorage.getItem('emp_role_id'),
        changes: [{
          old_changes: temptableData,
          new_changes: tableData
        }]

      }
      console.log('yess')
    //  timesheet_log(logpayload);
      gettimesheetdata();
      setLoading(false)

     }).catch((error) => {
      console.log(error, "error");
    });
    console.log(payload, "payload");
  }


  const isSameDay = (date1, date2) => {


    console.log("SDEDED", date1 +'----------'+ date2);
    return (
      (date1.getDate() >= selectedDate[0].getDate()  && date1.getDate() <= date2.getDate() ) &&  (date1.getMonth() >= selectedDate[0].getMonth() && date1.getMonth() <= date2.getMonth()) && (date1.getFullYear() >= selectedDate[0].getFullYear()   && date1.getFullYear() <= date2.getFullYear()));
  };

  // const isSameDaydisable = (date1, date2) => {
  //   console.log("Comparing Dates:", date1, "----------", date2);
  
  //   if (date1 && date2) {
  //     const parsedDate1 = dayjs(date1, 'DD-MM-YYYY');
  //     const parsedDate2 = dayjs(date2, 'DD-MM-YYYY');
  
  //     return parsedDate1.isSameOrBefore(parsedDate2); // Day.js comparison
  //   }
  
  //   return false; // If either date is missing, disable the row
  // };


//   const isSameDaydisable = (date1, date2) => {


//     console.log("SDEDED", date1 +'----------'+ date2);

// if(date1 && date2){
//   console.log("SDEDED", date1 +'----------'+ date2);
//   return (
//     (date1.getDate() <= date2.getDate()  && date1.getDate() <= date2.getDate() ) &&  (date1.getMonth() <= date2.getMonth() && date1.getMonth() <= date2.getMonth()) && (date1.getFullYear() <= date2.getFullYear()   && date1.getFullYear() <= date2.getFullYear()));
// }
// else{
//   return false
// }
  
// }
const isSameDaydisable = (date1, date2) => {
  console.log("Comparing Dates:", date1, "----------", date2);

  if (date1 && date2) {
    const parsedDate1 = dayjs(date1, 'DD-MM-YYYY');
    const parsedDate2 = dayjs(date2, 'DD-MM-YYYY');

    console.log(parsedDate1.isBefore(parsedDate2) || parsedDate1.isSame(parsedDate2),"trueeeeeee")

    return parsedDate1.isBefore(parsedDate2) ;
  }

  return false; // If either date is missing, disable the row
};





const columns = useMemo(() => [
    {
      accessorKey: 'client_name',
      header: 'Project',
      size: 200,
      Cell: ({ row }) => {


        console.log(row.original, "row.original");
        const isTotalRow = row.original.Project_name == "Total" ||  row.original.Defaults === '1' ;
        return isTotalRow ? (
          <Input
            className='w-[150px] timsheet-input'
            value={row.original.Project_name}
            disabled={row.original.Project_name != "Select a Project"}
            onClick={handleAddRow}
            style={{ color: row.original.Project_name == "Select a Project"?"rgb(58 130 247)":"black" }}
          />
        ) : row.original.Project_name == "Select a Project" ? (
          <div className='relative'>
            <Select
              className='w-[245px] project-select'
              value={row.original.Project_name || ''}
              onChange={(e) => handleSelectChangeselect(row.index, 'project', e)}
              displayEmpty
              options={project}
              disabled={row.original.Defaults == '1' || (row?.original?.Approved_status && row?.original?.Approved_status == "1" && localStorage.getItem("emp_role_id") != 2)}

            />
            <FaPlusCircle className='text-[16px] text-[#2563eb] absolute left-[2.3rem] top-[8px]' />
          </div>

        ) : (
          <Select
            className='w-[245px]'
            value={row.original.Project_name || ''}
            onChange={(e) => handleSelectChange(row.index, 'project', e)}
            displayEmpty
            options={project}
            disabled={row.original.Defaults =='1' || (row?.original?.Approved_status && row?.original?.Approved_status=="1" &&localStorage.getItem("emp_role_id") !=2) }
          />
        )
      },
    },
    ...dateList.map((date, index) => ({
      accessorKey: date.accessorKey,
      header: date.header,
      size: 120,
      Cell: ({ row }) => {
        const rowIndex = row.index - 1;
        const dayIndex = index;
        const dateForCell = row.original?.days?.[dayIndex]?.date || null;
        const expireDate = row?.original?.Expire_Date || null;
      
        const formattedDateForCell = dateForCell ? dayjs(dateForCell).format('DD-MM-YYYY') : null;
    const formattedExpireDate = expireDate ? dayjs(expireDate).format('DD-MM-YYYY') : null;

    console.log('Formatted Date for Cell:', formattedDateForCell);
    console.log('Formatted Expire Date:', formattedExpireDate);

    // Check if dates should disable the row
    const isExpired = isSameDaydisable(formattedExpireDate, formattedDateForCell);

        const isProjectNameValid = row.original.Project_name !== 'Total' && row.original.Project_name !== 'Select a Project';
        const isDisabled = !isProjectNameValid || isExpired || (weekapproved === 1 && localStorage.getItem("emp_role_id") === "1");
      
        const currentMinutes = row.original.days?.[dayIndex]?.["task-hours"] || 0;
        const formattedTime = formatTime(currentMinutes);

        return (
         <>
         {console.log('Logging row data:', row?.original?.Approved_status && row?.original?.Approved_status)}
         {
      row.original.Project_name == "Select a Project"?(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimeField', 'TimeField', 'TimeField']}>
      <Tooltip placement="leftTop" title={"Select project first."} arrow={mergedArrow}>
     <TimeField

       className='custom_time'
       value={row.original.Project_name !== "Total" ? formattedTime : formatTime(row.original.days?.[dayIndex]?.["task-hours"])}
       disabled={true 
         } //|| (row?.original?.Expire_Date && !isSameDaydisable(row?.original?.Expire_Date, row?.original?.Expire_Date)) row.original.Project_name === 'Total'|| row.original.Project_name == "Select a Project"  ||    || (weekapproved == 1 && localStorage.getItem("emp_role_id") == 1)        
       format="HH:mm"
   
     />
   </Tooltip>
   </DemoContainer>
 </LocalizationProvider>
 ):  formattedExpireDate &&  isExpired ?   (
 
     <LocalizationProvider dateAdapter={AdapterDayjs}>
   <DemoContainer components={['TimeField', 'TimeField', 'TimeField']}>
   <Tooltip placement="leftTop" title={"Project date is Expired."} arrow={mergedArrow}>

     <TimeField

       className='custom_time'
       value={row.original.Project_name !== "Total" ? formattedTime : formatTime(row.original.days?.[dayIndex]?.["task-hours"])}
       disabled={row.original.Project_name === 'Total'|| row.original.Project_name == "Select a Project" || (weekapproved == 1 && localStorage.getItem("emp_role_id") == 1)|| isDisabled}
       format="HH:mm"
       onChange={(newValue) => {
         const formattedValue = `${newValue.hour()}:${newValue.minute()}`;
         handleInputChange(rowIndex, dayIndex, formattedValue);
       }}
     />
     </Tooltip>
     </DemoContainer>
 </LocalizationProvider>

 ):(
  <>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
   <DemoContainer components={['TimeField', 'TimeField', 'TimeField']}>
     <TimeField
 
       className='custom_time'
       value={row.original.Project_name !== "Total" ? formattedTime : formatTime(row.original.days?.[dayIndex]?.["task-hours"])}
       disabled={row.original.Project_name === 'Total'|| row.original.Project_name == "Select a Project" || (row?.original?.Approved_status && row?.original?.Approved_status == 1 && localStorage.getItem("emp_role_id") == 1)|| isDisabled}
       //||  dateForCell !== today  // row.original.days?.[dayIndex]?.["dayapprove"]==1 ||  ||(weekapproved == 1 && localStorage.getItem("emp_role_id") !=2) 
       format="HH:mm"
       //style={{color:row.original.days?.[dayIndex]?.["dayapprove"]==1 ? "green":row.original.days?.[dayIndex]?.["dayapprove"]==2 ?'red':''}}
       onChange={(newValue) => {
         const formattedValue = `${newValue.hour()}:${newValue.minute()}`;
         handleInputChange(rowIndex, dayIndex, formattedValue);
       }}
     />

   </DemoContainer>
 </LocalizationProvider>
  </>
 )
         }
         
          </>
          
        );
      },
    })),





    {
      accessorKey: 'Total',
      header: 'WeekTotal',
      size: 100,
      Cell: ({ row, index }) => {
        const isTotalRow = row.original.Project_name === "Total" || row.original.Defaults === '1';
    
       
          const weekTotalMinutes = row?.original?.days?.reduce((acc, day) => {
            return acc + parseInt(day["task-hours"] || 0, 10);
          }, 0);
    


          console.log( row.original.days, weekTotalMinutes,"weekTotalMinutes");
          // Format the total time in HH:mm
          const formattedWeekTotal = formatTimes(weekTotalMinutes);
    
          return (
            <>
              <label
              
                className="w-[150px] timesheet-input"
                style={{ color: "black" }}
              >
                {formattedWeekTotal}
              </label>
            </>
          );
          
    
      },
    },
    



{
    accessorKey: 'actions',
    header: 'Actions',
    size: 100,
    Cell: ({ row }) => {
      const isTotalRow = row.original.Project_name === "Total" || row.original.Defaults === '1';
      return isTotalRow  || row.original.Project_name == "Select a Project" ? null : (
          <div className='text-center'>
        <Tooltip title="Delete">

          {}
        <IconButton
          onClick={() => handleDeleteRow(row.index,row)}
          disabled={(weekapproved=="1" &&localStorage.getItem("emp_role_id") !=2) }
          size="small"
          style={{ color: 'red' }}>

          <i class="fa fa-trash"></i>
        </IconButton>
        </Tooltip>
          </div>
       
      );
    },
  }

  ], [dateList, tableData,project]);
  
  // Helper function to format minutes into "HH:mm"
  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return dayjs().hour(hours).minute(mins);
  
  
  }
    
  
    
  

  const handleInputChange = (rowIndex, dayIndex, value) => {

    
    setdisablesubmitbutton(false);

    // Parse the input only if it's in "HH:mm" format
    const [hours, minutes] = value.split(':').map((v) => parseInt(v, 10)); // Convert to numbers
    const totalMinutes = (hours || 0) * 60 + (minutes || 0);

    console.log(hours,minutes,"givem")

    // Update the specific day's hours for the given row in tableData
    const updatedData = [...tableData];
    updatedData[rowIndex].days[dayIndex]["task-hours"] = totalMinutes;
    updatedData[rowIndex].days[dayIndex]["dayapprove"] = "3";

    // Recalculate totals for each column dynamically
    const newTotals = new Array(7).fill(0); // Adjust 7 based on the number of days if needed

    //row.original.days?.[dayIndex]?.["dayapprove"]==1
    updatedData.forEach((row) => {
        row.days.forEach((day, idx) => {
            newTotals[idx] += Number(day["task-hours"] || 0);
        });
    });


    console.log(updatedData,"ININ")
    // Update the tableData and totals
    setTableData(JSON.parse(JSON.stringify(updatedData)));
    setTotalRowCount(newTotals.map(formatTime)); // Format the totals as "HH:mm"
};

  

  const handleWeekSelection = (selectedDates) => {
    if (selectedDates.length > 0) {
      const selectedDate = selectedDates[0];
      const startOfWeek = new Date(selectedDate);
      const dayOfWeek = startOfWeek.getDay();
      const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      startOfWeek.setDate(startOfWeek.getDate() - daysToMonday);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      setSelectedDates([startOfWeek, endOfWeek]);
      const newDateList = Array.from({ length: 7 }, (_, index) => {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + index);




        const formattedDate = date.toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric'
        });

        return {
          accessorKey: formattedDate,
          header: formattedDate,
        };
      });
      setDateList(newDateList);
      console.log(newDateList, "newDateList");
      console.log("Selected Dates:", startOfWeek + "----" + endOfWeek);
    }
  };
  console.log(employee_id, "Timesheets");


const getteammembers = async() =>{


  const payload = {

    employee_id: localStorage.getItem('employee_id'),
    //Client_id:selected
  }

  await Timesheetapi.post('api/Projects/getteammembers', payload).then((response) => {
  
  console.log(response.data,defaultvalue,"members")

  
  const teamMembers = response.data.Teamlist;

   
  const memberoptions = teamMembers.map(member => ({

    value: member.employee_id,
    label: member.employee_name,
  }));


  const meOption = {
    value: localStorage.getItem('employee_id'), 
    label: 'ME',
  };

  // Optionally prepend or append the "ME" option
  const updatedOptions = [meOption, ...memberoptions];



  setTeammemberOptions(updatedOptions); 
 

   }).catch((error) => {



  });




}



const timesheet_log = async (payload) => {
  try {

    const response = await Timesheetapi.post('api/Projects/Timesheet_entries_log', payload).then((response) => {;

    console.log('Response:', payload);

    return response.data;
    }).catch((error) => {
      console.log(error, "error");
    });

  } catch (error) {
    console.error('Error during API call:', error);
  }
};








  const gettimesheetdata = async () => {
    const startDate = formatDateToDDMMYYYY(new Date(selectedDates[0])); // Convert start date
    const endDate = formatDateToDDMMYYYY(new Date(selectedDates[1])); // Convert end date

    
    setLoading(true)
    await Timesheetapi.get(`api/Projects/GetEmployeeWorkingHours?empid=${localStorage.getItem('employee_id')}&sdate=${startDate}&edate=${endDate}&Role_id=${localStorage.getItem('emp_role_id')}&Teamleadid=${localStorage.getItem('employee_id')}`)
    .then((response) => {
      setLoading(false)
      console.log(response.data, "responsedd");
      setTableData(JSON.parse(JSON.stringify(response?.data?.WeekData)));
      settempTableData(JSON.parse(JSON.stringify(response?.data?.WeekData)));


      setTimesheet_id(response?.data?.Timesheet_id ? response?.data?.Timesheet_id : "");


      const filterser = response.data.WeekData.filter((item) => item.Defaults ==0);



      if(filterser.length>0){

        const filters = filterser && Array.isArray(filterser) && filterser.every((item) => item.Approved_status == 1) ? true : false;
        setweekapproved(filters ?'1':'0')
      }
      else{
        setweekapproved("0")
      }


    })



  }




  useEffect(()=>{


     const updatedOptions = tempoptions.map(option => {
      const isDisabled = tableData.some(item => item.Project_id == option.id);
      return {
        ...option,
        disabled: isDisabled || false,
      };
    });

    console.log(updatedOptions,"options")


    setProject(updatedOptions)

  },[tempoptions,tableData])



  const today = new Date();

  useEffect(() => {
    handleWeekSelection([today]);
    console.log("jack", selectedDates);


    const selectedDate = today;
    const startOfWeek = new Date(selectedDate);
    const dayOfWeek = startOfWeek.getDay();
    const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    startOfWeek.setDate(startOfWeek.getDate() - daysToMonday);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    setSelectedDate([startOfWeek, endOfWeek]);

  }, []);

  useEffect(() => {
    console.log("selectedDates", selectedDates, localStorage.getItem('employee_id'));
    if (selectedDates.length > 0 && localStorage.getItem('employee_id') != undefined) {
      gettimesheetdata();
     

      //getteammembers();
    }
  }, [selectedDates]);





  const totalRow = useMemo(() => {
    const newTotals = new Array(7).fill(0); // Assuming 7 days/columns
  
console.log(tableData,"HHBHUHH")
    tableData?.forEach(row => {
      row?.days?.forEach((day, index) => {
        newTotals[index] += parseInt(day["task-hours"] || 0);
      });
    });



    console.log(newTotals, "newTotals");
  
    return {
      "Project_id": "null",
      "Project_name": 'Total',
      "employee_id": localStorage.getItem('employee_id'),
      "days": newTotals.map(total => ({ "task-hours":total})) // Format totals as "HH:mm" and place in task-hours
    };
  }, [tableData]);







  const totalRowfirst = {
    Project_id: "null",
    Project_name: "Select a Project",
    employee_id: localStorage.getItem("employee_id"),
    days: Array(7).fill({ "task-hours": 0 }) // 7 days with 0 hours
  };
  




  const changeWeek = (direction) => {
    const newDates = [...selectedDates];
    const increment = direction === 'next' ? 7 : -7;
 
    newDates[0] = new Date(newDates[0].getTime() + increment * 24 * 60 * 60 * 1000);
    newDates[1] = new Date(newDates[0].getTime() + 6 * 24 * 60 * 60 * 1000);
 
    setSelectedDates(newDates);
    handleWeekSelection(newDates);
    
  };

  
  const isSameDayMonthYear = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };
  
  // Usage
  if (selectedDate[1] && selectedDates[1]) {
    console.log(isSameDayMonthYear(selectedDate[1], selectedDates[1]), "date comparison");
  } else {
    console.log("One of the dates is undefined");
  }


  const initialEmptyRow = useMemo(() => {
    return {
      Project_id: null,
      Project_name: 'Select a Project',
      employee_id: localStorage.getItem('employee_id'),
      days: Array(7).fill({ "date":0, "task-hours": 0 })
    };
  }, []);


  return (
    <Box className='custom-material mt-[40px] timesheet_section'>
            {contextHolder}

      <ToastContainer />

      {
        !Loading ?(
<>
            <div className='timesheet_custom_table'>
      <MaterialReactTable
        columns={columns}
                data={[totalRowfirst,...tableData,totalRow]}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnVisibility={false}
        enableSorting={false}
        enableGlobalFilter={false}
        enableHiding = {false}
        enablePagination={false}

        renderTopToolbarCustomActions={() => (
          <>
          <div className='flex items-center'>
          <span className="">
             <span className="text-[16px] font-[500]">TIME SHEET</span>
            </span>

          </div>
   

          <div>
              <div className="date-picker-container">
             <div className='flex items-start'>
             <div className='custom-flatpicker'>
                <Flatpickr
                    className="date-picker"
                    value={selectedDates}
                    options={{
                        mode: "range", 
                        dateFormat: "D, M j", 
                        maxDate: selectedDate[1],   // Restrict to the end of the month
                        onChange: handleWeekSelection, 
                    }}
                />
            </div>
            <div className='custom-flatpicker' style={{padding:'12px 10px 11px',borderLeft:'0px',cursor:'pointer'}}>
                <MdOutlineArrowBackIos onClick={() => changeWeek('prev')} />
            </div>
            <div className='custom-flatpicker' style={{padding:'12px 10px 11px',borderLeft:'0px',cursor:selectedDate[1] && selectedDates[1] && isSameDayMonthYear(selectedDate[1], selectedDates[1])?"not-allowed":"pointer"}}>
<MdOutlineArrowForwardIos
 onClick={() => {
  if (selectedDate[1] && selectedDates[1] && !isSameDayMonthYear(selectedDate[1], selectedDates[1]))
    {
      changeWeek('next');
    }
  }}     />            </div>
             </div>


        </div>
          </div>
          </>
          

        )}

      />
            </div>



            <div className='flex items-center gap-[20px]' >

              {/* <div className='flex items-center gap-[20px] justify-between' >

                {
                  (selectedDates[0] && isSameDay(selectedDates[0], selectedDate[1])) && (
                    <button

                      disabled={(weekapproved == 1 && localStorage.getItem("emp_role_id") != 2)}

                      onClick={handleAddRow}
                      className="bg-blue-500 text-white py-1.5 px-3 rounded flex items-center mb-4 z-[9] left-[10px] relative top-[-2.9rem]">
                      <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Select a Project</span>
                    </button>
                 // )
              </div> */}

            </div>


            <>
              <Modal
                title={<><ExclamationCircleFilled style={{ color: 'orange', marginRight: '8px' }} /> Confirm</>}
                visible={isModalOpen}
                onOk={confirmDelete}
                onCancel={cancelDelete}
                footer={[
                  <Button key="cancel" onClick={cancelDelete}>
                    Cancel
                  </Button>,
                  <Button key="confirm" type="primary" onClick={confirmDelete}>
                    Delete
                  </Button>
                ]}
              >
                <p>Are you sure you want to delete this row?</p>
              </Modal>
            </>


 { (weekapproved=="1" &&localStorage.getItem("emp_role_id") !=2) ? (
  <div
  className='flex items-center gap-[20px]' 
  style={{
      justifyContent: 'end',
      position: 'relative',
      right: '1rem',
      top: '-3.1rem'
  }}
  >
    <Button

            
className="approve-btn bg-blue-500 text-white py-1.5 px-3 rounded flex items-center relative top-[4px] z-[9] left-[10px]"
//onClick={handlesubmit}
>
Weekly approved success
</Button>
  </div>
            ) :  (
  <div 
  className='flex items-center gap-[20px]' 
  style={{
      justifyContent: 'end',
      position: 'relative',
      right: '1rem',
      top: '-3.1rem'
  }}
>
            <Button
              disabled={disablesubmitbutton}
              className="approve-btn bg-blue-500 text-white py-1.5 px-3 rounded flex items-center relative top-[4px] z-[9] left-[10px]"
              onClick={handlesubmit}>
              SUBMIT FOR APPROVAL
            </Button>
          </div>

 )
}

 


 
</>
        ):(
          <>
     <Spin tip="Loading...">
      <MaterialReactTable
        columns={columns}
                data={[totalRowfirst,...tableData, totalRow]}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnVisibility={false}
        enableSorting={false}
        enableGlobalFilter={false}
        enablePagination={false}
  
      

        renderTopToolbarCustomActions={() => (
          <div className='flex items-center gap-[20px]'>
          
            <span className="bg-blue-500 text-white py-1.5 px-3 rounded flex items-center mb-4 relative top-[10px] z-[9] left-[10px]"
            >
              <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Time Sheet</span>
            </span>
            <div className='custom-flatpicker'>
              <Flatpickr
                className="date-picker"
                value={selectedDates}
                options={{
                  mode: "range",
                  dateFormat: "D, M j",
                  onChange: handleWeekSelection
                }}
              />

            </div>

            <div className='flex items-center gap-[20px]'>

              <Button
                className="bg-blue-500 text-white py-1.5 px-3 rounded flex items-center mb-4 relative top-[10px] z-[9] left-[10px]"
             
                onClick={handlesubmit}>
                Save
              </Button>
            </div>
          </div>

        )}
      />
      </Spin>
          </>

        )
      }

     
    </Box>
  );
}
