

import React, { useState, useMemo, useEffect } from "react";
import { Form, Input, Button, Select, Spin, message, Modal as AntdModal } from 'antd';
import { Timesheetapi } from "../../middleware/interceptor";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTable from "../common/CustomTable";
import { Modal } from "../common/Modal";
import { FaRegEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { ExclamationCircleFilled } from '@ant-design/icons';
import CryptoJS from 'crypto-js';
import AWS from 'aws-sdk';

// Configure AWS SDK
AWS.config.update({
  accessKeyId: 'AKIA2JPUCIF2VPIQVPED', // Not recommended for frontend!
  secretAccessKey: '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', // Not recommended for frontend!
  region: 'us-east-1',
});



export const Employeerate = ({isAuthenticated,Secret_key}) => {


console.log(Secret_key,"kooo")

  const [form] = Form.useForm();


  const [empname, setEmpname] = useState();

  const [grade, setGrade] = useState();
  const [clients, setClients] = useState([]);
  const [edit, setedit] = useState(false);

  const [emp_id, setemp_id] = useState();
  const [data, setData] = useState([]);

  const [loading, setloading] = useState(false)


  const [ratedeletemodal, setratedeletemodal] = useState(false);
  const [deletedata, setdeletedata] = useState();


  const [startValue, setStartValue] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();


  const [isModalOpen, setIsModalOpen] = useState(false);




  const [experienceOptions, setExperienceOptions] = useState([
    { value: '0-2', label: '0 to 2 years' },
    { value: '2-4', label: '2 to 4 years' },
    { value: '4-6', label: '4 to 6 years' },
    { value: '6-8', label: '6 to 8 years' },
    { value: '8-10', label: '8 to 10 years' },
    { value: '10-12', label: 'more than 10 years' },


  ]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setedit(false);
  };

  const handleStartChange = (value) => {
    //form.setFieldValue("year_to", null);



    if (value == "0-2") {

      form.setFieldValue("Grade", "Grade A");

    }
    else if (value == "2-4") {
      form.setFieldValue("Grade", "Grade B");

    }
    else if (value == "4-6") {
      form.setFieldValue("Grade", "Grade C");

    }
    else if (value == "6-8") {
      form.setFieldValue("Grade", "Grade D");

    }
    else if (value == "8-10") {
      form.setFieldValue("Grade", "Grade E");

    }
    else {

      form.setFieldValue("Grade", "Grade F");

    }



    console.log(value, "hooo")





  };


  const toggleModalclick = () => {


    form.resetFields();

    setIsModalOpen(!isModalOpen);
    setedit(false);
  };

  const getemployee = async () => {


    setloading(true);

    await Timesheetapi.get('api/Projects/Getempgradedata').then(async (response) => {


      //let filter = response?.data?.Emp_Grade_Data?.filter((item) => item.del_empgrade_status == null);

      let data = response?.data?.Emp_Grade_Data?.map(item => {
        return Object.fromEntries(
          Object.entries(item).map(([key, value]) => {

            if ((key === 'externalRank' || key === 'internalRank') && value) {
              const [ciphertext, ivBase64] = value.split(',');
              return [key, decryptData(ciphertext, ivBase64)];
            }

            return [key, value || "-"];
          })
        );
      })

      console.log(data, "handle")
      const updatedOptions = experienceOptions.map(option => {
        const isDisabled = response.data.Emp_Grade_Data.some(item => item.YOE === option.value);
        return {
          ...option,
          disabled: isDisabled,
        };
      });



      setExperienceOptions(updatedOptions);

      setData(data);


      // await Timesheetapi.get('api/Projects/getgrade')
      //   .then(async (response) => {
      //     const clientOptions = response.data.Grade.map(client => ({
      //       value: client.grade_id + ',' + client.grade_name,
      //       label: client.grade_name,
      //     }));

      //     setGrade(clientOptions);


      //   }).catch((error) => {
      //     console.log(error);
      //   });




    }).catch((error) => {
      console.log(error);
    });


    setloading(false);



  }

  useEffect(() => {
    getemployee();

  }, []);


  const toggleedit = async (row) => {
    setIsModalOpen(true);

    console.log(row, "vooo")

    setedit(true);
    form.setFieldsValue({
      Grade: row.grade_name == '-' ? null : row.grade_name,
      internalRank: row.internalRank == '-' ? null : row.internalRank,
      externalRank: row.externalRank == '-' ? null : row.externalRank,
      year_from: row.YOE,
    });






  }


  const toggledelete = async (row) => {

    setratedeletemodal(true);

    let data = {
      grade: row.original.grade_name,
      yoe: row.original.YOE
    }

    setdeletedata(data);
    let grade = row.original.grade_name
    let yoe = row.original.YOE


  };



  const columns = useMemo(() => [
    // {
    //   accessorKey: 'emp_name',
    //   header: 'Employee Name',
    //   size: 195,
    //   enableColumnActions: false,
    // },
    // {
    //   accessorKey: 'role',
    //   header: 'Role Name',
    //   size: 195,
    // },

    {
      accessorKey: 'grade_name',
      header: 'Grade',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'left',
        },
      },
    },
    {
      accessorKey: 'YOE',
      header: 'YOE',
      size: 160,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },
    },

    {
      accessorKey: 'internalRank',
      header: 'Internal Rate',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },
    },
    {
      accessorKey: 'externalRank',
      header: 'External Rate',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        style: {
          textAlign: 'right',
        },
      },
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <div className="flex justify-center gap-3">
          <FaRegEdit onClick={() => toggleedit(row.original)}
            className="text-[19.5px] text-[#3b82f6] cursor-pointer"
          />
          <FaTrashAlt
            onClick={() => toggledelete(row)}
            className="text-[16px] text-[#ff4d4d] cursor-pointer"
          />
        </div>
      ),
    }


  ], [data]);


  const encryptData = async (data) => {

    // Generate a random IV (16 bytes)
    const iv = CryptoJS.lib.WordArray.random(16);

    // Encrypt using AES CBC mode
    const ciphertext = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(Secret_key), { iv: iv }).toString();

    // Return both ciphertext and IV (encode IV as base64 to store it)


    return { ciphertext, iv: iv.toString(CryptoJS.enc.Base64) };
  };

  // AES Decryption with IV
  const decryptData = (ciphertext, ivBase64) => {

    const iv = CryptoJS.enc.Base64.parse(ivBase64);

    // Decrypt using AES CBC mode
    const bytes = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(Secret_key), { iv: iv });
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);


    console.log(decryptedData, "joker")

    return decryptedData;
  };



  const handleFinish = async (values) => {



    const internaldata = await encryptData(values.internalRank);

    const externaldata = await encryptData(values.externalRank);


  

    let payload = {
      Grade: values.Grade,
      Irate: internaldata.ciphertext + "," + internaldata.iv,
      Erate: externaldata.ciphertext + "," + externaldata.iv,
      experience: values.year_from
    }




    console.log(payload, "checkrate")


    if (!edit) {
      await Timesheetapi.post('api/Projects/creategrade', payload).then((response) => {
        console.log(response);
        if (response.data.status == "1") {
          success(response.data.Msg);
        }

        else if (response.data.status == "2") {

          warning(response.data.Msg);
        }

        else {
          error(response.data.Msg);

        }
      }).catch((error) => {
        console.log(error);
        toast.error('Error in adding Employee Rank');
      });
    }
    else {


      const internaldata = await encryptData(values.internalRank);

      const externaldata = await encryptData(values.externalRank);
  


      payload = {

        Grade: values.Grade,
        Irate: internaldata.ciphertext + "," + internaldata.iv,
        Erate: externaldata.ciphertext + "," + externaldata.iv,
        experience: values.year_from
        
      }

      await Timesheetapi.post('api/Projects/updategrade', payload).then(async (response) => {

        if (response.status == 200) {
          success(response.data.Msg);

        }
      }).catch((error) => {
        console.log(error);
      });


    }

    getemployee();
    toggleModal();

    console.log('Form values:', values);
  }


  const cancelDelete = () => {
    setratedeletemodal(false);
  };

  const confirmDelete = async () => {

    try {
      const response = await Timesheetapi.post(`api/Projects/deletegrade?grade=${deletedata.grade}&YOE=${deletedata.yoe}`);

      getemployee();
      console.log('response', response.data);


      if (response.data == "deleted sucessfully") {

        success(response.data);

      }
      else {
        error(response.data)
      }


    } catch (error) {
      console.error('Error fetching client names:', error);
    }

    setratedeletemodal(false);

  }

  const success = (content) => {
    messageApi.open({
      type: 'success',
      title: 'Success',
      content: content,
      duration: 1.5
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      duration: 1.5,
      content: 'This is an error message',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      title: 'Warning',
      duration: 1.5,
      content: 'No more project available',
    });
  };

  return (

    <section className="employee_section">
      {contextHolder}

      <ToastContainer />
      <div>
        <h2 className="text-[20px] font-[500]">Rate Card</h2>
      </div>
      <div className="flex justify-start">
        <button
          onClick={toggleModalclick}
          className="bg-blue-500 text-white py-[4px] px-3 rounded flex items-center mb-4 relative top-[20px] z-[9] left-[1rem]"
        >
          <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Add Employee Rates</span>
        </button>
      </div>

      <div>
        <AntdModal
          title={<><ExclamationCircleFilled style={{ color: 'orange', marginRight: '8px' }} /> Confirm</>}
          visible={ratedeletemodal}
          onOk={confirmDelete}
          onCancel={cancelDelete}
          footer={[
            <Button key="cancel" onClick={cancelDelete}>
              Cancel
            </Button>,
            <Button key="confirm" type="primary" onClick={confirmDelete}>
              Delete
            </Button>
          ]}
        >
          <p>Are you sure you want to delete this rate card?</p>
        </AntdModal>

      </div>

      {loading ? (<Spin tip="Loading..." className="justify-center items-center flex" style={{height:'100vh'}}>
        <div className="mt-[-40px] overflow-auto custom-table">
          <CustomTable
            columns={columns}
            data={data}
            pinColumn={['action']}
          />
        </div>

      </Spin>


      ) : (<div className="mt-[-40px] overflow-auto custom-table">
        <CustomTable
          columns={columns}
          data={data}
          pinColumn={['action']}
        />
      </div>

      )}



      {isModalOpen && (
        <Modal toggleModal={toggleModal}>
          <div className="flex justify-center">
            <div className="w-[100%] mt-[20px]">

              <Form form={form} onFinish={handleFinish} layout="vertical">
                <div className='grid grid-cols-2 gap-[10px]'>

                  <div>

                    <Form.Item
                      label="Year of Experience"
                      name="year_from"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the year',
                        }
                      ]}
                    >
                      <Select
                        allowClear={true}
                        className="w-full"
                        size="large"
                        //placeholder="From"
                        options={experienceOptions}
                        onChange={handleStartChange}
                        disabled={edit}
                      />

                    </Form.Item>


                  </div>
                  {/* <div>
                    <Form.Item
                      label="Month of Experience"
                      name="year_to"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the year',
                        }
                      ]}
                    >
                      <Select
                        allowClear={true}
                        className="w-full"
                        size="large"
                        placeholder="To"
                        options={endOptions}
                        onChange={handlemonthchange}
                      //disabled={!startValue && startValue !== 0}
                      />

                    </Form.Item>


                  </div> */}
                  {/* <div>
                    <Form.Item
                      label="Employee Name"
                      name="employeeName"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the employee name',
                        },
                      ]}
                    >
                      <Select className="h-9" options={empname} disabled={true} onChange={(e) => { handleempchange(e) }} />
                    </Form.Item>
                  </div> */}

                  <div>
                    <Form.Item
                      label="Grade"
                      name="Grade"
                      rules={[
                        {
                          required: true,
                          message: 'Please select a Grade',
                        },
                      ]}
                    >
                      <Select className="h-10"
                        //defaultValue="member"
                        disabled={edit}
                        options={grade}
                      >
                      </Select>
                    </Form.Item>
                  </div>

                  <div >
                    <Form.Item
                      label="Internal Rate"
                      name="internalRank"
                      rules={[
                        {
                          required: true,
                          message: 'Please select at External Rank',
                        },
                      ]}
                    >
                      <Input
                        className="w-[100%] h-10"
                        onKeyDown={(event) => {
                          // Allow Backspace, Tab, Delete, and Arrow keys
                          if (
                            event.key !== 'Backspace' &&
                            event.key !== 'Tab' &&
                            event.key !== 'Delete' &&
                            event.key !== 'ArrowLeft' &&
                            event.key !== 'ArrowRight'
                          ) {
                            // Regex to allow only numbers and a single decimal point
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (!regex.test(event.target.value + event.key)) {
                              event.preventDefault();
                            }
                          }
                        }}
                      />
                    </Form.Item>

                  </div>



                  <div>
                    <Form.Item
                      label="External Rate"
                      name="externalRank"
                      rules={[
                        {
                          required: true,
                          message: 'Please select at External Rank',
                        },
                      ]}
                    >
                      <Input
                        className="w-[100%] h-10"
                        onKeyDown={(event) => {
                          // Allow Backspace, Tab, Delete, and Arrow keys
                          if (
                            event.key !== 'Backspace' &&
                            event.key !== 'Tab' &&
                            event.key !== 'Delete' &&
                            event.key !== 'ArrowLeft' &&
                            event.key !== 'ArrowRight'
                          ) {
                            // Regex to allow only numbers and a single decimal point
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (!regex.test(event.target.value + event.key)) {
                              event.preventDefault();
                            }
                          }
                        }}
                      />
                    </Form.Item>
                  </div>






                </div>
                {/* <div className='grid grid-cols-1'>
          <div>
        
        <Form.Item
      label="Billing status"
      name="Billing_status"
      rules={[
        {
          required: true,
          message: 'Please enter the year',
        }
      ]}
    >
                                   <Select
                                    allowClear={true}
                                    className="w-full h-10"
                                    size="large"
                                    placeholder="select"

                                  > 
                                  <Option value="1"> Billable </Option>
                                  <Option value="2"> Non Billable </Option>
                                  <Option value="3"> Bench</Option>


                                  </Select>

    
                </Form.Item>
    
    
    </div>
          </div> */}
                <div className="flex justify-end gap-3 mt-8">
                  <Button onClick={toggleModal} className="text-gray-500 hover:text-gray-700">
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" className="bg-blue-500 text-white">
                    Save
                  </Button>
                </div>
              </Form>
            </div>

          </div>
        </Modal>
      )}
    </section>



  )
}
